import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "src/assets/styles/theme";
import AuthProvider from "./components/AuthProvider";
import Login from "./Login";
import Registration from "./Registration";

const style = {
  box: {
    display: "flex",
    width: "100vw",
    alignItems: "center",
    justifyContent: "end",
    flexDirection: "row",
  },
};

interface Props {
  isOpen: boolean;
  isLoginBtnCliked?: boolean;
  handleClose: () => void;
  nextStep: () => void;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthDialog = ({
  isOpen,
  isLoginBtnCliked,
  handleClose,
  nextStep,
  setIsLoggedIn,
}: Props): JSX.Element => {
  const [login, setLogin] = useState(isLoginBtnCliked);

  const handleLogIn = (): void => {
    setLogin(true);
  };
  const handleSignUp = (): void => {
    setLogin(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 30,
          width: "463px",
          display: "flex",
          flexDirection: "column",
          paddingRight: "32px",
          paddingLeft: "32px",
        },
      }}
      fullScreen={isMobile}
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "30px",
        }}
      >
        {isMobile && (
          <Box sx={style.box}>
            <IconButton
              aria-label="Close welcome message window"
              onClick={() => handleClose()}
            >
              <CloseIcon
                sx={{
                  color: "black",
                  fontSize: "35px",
                  mt: "5px",
                  mr: "15px",
                }}
              />
            </IconButton>
          </Box>
        )}
        <Typography
          sx={{
            fontStyle: "normal",
            fontSize: "30px",
            color: "#252525",
            fontWeight: 'bold',
          }}
        >
          {login ? "Log into your Account" : " Join Us"}
        </Typography>
      </DialogTitle>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "24px",
      }}
      >
      <Typography
      sx={{
        fontStyle: "normal",
        fontSize: "20px",
        color: "#888",
        fontWeight: 'regular' ,
        textAlign: "center",
      }}>
        {login ? "to get the best quotes" : "Looking for a hassle-free insurance process? Join our platform for the best experience."} </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {login ? (
          <>
            <Login
              handleClose={handleClose}
              nextStep={nextStep}
              setIsLoggedIn={setIsLoggedIn}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography
              sx={{
                color: "#000",
                fontSize: "20px",
              }}
              >A new user?
              </Typography>
              <Button
                sx={{
                  textTransform: "none",
                  color: "#4299E1",
                  fontSize: "20px",
                }}
                onClick={handleSignUp}
              >
                Join Now
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Registration nextStep={nextStep} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Typography sx= {{ fontSize: "20px", marginBottom: "3px", marginTop: "5px"}}>Already a user?</Typography>
              <Button
                sx={{
                  textTransform: "none",
                  color: "#109CF1",
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "3px",
                  marginTop: "5px"
                }}
                onClick={handleLogIn}
              >
                Log In
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Divider orientation="vertical" flexItem sx={{ width: "100%" }}>
        <Divider>
          <Typography 
          sx= {{fontWeight: "regular", fontSize: "20px" }}>OR
          </Typography>
        </Divider>
      </Divider>
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "10px",
      }}
      >
      <Typography
      sx={{
        fontStyle: "normal",
        fontSize: "20px",
        color: "#000000",
        fontWeight: 'regular' ,
      }}>
        Social Login </Typography>
      </Box>
      <AuthProvider setIsLoggedIn={setIsLoggedIn} />
    </Dialog>
  );
};
export default AuthDialog;
