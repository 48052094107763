import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  Link,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axiosConfig from "src/lib/axios";
import theme from "src/assets/styles/theme";
import verificationMail from "../../assets/icons/verificationMail.svg";

const style = {
  box: {
    display: "flex",
    width: "100vw",
    alignItems: "center",
    justifyContent: "end",
    flexDirection: "row",
    mt: "25px",
  },
  icon: {
    width: "128px",
    height: "70px",
    mt: { xs: "100px", md: "150px" },
    mb: "24px",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    textAlign: "center",
    color: "#252525",
    mx: { xs: "10px", md: "0px" },
  },
  message: {
    fontWeight: "normal",
    fontSize: "18px",
    textAlign: "center",
    color: "#252525",
    mt: "36px",
    mx: { xs: "40px", md: "0px" },
  },
  link: {
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "18px",
    color: "#109CF1",
    mt: "5px",
  },
  footerText: {
    fontWeight: "normal",
    fontSize: "18px",
    textAlign: "center",
    color: "#252525",
    mt: "16px",
    mb: "150px",
    mx: { xs: "40px", md: "0px" },
  },
  middleText: {
    fontWeight: "bold",
    color: "#000000",
  },
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
  name: string;
}

const ValidationEmailDialog = ({
  isOpen,
  handleClose,
  email,
  name,
}: Props): JSX.Element => {
  const resendCode = async () => {
    const updateUrl = `/api/v1/email/send_verification_email?email=${email}`;
    await axiosConfig.patch(updateUrl, {
      body: { email: email, first_name: name },
    });
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 30,
          width: isMobile ? "100vw" : "463px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingRight: isMobile ? "0px" : "32px",
          paddingLeft: isMobile ? "0px" : "32px",
        },
      }}
      fullScreen={isMobile}
      open={isOpen}
      onClose={handleClose}
    >
      {isMobile && (
        <Box sx={style.box}>
          <IconButton
            aria-label="Close welcome message window"
            onClick={() => handleClose()}
          >
            <CloseIcon
              sx={{
                color: "black",
                fontSize: "35px",
                mt: "5px",
                mr: "15px",
              }}
            />
          </IconButton>
        </Box>
      )}
      <Box sx={{ display: "flex" }}>
        <Box
          component="img"
          alt={"VerificationEmailDialog"}
          src={verificationMail}
          sx={style.icon}
        />
      </Box>
      <DialogTitle sx={style.dialogTitle}>
        <Typography sx={style.title}>
          We've sent a verification email to: {email}
        </Typography>
      </DialogTitle>
      <Typography sx={style.message}>
        Please click the link in your email to verify your account. If you're
        unable to locate the email, check your spam folder or&nbsp;
      </Typography>
      <Link onClick={resendCode} sx={style.link}>
        click here to resend.
      </Link>

      <Typography sx={style.footerText}>
        The link will expire in the&nbsp;
        <strong style={style.middleText}>next 24 hours</strong> after which you
        will have to sign in again.
      </Typography>
    </Dialog>
  );
};
export default ValidationEmailDialog;
