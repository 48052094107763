const classes = {
  container: {
    display: "flex",
    height: "100vh",
    width: { xs: "100%", sm: "100vw" },
    flexDirection: "column",
  },
  layoutContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: { xs: "100%", sm: "100vw" },
  },
  rightSideContainer: {
    display: "flex",
    width: { xs: "100%", sm: "100vw" },
    flexDirection: "column",
    mb: { xs: 0, sm: "50px" },
    padding: { xs: "30px", sm: 0 },
  },
  formContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
    padding: { sm: "8px" },
    paddingBottom: { sm: 0 },
    ml: { xs: 0, sm: "20px" },
  },
  boxesContainar: {
    display: "flex",
    flexDirection: "column",
    mt: { xs: 0, sm: "20px" },
    mr: { xs: 0, sm: "40px" },
    borderRadius: "10px",
    backgroundColor: "#F4F4F9",
    padding: {
      xs: "30px 20px 20px",
      sm: "20px 20px 20px 0",
      md: "30px 0 20px",
    },
  },
  policiesContainer: {
    display: "flex",
    flexDirection: "column",
    mt: "20px",
    mr: { xs: 0, sm: "40px" },
    mb: { xs: "20px", sm: 0 },
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#F4F4F9",
    padding: { xs: "30px 20px 20px", sm: "30px 0 20px" },
  },
  primaryText: {
    mb: "20px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "24px",
    fontFamily: "Outfit",
    color: "#252525",
  },
  secondaryText: {
    textAlign: "center",
    fontWeight: "normal",
    fontSize: "20px",
    fontFamily: "Outfit",
    color: "#252525",
  },
  addCoApplicationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "20px",
    paddingBottom: { xs: "10px", sm: 0 },
  },
  uploadFileBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 1,
    borderStyle: "dashed",
    borderColor: "gray",
    backgroundColor: "white",
    height: "42px",
    width: "95%",
    mt: "12px",
  },
  uploadButton: {
    borderRadius: "20px",
    textTransform: "none",
    height: "42px",
    width: "11%",
    mt: "24px",
  },
  successUploadText: {
    mt: "24px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "20px",
    fontFamily: "Outfit",
    color: "#62D690",
  },
};
export default classes;
