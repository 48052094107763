import { Box, Grid, Typography, Link } from "@mui/material";
import { Container } from "@mui/system";

import email from "../assets/icons/email.svg";
import general_question from "../assets/icons/general_question.svg";
import billing_question from "../assets/icons/billing_question.svg";

const classes = {
  container: {
    mt: "67px", // height of Navbar
    display: "flex",
    flexDirection: "column",
    mb: "50px",
  },
  pageTitle: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
    },
    padding: {
      xs: "30px 20px 0 20px",
      sm: "20px 0 0 0",
    },
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: {
      xs: "16px",
      sm: "18px",
      md: "20px",
    },
    padding: "10px",
    letterSpacing: "1.1px",
    textAlign: "center",
  },
  supportCards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "30px",
    marginRight: "20px",
    width: { xs: "100%", sm: "50%" },
    padding: "20px",
    "&:hover": {
      backgroundColor: "#ededed",
    },
  },
  icon: {
    width: { xs: "110px", md: "150px" },
  },
  cardTitle: {
    fontSize: { xs: "16px", sm: "18px", md: "22px" },
    margin: "16px",
  },
  cardContent: {
    fontSize: { xs: "16px", md: "18px" },
    margin: "10px",
  },
  cardMore: {
    fontSize: { xs: "16px", md: "18px" },
    marginBottom: "20px",
  },
  email: {
    color: "black",
    "&:hover": {
      color: "#69B3C4",
    },
  },
};
const Support = (): JSX.Element => {
  return (
    <Container>
      <Box sx={classes.container}>
        <Typography sx={classes.pageTitle}>SUPPORT</Typography>
        <Typography sx={classes.subtitle}>How can we help?</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={classes.supportCards}>
            <Link href="mailto:support@kloutgroup.com">
              <Box component="img" src={email} sx={classes.icon} />
            </Link>
            <Typography sx={classes.cardTitle}>CONTACT US</Typography>
            <Typography sx={classes.cardContent}>
              If you're unable to locate your question(s) within our
              <Link href="/faq" underline="none" color="inherit">
                &nbsp;FAQs
              </Link>
              , please contact us&nbsp;
              <Link
                href="mailto:support@kloutgroup.com"
                color="inherit"
                sx={classes.email}
              >
                support@kloutgroup.com
              </Link>
            </Typography>
          </Box>
          <Box sx={classes.supportCards}>
            <Box
              component="img"
              src={general_question}
              sx={{
                width: { xs: "110px", md: "150px" },
                marginBottom: "4px",
              }}
            />
            <Typography sx={classes.cardTitle}>GENERAL QUESTIONS</Typography>
            <Typography sx={classes.cardContent}>
              We've done our best to compile our most frequently asked questions
              in the
              <Link href="/faq" underline="none" color="inherit">
                &nbsp;FAQ &nbsp;
              </Link>
              section.
            </Typography>
            <Typography sx={classes.cardMore}>
              You may search your query, and we encourage you to check back any
              time you have a new one, as this is regularly updated to include
              new Q&As!
            </Typography>
          </Box>
          <Box sx={classes.supportCards}>
            <Link href="mailto:support@kloutgroup.com">
              <Box component="img" src={billing_question} sx={classes.icon} />
            </Link>
            <Typography sx={classes.cardTitle}>BILLING QUESTIONS</Typography>
            <Typography sx={classes.cardContent}>
              Can't find the answer to your billing question(s) in our
              <Link href="/faq" underline="none" color="inherit">
                &nbsp;FAQs
              </Link>
              ?
            </Typography>
            <Typography sx={classes.cardMore}>
              No worries! Please send us an email at&nbsp;
              <Link
                href="mailto:support@kloutgroup.com"
                color="inherit"
                sx={classes.email}
              >
                support@kloutgroup.com
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Support;
