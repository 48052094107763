import { RequestOptions } from "src/data/interfaces";
import axiosConfig from "../lib/axios";

export interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
}

const http = {
  get: async <T>(
    url: string,
    params?: any,
    options: RequestOptions = {}
  ): Promise<ApiResponse<T>> => {
    const config = {
      headers: {
        ...(options.token && { Authorization: `Bearer ${options.token}` }),
        ...(options.contentType && {
          "Content-Type": options.contentType ?? "application/json",
        }),
        "Access-Control-Allow-Origin": "*",
      },
    };
    const response = await axiosConfig.get(url, { params, ...config });
    return response as ApiResponse<T>;
  },

  post: async <T>(
    url: string,
    data: any,
    options: RequestOptions = {}
  ): Promise<ApiResponse<T>> => {
    const config = {
      headers: {
        ...(options.token && { Authorization: `Bearer ${options.token}` }),
        ...(options.contentType && {
          "Content-Type": options.contentType ?? "application/json",
        }),
      },
    };
    const response = await axiosConfig.post(url, data, config);
    return response as ApiResponse<T>;
  },

  put: async <T>(
    url: string,
    data: any,
    options: RequestOptions = {}
  ): Promise<ApiResponse<T>> => {
    const config = {
      headers: {
        ...(options.token && { Authorization: `Bearer ${options.token}` }),
        ...(options.contentType && {
          "Content-Type": options.contentType ?? "application/json",
        }),
      },
    };
    const response = await axiosConfig.put(url, data, config);
    return response as ApiResponse<T>;
  },

  del: async <T>(
    url: string,
    options: RequestOptions = {}
  ): Promise<ApiResponse<T>> => {
    const config = {
      headers: {
        ...(options.token && { Authorization: `Bearer ${options.token}` }),
        ...(options.contentType && {
          "Content-Type": options.contentType ?? "application/json",
        }),
      },
    };
    const response = await axiosConfig.delete(url, config);
    return response as ApiResponse<T>;
  },
};

export default http;
