import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Logout from "@mui/icons-material/Logout";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import axiosConfig from "src/lib/axios";
import jwt_decode from "jwt-decode";
import classes from "../Navbar/styles/styles";
import http from "src/services/http";

interface Props {
  isHovered?: boolean;
  openHamburgerMenu?: boolean;
  startScroll?: boolean;
  isDashboard?: boolean;
  isOpenDropdown?: boolean;
  setIsOpenDropdown?: any;
}
export default function AccountMenu({
  isHovered,
  openHamburgerMenu,
  startScroll,
  isDashboard,
  isOpenDropdown,
  setIsOpenDropdown,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token !== null) {
      http.get("/api/v1/user/login", {}, { token: token }).then((res: any) =>
        setUser({
          first_name: res.data.first_name,
          last_name: res.data.last_name,
        })
      );
    }
    const accountToken = window.localStorage.getItem("accountToken");
    if (accountToken !== null) {
      const userObj: any = jwt_decode(accountToken);
      console.log(userObj);
      const email: string = userObj.email
        ? userObj.email
        : userObj.preferred_username;
        axiosConfig.get(`/api/v1/user/get_by_email?email=${email}`).then((res: any) => {
        setUser({
          first_name: res.data.first_name
            ? res.data.first_name
            : email.split("@")[0],
          last_name: res.data.last_name ? res.data.last_name : "",
        });
      });
    }
  }, []);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenDropdown(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenDropdown(false);
  };
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("accountToken");
    navigate("/");

    window.location.reload();
  };
  function stringAvatar(name: string) {
    return {
      sx: {
        background: "grey",
        marginRight: "5px",
      },
      children:
        name.split(" ")[1] === ""
          ? `${name[0][0].toUpperCase()}`
          : `${name.split(" ")[0][0].toUpperCase()}${name
              .split(" ")[1][0]
              .toUpperCase()}`,
    };
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Button
          disableRipple
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            textTransform: "capitalize",
            "&:hover": {
              background: "none",
            },
            "&:active": {
              background: "none",
            },
          }}
        >
          {!openHamburgerMenu ? (
            <>
              <Avatar
                {...stringAvatar(user.first_name + " " + user.last_name)}
              />
              <Typography
                sx={
                  isDashboard
                    ? classes.blackName
                    : isHovered || startScroll || isOpenDropdown
                    ? classes.blackName
                    : classes.whiteName
                }
              >
                {user.first_name + " " + user.last_name}
              </Typography>
              {!open ? (
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDashboard
                      ? "black"
                      : isHovered || startScroll || isOpenDropdown
                      ? "black"
                      : "white",
                    marginTop: "5px",
                    fontSize: "18px",
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  sx={{
                    color: isDashboard
                      ? "black"
                      : isHovered || startScroll || isOpenDropdown
                      ? "black"
                      : "white",
                    marginTop: "5px",
                    fontSize: "18px",
                  }}
                />
              )}
            </>
          ) : (
            <Avatar {...stringAvatar(user.first_name + " " + user.last_name)} />
          )}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
         {isDashboard ? (
        <MenuItem  onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeOutlined fontSize="medium" />
          </ListItemIcon>
          Home
        </MenuItem>
      ) : (
        <MenuItem onClick={() => navigate("/dashboard")}>
          <Avatar /> Profile
        </MenuItem>
      )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" sx = {{ ml: "4px" }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}