import { useState } from "react";
import { Box } from "@mui/material";
import DashboardNavBar from "src/components/Navbar/DashboardNavbar";
import DashboardSidebar from "src/components/DashboardSidebar";
import classes from "./styles/styles";
import PersonalDashboard from "./PersonalDashboard";
import CompanyDashboard from "./CompanyDashboard";

const Dashboard = (): JSX.Element => {
  const [openDashboardHamburgerMenu, setOpenDashboardHamburgerMenu] =
    useState(false);
  const [isClickSwitchAccount, setIsClickSwitchAccount] = useState(false);

  return (
    <Box sx={classes.container}>
      <DashboardNavBar
        openDashboardHamburgerMenu={openDashboardHamburgerMenu}
        setOpenDashboardHamburgerMenu={setOpenDashboardHamburgerMenu}
      />
      <Box sx={classes.layoutContainer}>
        {!openDashboardHamburgerMenu && (
          <DashboardSidebar
            openDashboardHamburgerMenu={openDashboardHamburgerMenu}
            setIsClickSwitchAccount={setIsClickSwitchAccount}
          />
        )}
        {isClickSwitchAccount ? <CompanyDashboard /> : <PersonalDashboard />}
      </Box>
    </Box>
  );
};

export default Dashboard;
