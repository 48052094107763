import { Button, Box } from "@mui/material";

interface Props {
  icon: string;
  title: string;
  handleLoginButtonClick?: () => void;
}
const classes = {
  btn: {
    m: "0.6em 0",
    border: "1px solid #BABABA",
    height: "60px",
    borderRadius: '50%',
    '&:hover': {
      background: "#FEFEFE",
      border: "1px solid #252525",
   },
  },
};
export const SignButton = (props: Props) => {
  return (
    <Button sx={classes.btn} onClick={props.handleLoginButtonClick}>
      <Box
        component="img"
        src={props.icon}
        sx={{
          width: "24px",
        }}
      />
    </Button>
  );
};
