import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const LoginButton = styled(Button)(() => ({
  textTransform: "none",
  fontWeight: "bold",
  fontSize: "16px",
  backgroundColor: "#ffffff",
  marginRight: "20px",
  marginTop: "8px",
  height: "39px",
  width: "150px",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#ffffff",
  },
}));

export default LoginButton;
