const classes = {
  extendedMenu: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row" },
    backgroundColor: "#ffffff",
    paddingTop: "0.5em",
    borderTop: { xs: "none", sm: "none", md: "1px solid #000000" },
    borderBottom: { xs: "none", sm: "none", md: "1px solid #000000" },
  },
  listItem: {
    padding: { xs: "none", sm: "none", md: "0.8em 1em" },
    justifyContent: { xs: "left", sm: "left", md: "center" },
  },
  listText: {
    color: "black",
    fontSize: {
      sm: "20px",
      md: "14px",
    },
    lineHeight: "22px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    marginLeft: "6px",
    "&:hover": {
      color: "#69B3C4",
    },
    textDecoration: "none",
  },
  listTitle: {
    fontWeight: "bold",
    fontSize: "16px",
    paddingBottom: "0.3em",
    paddingTop: "0.3em",
    marginLeft: "5px",
    color: "black",
    paddingLeft: "16px",
    "&:hover": {
      color: "#69B3C4",
    },
    textTransform: "uppercase",
  },
  linkStyle: {
    textDecoration: "none",
  },
  rightBorder: {
    borderRight: "1px solid  #000000",
    paddingBottom: "10px",
  },
  navbarContainer: {
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    transitionDuration: "1s",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  navbarContainerWithScroll: {
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: "#ffffff",
    borderBottom: "1px solid  #000000",
    transitionDuration: "1s",
    "&:hover": {
      backgroundColor: "#ffffff",
      borderBottom: "1px solid  #000000",
    },
  },
  fontStyleBlack: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: "500",
    "&:hover": {
      color: "#69B3C4",
    },
  },
  fontStyleWhite: {
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    "&:hover": {
      color: "#69B3C4",
    },
  },
  titleBox: {
    paddingTop: "1.5em",
    paddingBottom: "1.5em",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  menuIconBtn: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  menuIcon: {
    display: { xs: "block", sm: "block", md: "none" },
    fontSize: "35px",
    color: "black",
  },
  navLinksWrapper: {
    display: { xs: "none", sm: "none", md: "flex" },
    flexDirection: "row",
    justifyContent: "space-between",
    width: { md: "100%", lg: "180%" },
    paddingLeft: { md: "30px", lg: "200px" },
  },
  blackName: {
    color: "black",
    fontSize: "18px",
    fontWeigh: "bold",
    marginLeft: "5px",
  },
  whiteName: {
    color: "white",
    fontSize: "18px",
    fontWeigh: "bold",
    marginLeft: "5px",
  },
};
export default classes;
