import React from "react";
import { Typography, Box } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InsuranceMenu from "./InsuranceMenu";
import AdvisoryMenu from "./AdvisoryMenu";
import IndustriesMenu from "./IndustriesMenu";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";

const classes = {
  navbarExtendedContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleStyleBlack: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: "500",
  },
  titleStyleWhite: {
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
  },
  titleStyleHover: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#69B3C4",
  },
};
interface Content {
  title: string;
  isHover: boolean;
  color: any;
  setOpenHamburgerMenu: (value: boolean) => void;
}

const ExtendedNavbar = (props: Content) => {
  return (
    <>
      <Box sx={classes.navbarExtendedContainer}>
        <Typography
          sx={
            props.isHover
              ? classes.titleStyleHover
              : props.color === "white"
              ? classes.titleStyleWhite
              : classes.titleStyleBlack
          }
        >
          {props.title}
        </Typography>
        {props.isHover ? (
          <KeyboardArrowUpIcon
            sx={
              props.isHover
                ? classes.titleStyleHover
                : props.color === "white"
                ? classes.titleStyleWhite
                : classes.titleStyleBlack
            }
          />
        ) : (
          <KeyboardArrowDownIcon
            sx={
              props.isHover
                ? classes.titleStyleHover
                : props.color === "white"
                ? classes.titleStyleWhite
                : classes.titleStyleBlack
            }
          />
        )}
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: "68px", width: "100%" }}>
        <Collapse
          in={props.title == "Insurance" && props.isHover}
          timeout={1000}
        >
          <div>
            <Fade
              in={props.title == "Insurance" && props.isHover}
              timeout={500}
            >
              <div>
                <InsuranceMenu />
              </div>
            </Fade>
          </div>
        </Collapse>

        <Collapse
          in={props.title == "Advisory" && props.isHover}
          timeout={1000}
        >
          <div>
            <Fade in={props.title == "Advisory" && props.isHover} timeout={500}>
              <div>
                <AdvisoryMenu />
              </div>
            </Fade>
          </div>
        </Collapse>

        <Collapse
          in={props.title == "Industries" && props.isHover}
          timeout={1000}
        >
          <div>
            <Fade
              in={props.title == "Industries" && props.isHover}
              timeout={500}
            >
              <div>
                <IndustriesMenu
                  setOpenHamburgerMenu={props.setOpenHamburgerMenu}
                />
              </div>
            </Fade>
          </div>
        </Collapse>
      </Box>
    </>
  );
};

export default ExtendedNavbar;
