import { forwardRef } from 'react'

import { Box, Typography, List, ListItem, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CircleIcon from '@mui/icons-material/Circle'

import { WhatIsIt } from 'src/data/static/insuranceInfo'

import classes from '../style/index'

interface Props {
    whatIsIt: WhatIsIt
}

const InsuranceDescription = forwardRef<HTMLDivElement, Props>((props: Props, ref): JSX.Element => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const createParagraph = (list: { text: string, isBold: boolean, bulletList?: string[] }[]): JSX.Element => {

        const boldedTexts = list.map((obj, i) => {
            const { text, isBold } = obj
            const key = `text-${i}`
            if (isBold) {
                return <Typography key={key} component="span" fontWeight="bold">{text}</Typography>
            } else {
                return <span key={key}>{text}</span>
            }
        })

        const bulletList = list.map((obj, i) => {
            const { bulletList } = obj
            const key = `bullet-${i}`
            if (bulletList) {
                return <List key={key}>
                    {bulletList.map((bullet, i) => {
                        return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircleIcon sx={classes.dot} />
                            <ListItem key={`bullet-${i}`} sx={classes.bulletText}>{bullet}</ListItem>
                        </Box>
                    })}
                </List>
            } else {
                return <></>
            }
        })

        return <>
            <Typography sx={classes.paragraph} variant="body1" component="p">
                {boldedTexts}
            </Typography>
            <Box mb='20px'>
                {bulletList}
            </Box>
        </>
    }

    return <Box sx={classes.sectionContainer} ref={ref}>
        <Typography sx={classes.sectionTitle}>
            {props.whatIsIt.question}
        </Typography>
        {
            props.whatIsIt.paragraphs.map((paragraph) => {
                return createParagraph(paragraph)
            })
        }
        <img
            src={props.whatIsIt.graphic}
            style={{ height: isMobile ? '70px' : '120px', alignSelf: 'center', marginTop: '35px' }}
        />
    </Box>
})

export default InsuranceDescription