import { useEffect, useState, useRef } from "react"

import { Box, Typography } from "@mui/material"

import { InsuranceInfo } from "../../data/static/insuranceInfo"

import classes from "./style"

import SideStepper from "./components/SideStepper"
import InsuranceDescription from "./components/InsuranceDescription"
import InsuranceCoverage from "./components/InsuranceCoverage"
import InsuranceIsFor from "./components/InsuranceIsFor"
import ScrollToTop from "src/components/ScrollToTop/ScrollToTop"

const InsuranceInfoPage = (props: InsuranceInfo): JSX.Element => {
  const whatIsItRef = useRef<HTMLDivElement>(null)
  const coverageRef = useRef<HTMLDivElement>(null)
  const noCoverageRef = useRef<HTMLDivElement>(null)
  const whoIsItForRef = useRef<HTMLDivElement>(null)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight
      const refs = [whatIsItRef, coverageRef, noCoverageRef, whoIsItForRef]

      let activeSection = -1
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i].current
        if (ref) {
          const rect = ref.getBoundingClientRect()
          if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
            activeSection = i
            break
          }
        }
      }

      if (activeSection >= 0) {
        setActiveStep(activeSection)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.insuranceTitle}>{props.name}</Typography>
      <Box sx={classes.main}>
        <SideStepper
          activeStep={activeStep}
          handleScroll={(index) => {
            const element = [
              whatIsItRef,
              coverageRef,
              noCoverageRef,
              whoIsItForRef,
            ][index].current
            if (element) {
              const topOffset =
                element.getBoundingClientRect().top + window.scrollY - 100
              window.scrollTo({ top: topOffset, behavior: "smooth" })
            }
          }}
        />
        <Box sx={classes.content}>
          <InsuranceDescription ref={whatIsItRef} whatIsIt={props.whatIsIt} />
          <InsuranceCoverage ref={coverageRef} coverage={props.coverage} />
          <InsuranceCoverage ref={noCoverageRef} coverage={props.noCoverage} />
          <InsuranceIsFor ref={whoIsItForRef} whoIsItFor={props.whoIsItFor} />
        </Box>
      </Box>
      <ScrollToTop />
    </Box>
  )
}

export default InsuranceInfoPage
