const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    mt: "67px", // height of the navabar
    padding: "50px 30px 50px 30px",
  },
  main: {
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    width: "100%",
    mt: {
      xs: "40px",
      sm: "60px",
      md: "80px",
    },
  },
  stepperContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    mb: {
      xs: "40px",
      md: "0",
    },
    position: { md: "fixed" },
  },
  stepper: {
    position: "sticky",
    top: "50px",
    width: "100%",
    maxWidth: "300px",
    zIndex: 1,
  },
  content: {
    flex: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: {
      xs: "0 20px",
      sm: "0 60px",
      md: "0 100px",
    },
    marginLeft: { md: "300px" },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    mb: {
      xs: "50px",
      sm: "70px",
    },
    maxWidth: "800px",
    width: "100%",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontSize: {
      xs: "16px",
      sm: "24px",
    },
    fontWeight: "bold",
    mb: "20px",
  },
  paragraph: {
    mb: {
      xs: "0",
      sm: "15px",
    },
    fontSize: {
      xs: "14px",
      md: "17px",
    },
    paddingRight: {
      xs: "0",
      sm: "30px",
    },
  },
  expandableSection: {
    width: "100%",
    mb: "20px",
  },
  coverageSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: {
      xs: "#F5F5F5",
      sm: "white",
    },
    margin: {
      xs: "10px 0",
      sm: "0",
    },
    borderRadius: {
      xs: "5px",
      sm: "0",
    },
    padding: {
      xs: "10px",
      sm: "0",
    },
  },
  listItem: {
    padding: "25px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  listItemTitle: {
    textTransform: "uppercase",
    fontSize: {
      xs: "13px",
      sm: "17px",
    },
    fontWeight: {
      xs: "bold",
      sm: "normal",
    },
    ":hover": {
      cursor: "pointer",
    },
    marginRight: {
      xs: "10px",
      sm: "0",
    },
  },
  insuranceTitle: {
    textTransform: "uppercase",
    fontSize: {
      xs: "24px",
      sm: "32px",
      md: "40px",
    },
    fontWeight: "bold",
    textAlign: "center",
  },
  step: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  stepDot: {
    mr: "8px",
    ml: "8px",
    height: "8px",
    width: "8px",
    border: "1px solid black",
    borderRadius: "50%",
  },
  stepLabel: {
    textDecoration: "none",
    textTransform: "none",
    color: "black",
    ":hover": {
      cursor: "pointer",
    },
  },
  dot: {
    height: {
      xs: "4px",
      sm: "6px",
    },
    ml: {
      xs: "4px",
      sm: "8px",
    },
    mr: {
      xs: "4px",
      sm: "8px",
    },
  },
  bulletText: {
    fontSize: {
      xs: "14px",
      md: "17px",
    },
    padding: {
      md: "8px 0",
    },
  },
  label: {
    fontSize: {
      xs: "15px",
      md: "18px",
    },
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: {
      md: "40px 0 10px 0",
    },
  },
};

export default classes;
