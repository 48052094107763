import { Box, Link, Drawer } from "@mui/material";
import kloutLogo from "../../assets/icons/klout-black-logo.svg";
import AccountMenu from "../authentication/AccountMenu";
import DashboardSidebar from "../DashboardSidebar";
import bell from "../../assets/icons/bell.svg";

const classes = {
  icon: {
    width: "42px",
    height: "42px",
    mr: "10px",
    mt: "4px",
  },
};
interface Props {
  openDashboardHamburgerMenu: boolean;
  setOpenDashboardHamburgerMenu: any;
}
export const DashboardHamburgerMenu = ({
  openDashboardHamburgerMenu,
  setOpenDashboardHamburgerMenu,
}: Props) => {
  const toggleDrawer = () => {
    setOpenDashboardHamburgerMenu(false);
  };

  return (
    <Drawer
      anchor="right"
      open={openDashboardHamburgerMenu}
      onClose={toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "45%", md: 0 },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Link href="/" underline="none">
          <img
            src={kloutLogo}
            alt="klout-logo"
            style={{
              paddingTop: "8.5px",
              marginLeft: "24px",
              height: "50px",
              width: "120px",
            }}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            paddingTop: "10px",
          }}
        >
          <Box component="img" alt={"Home"} src={bell} sx={classes.icon} />
          <AccountMenu openHamburgerMenu={openDashboardHamburgerMenu} />
        </Box>
      </Box>

      <DashboardSidebar openDashboardHamburgerMenu={true} />
    </Drawer>
  );
};
