import { Box, IconButton, Link } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import kloutLogo from "../../assets/icons/klout-black-logo.svg";
import bell from "../../assets/icons/bell.svg";
import AccountMenu from "../authentication/AccountMenu";
import { DashboardHamburgerMenu } from "./DashboardHamburgerMenu";

const classes = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    pt: "20px",
    pb: "20px",
    width: "100%",
  },
  box: {
    display: { xs: "none", sm: "flex" },
    mr: { sm: "25px", md: "40px" },
  },
  icon: {
    width: "42px",
    height: "42px",
    mr: { sm: "10px", md: "20px" },
    mt: "4px",
    "&:hover": {
      backgroundcolor: "#109CF1",
    },
  },
  menuIconBtn: {
    position: "absolute",
    top: "10px",
    right: "20px",
  },
  menuIcon: {
    display: { xs: "block", sm: "none" },
    fontSize: "35px",
    color: "black",
  },
};
interface Props {
  openDashboardHamburgerMenu: boolean;
  setOpenDashboardHamburgerMenu: any;
}
const DashboardNavBar = ({
  openDashboardHamburgerMenu,
  setOpenDashboardHamburgerMenu,
}: Props) => {
  return (
    <>
      <Box sx={classes.container}>
        <Link href="/">
          <Box
            component="img"
            src={kloutLogo}
            height="50px"
            width="120px"
            marginLeft="34px"
            marginTop="-10px"
            alt="Klout logo"
          />
        </Link>
        <Box>
          <Box sx={classes.box}>
            <Box component="img" alt={"Home"} src={bell} sx={classes.icon} />
            <AccountMenu isDashboard={true} />
          </Box>
          <IconButton
            aria-label="Show menu"
            onClick={() => {
              setOpenDashboardHamburgerMenu(true);
            }}
            sx={classes.menuIconBtn}
          >
            <MenuIcon sx={classes.menuIcon} />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <DashboardHamburgerMenu
          openDashboardHamburgerMenu={openDashboardHamburgerMenu}
          setOpenDashboardHamburgerMenu={setOpenDashboardHamburgerMenu}
        />
      </Box>
    </>
  );
};

export default DashboardNavBar;
