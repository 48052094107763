import { useState } from "react";
import Typed from "react-typed";
import { Animator, Fade } from "react-scroll-motion";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, IconButton, Button } from "@mui/material";
import background from "../../assets/img/home-background-klout.jpg";

const classes = {
  container: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  layer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: { xs: "40px", sm: "0px" },
  },
  mainQuote: {
    fontSize: { xs: "25px", sm: "30px", md: "40px" },
    color: "white",
    paddingLeft: { xs: "50px", sm: "30px" },
    paddingRight: { xs: "35px", sm: "0" },
  },
  quotesBtn: {
    background: "white",
    color: "black",
    fontSize: "25px",
    fontWeight: "bold",
    width: "250px",
    position: "absolute",
    bottom: "60px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  welcome: {
    backgroundColor: "#EFEFEF",
    borderRadius: "40px",
    color: "black",
    textAlign: "center",
    padding: "0 20px",
    justifyContent: "center",
    margin: "40px 0",
    position: "relative",
    paddingTop: "25px",
    width: { xs: "80vw", sm: "520px" },
  },
};
const Welcome = (): JSX.Element => {
  const [welcomeMsg, setWelcomeMsg] = useState(true);

  return (
    <>
      <Animator animation={Fade()}>
        <Box sx={classes.container}>
          <Box sx={classes.layer}>
            <Box
              sx={{
                ...classes.mainQuote,
                marginTop: { xs: !welcomeMsg ? "-300px" : "-70px", sm: 0 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "25px", sm: "30px", md: "40px" },
                  height: 0,
                  opacity: 0,
                }}
              >
                Built on Relationships, Optimized by Technology.
              </Typography>
              <Typed
                strings={["Built on Relationships, Optimized by Technology."]}
                typeSpeed={40}
                showCursor={false}
              />
            </Box>
            {welcomeMsg ? (
              <Box sx={classes.welcome}>
                <Box
                  sx={{
                    position: "absolute",
                    right: { xs: "15px", sm: "20px" },
                    top: { xs: "18px", sm: "18px" },
                  }}
                >
                  <IconButton
                    aria-label="Close welcome message window"
                    onClick={() => setWelcomeMsg(false)}
                  >
                    <CloseIcon
                      sx={{
                        color: "black",
                        fontSize: { xs: "25px", sm: "35px" },
                      }}
                    />
                  </IconButton>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    fontSize: { xs: "20px", sm: "25px" },
                    fontWeight: "bold",
                  }}
                >
                  Welcome!
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "20px",
                    fontSize: "18px",
                  }}
                >
                  We're happy you're here. Our website is currently under
                  construction as we are working to create a platform that will
                  better serve you.
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "40px",
                    fontSize: "18px",
                  }}
                >
                  You can still use it! Just please pardon a strange look here
                  or broken link there. If something you need isn't working for
                  you, please contact us at&nbsp;
                  <strong style={{ color: "#053B5C" }}>
                    support@kloutgroup.com.
                  </strong>
                </Typography>
              </Box>
            ) : null}
            {/* <Button
          fullWidth
          aria-label="Get Quotes"
          onClick={() => {
            setOpenDialogQuote(true);
          }}
          sx={classes.quotesBtn}
        >
          Get Quotes
        </Button>
        {openDialogQuote && <UserForm />} */}
          </Box>
        </Box>
      </Animator>
    </>
  );
};

export default Welcome;
