import { configureStore, combineReducers, Reducer, ThunkAction, Action } from "@reduxjs/toolkit";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import formReducer, { FormState } from './formSlice';
export interface RootState {
  form: FormState;
}

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  form: formReducer,


}) as Reducer<RootState>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
export default store;