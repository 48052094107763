import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { InsuranceCard } from "src/data/static/insurances";

const classes = {
  cardGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  },
  cardContainer: {
    border: "3px solid black",
    borderRadius: "5px",
    width: {
      xs: "100%",
      sm: "360px",
    },
    maxWidth: {
      xs: "320px",
      sm: "360px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    width: "90%",
    border: "2px solid black",
    borderRadius: "5px",
    padding: "0 8px",
  },
  titleBoxTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: "1.5px",
    fontSize: {
      xs: "18px",
      sm: "22px",
    },
    textAlign: "center",
    lineHeight: "1.2",
    padding: "3px 5px 10px 5px",
  },
  titleBoxSubtitle: {
    textTransform: "uppercase",
    fontSize: {
      xs: "11px",
      md: "14px",
    },
    mt: "8px",
    letterSpacing: "0.8px",
    fontWeight: "bold",
  },
  segmentTitle: {
    textTransform: "uppercase",
    fontSize: {
      xs: "14px",
      sm: "18px",
    },
    mt: "0.6em",
  },
  descriptionText: {
    textAlign: "center",
    fontSize: {
      xs: "12px",
      sm: "14px",
    },
    padding: "0 10px",
  },
  coverageText: {
    letterSpacing: "0.8px",
    fontSize: {
      xs: "12px",
      sm: "14px",
    },
    mb: "0.2em",
  },
  buttonGroup: {
    mt: "0.8em",
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    border: "2px solid black",
    borderRadius: "5px",
    fontWeight: "bold",
    letterSpacing: "1.3px",
    fontSize: {
      xs: "13px",
      sm: "16px",
    },
    "&:hover": {
      color: "black",
    },
    width: "40%",
  },
  coverageList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
  },
  dot: {
    height: {
      xs: "4px",
      sm: "6px",
    },
    ml: {
      xs: "4px",
      sm: "8px",
    },
    mr: {
      xs: "4px",
      sm: "8px",
    },
  },
};

interface Props {
  insurance: InsuranceCard;
  isHovered: boolean;
  isFocused: boolean;
  isStatic: boolean;
  isMobile: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const MonopolyCard = (props: Props): JSX.Element => {
  const { isHovered, isFocused, isStatic, isMobile } = props;

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      sx={classes.cardGrid}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Box
        sx={{
          ...classes.cardContainer,
          height: {
            xs: "auto",
            sm: props.insurance.height,
          },
          transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out",
          transform:
            isHovered || isFocused || isStatic ? "scale(1)" : "scale(0.9)",
          opacity: isHovered || isFocused || isStatic ? "1" : "0.6",
          cursor: isHovered || isFocused || isStatic ? "pointer" : "default",
          mb: isStatic ? "40px" : "0px",
        }}
      >
        <Box
          sx={{ ...classes.titleBox, backgroundColor: props.insurance.color }}
        >
          <Typography
            sx={{
              ...classes.titleBoxSubtitle,
              color: props.insurance.whiteText ? "white" : "black",
            }}
          >
            Policy type
          </Typography>
          <Typography
            sx={{
              ...classes.titleBoxTitle,
              color: props.insurance.whiteText ? "white" : "black",
            }}
          >
            {props.insurance.type}
          </Typography>
        </Box>
        <Typography sx={classes.segmentTitle}>What does it cover?</Typography>
        <Typography sx={classes.descriptionText}>
          {props.insurance.description}
        </Typography>
        <Typography sx={classes.segmentTitle}>Specific coverages:</Typography>
        <Box sx={classes.coverageList}>
          <List>
            {props.insurance.coverages.map((option) => {
              return (
                <ListItem disablePadding key={option}>
                  <CircleIcon sx={classes.dot} />
                  <ListItemText
                    disableTypography
                    primary={option}
                    sx={classes.coverageText}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box sx={classes.buttonGroup}>
          {isMobile || isHovered || isStatic ? (
            <Button
              href={props.insurance.href}
              sx={{
                ...classes.button,
                backgroundColor: props.insurance.color,
                color: props.insurance.whiteText ? "white" : "black",
                opacity: isHovered || isStatic ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              Learn more
            </Button>
          ) : (
            <></>
          )}
          {/* <Button sx={{ ...classes.button, backgroundColor: props.insurance.color, color: props.insurance.whiteText ? 'white' : 'black' }}>
                        Get quotes
                    </Button> */}
        </Box>
      </Box>
    </Grid>
  );
};

export default MonopolyCard;
