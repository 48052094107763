import { useEffect, useState } from "react";
import { Typography, Box, Link, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import kloutLogo from "../../assets/icons/klout-black-logo.svg";
import ExtendedNavbar from "./components/ExtendedNavbar";
import { HamburgerMenu } from "./HamburgerMenu";
import { LoggedUser } from "./LoggedUser";
import AuthDialog from "../authentication/AuthDialog";
import { useHover } from "../../hooks/useHover";
import classes from "./styles/styles";

const NavbarBlack = () => {
  const [hoverRefInsurance, isHoveredInsurance] = useHover<HTMLDivElement>();
  const [hoverRefAdvisory, isHoveredAdvisory] = useHover<HTMLDivElement>();
  const [hoverRefIndustries, isHoveredIndustries] = useHover<HTMLDivElement>();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const [isLoginBtnCliked, setIsLoginBtnCliked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const accountToken = window.localStorage.getItem("accountToken");
    if (token !== null || accountToken !== null) {
      setIsLoggedIn(true);
    }
  }, []);
  const handleOpenLoginDialog = () => {
    setOpenAuthDialog(true);
    setIsLoginBtnCliked(true);
  };

  const handleOpenRegisterDialog = () => {
    setOpenAuthDialog(true);
    setIsLoginBtnCliked(false);
  };

  return (
    <>
      <Box
        sx={{
          ...classes.navbarContainer,
          background: "white",
          borderBottom: "2px solid black",
        }}
        ref={hoverRef}
      >
        {!openHamburgerMenu ? (
          <Link href="/" underline="none">
            <Box
              component="img"
              src={kloutLogo}
              alt="Klout logo"
              sx={{
                position: { lg: "absolute", left: "24px", top: "0" },
                paddingTop: "8.5px",
                height: "50px",
                width: "120px",
              }}
            />
          </Link>
        ) : null}

        <IconButton
          onClick={() => {
            setOpenHamburgerMenu(!openHamburgerMenu);
          }}
          sx={classes.menuIconBtn}
        >
          <MenuIcon sx={classes.menuIcon} />
        </IconButton>
        <Box sx={classes.navLinksWrapper}>
          <Box ref={hoverRefInsurance} sx={classes.titleBox}>
            <Link href="/insurance" underline="none">
              <ExtendedNavbar
                color={"black"}
                title="Insurance"
                isHover={isHoveredInsurance}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box ref={hoverRefAdvisory} sx={classes.titleBox}>
            <Link href="/advisory" underline="none">
              <ExtendedNavbar
                title="Advisory"
                color={"black"}
                isHover={isHoveredAdvisory}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box ref={hoverRefIndustries} sx={classes.titleBox}>
            <Link href="/industries" underline="none">
              <ExtendedNavbar
                title="Industries"
                color={"black"}
                isHover={isHoveredIndustries}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box sx={classes.titleBox}>
            <Link href="/faq" underline="none">
              <Typography sx={classes.fontStyleBlack}>FAQ</Typography>
            </Link>
          </Box>
          <Box sx={classes.titleBox}>
            <Link href="/support" underline="none">
              <Typography sx={classes.fontStyleBlack}>Support</Typography>
            </Link>
          </Box>
        </Box>
        <LoggedUser
          isLoggedIn={isLoggedIn}
          isHovered={true}
          handleOpenLoginDialog={handleOpenLoginDialog}
          handleOpenRegisterDialog={handleOpenRegisterDialog}
        />
      </Box>

      {openAuthDialog ? (
        <AuthDialog
          isOpen={openAuthDialog}
          setIsLoggedIn={setIsLoggedIn}
          isLoginBtnCliked={isLoginBtnCliked}
          handleClose={() => setOpenAuthDialog(false)}
          nextStep={() => {}}
        />
      ) : (
        <></>
      )}

      <Box>
        {openHamburgerMenu ? (
          <HamburgerMenu
            openHamburgerMenu={openHamburgerMenu}
            setOpenHamburgerMenu={setOpenHamburgerMenu}
            handleOpenLoginDialog={handleOpenLoginDialog}
            handleOpenRegisterDialog={handleOpenRegisterDialog}
            isLoggedIn={isLoggedIn}
          />
        ) : null}
      </Box>
    </>
  );
};

export default NavbarBlack;
