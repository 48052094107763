import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import SupportCard from "./component/SupportCard";
import one from "../../../assets/icons/one.svg";
import two from "../../../assets/icons/two.svg";
import three from "../../../assets/icons/three.svg";

const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: { xs: "100%", sm: "100vh" },
    width: "100vw",
  },
  title: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
      xl: "39px",
    },
    fontWeight: "bold",
    textAlign: "center",
    alignItems: "center",
    marginTop: {
      xs: "30px",
      sm: "50px",
      md: "70px",
      lg: "90px",
      xl: "110px",
    },
  },
  subtitle: {
    fontSize: {
      xs: "16px",
      sm: "18px",
      md: "20px",
    },
    letterSpacing: "1.1px",
    textAlign: "center",
    alignItems: "center",
    fontWeight: "bold",
    marginTop: {
      xs: "14px",
      sm: "16px",
      md: "18px",
      lg: "20px",
      xl: "24px",
    },
  },
};

const HowItWorks = () => {
  return (
    <Box sx={classes.container}>
      <Typography sx={classes.title}>HOW IT WORKS</Typography>
      <Typography sx={classes.subtitle}>It's as easy as 1, 2, 3...</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          width: "70%",
          margin: "30px 0px",
          mt: { xs: "0px", md: "50px" },
        }}
      >
        <SupportCard
          icon={one}
          title="Create your profile"
          subtitlePrimary="Enter your personal or company details."
          subtitleSecondary="Connect with an Account Manager directly, or proceed with automatic quoting."
          transitionDelay={"200ms"}
          altTag={"First Step"}
        />
        <SupportCard
          icon={two}
          title="Review your options"
          subtitlePrimary="View quotes from multiple carriers."
          subtitleSecondary="Meet with a specialist to answer questions, or simply select
          your preferred policy(s)."
          transitionDelay={"400ms"}
          altTag={"Second Step"}
        />
        <SupportCard
          icon={three}
          title="Bind & pay"
          subtitlePrimary="Set up your digital wallet & complete purchase via the platform."
          subtitleSecondary="Pay your premium in full or expolore available premium financing
          options."
          transitionDelay={"600ms"}
          altTag={"Third Step"}
        />
      </Box>
    </Box>
  );
};
export default HowItWorks;
