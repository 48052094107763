import {
  Typography,
  Grid,
  Button,
  Avatar,
  Container,
  Box
} from "@mui/material"
import { Link } from 'react-router-dom'
import { IPageContent } from "src/data/static/pages"

const classes = {
  container: {
    mt: "67px", // height of Navbar
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 30px 50px 30px",
  },
  pageTitle: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
    },
    mt: "20px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
  },
  subtitle: {
    fontSize: {
      xs: "14px",
      sm: "20px",
      md: "24px",
      lg: "30px",
    },
    letterSpacing: "1.1px",
  },
  cardContainer: {
    maxWidth: "1200px",
    mt: {
      xs: "1em",
      sm: "2em",
      md: "3em",
    },
  },
  industryCard: {
    paddingTop: "13px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    ":hover": {
      backgroundColor: "#ededed",
    },
    ":active": {
      backgroundColor: "#d6d6d6",
    },
  },
  cardTitle: {
    color: "black",
    textTransform: "Uppercase",
    textAlign: "center",
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
    },
  },
  button: {
    backgroundColor: "black",
    borderRadius: "10px",
    margin: "3em",
    padding: "10px 40px",
    "&:hover": {
      color: "black",
    },
    textTransform: "Uppercase",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  image: {
    height: {
      xs: "70px",
      sm: "85px",
      md: "100px",
    },
    width: {
      xs: "70px",
      sm: "85px",
      md: "100px",
    },
  },
  main: {
    position: "relative",
    display: "flex",
    flexDirection: {
      xs: "column",
      md: "row",
    },
    width: "100%",
    mt: {
      xs: "40px",
      sm: "60px",
      md: "80px",
    },
    mb: {
      xs: "30px",
      md: "50px",
    },
  },
  content: {
    flex: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: {
      xs: "0 20px",
      sm: "0 60px",
      md: "0 100px",
    },
    marginLeft: { md: "200px" },
  },
  sectionTitle: {
    fontSize: {
      md: "24px",
    },
    fontWeight: "bold",
    textTransform: "uppercase",
    mb: {
      md: "1.5em",
    },
  },
  optionLabel: {
    fontSize: {
      md: "20px",
    },
    textTransform: "uppercase",
    textDecoration: "underline",
  },
  optionDesc: {
    maxWidth: "800px",
    fontSize: {
      md: "16px",
    },
    margin: {
      md: "1em 0",
    },
  },
}

const Industries: React.FC<{ pageContent: IPageContent }> = ({ pageContent }) => {
  return (
    <Container sx={classes.container}>
      <Typography sx={classes.pageTitle}>{pageContent.title}</Typography>
      <Typography sx={classes.subtitle}>{pageContent.subtitle}</Typography>
      <Grid container sx={classes.cardContainer} spacing={{ xs: 2 }}>
        {pageContent.main.map((option, index) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              sx={{
                mt: {
                  xs: "2em",
                  sm: index >= 3 ? "2em" : 0,
                  md: index >= 4 ? "3em" : 0,
                },
              }}
              key={option.title}
            >
              <Link to={`/industries-detailed?section=${option.stepId}`} style={{ textDecoration: 'none' }}>
                <Box
                  sx={classes.industryCard}
                >
                  <Avatar
                    variant="square"
                    src={option.icon}
                    sx={classes.image}
                  />
                  <Typography sx={classes.cardTitle}>
                    {option.title}
                  </Typography>
                </Box>
              </Link>
            </Grid>
          )
        })}
      </Grid>
      <Button sx={classes.button} href={"/industries-detailed"}>
        View all covered industries
      </Button>
    </Container >
  )
}

export default Industries
