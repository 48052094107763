import './styles/slideIndicator.css'

interface SlideIndicatorProps {
    length: number
    current: number
}

const SlideIndicator = ({ length, current }: SlideIndicatorProps) => {
    const indicators = []

    for (let i = 0; i < length; i++) {
        indicators.push(
            <div
                key={i}
                className={`indicator ${current === i ? 'active' : ''}`}
            ></div>
        )
    }

    return <div className="indicator-container">{indicators}</div>
}

export default SlideIndicator