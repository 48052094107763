import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const LoginButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "bold",
  fontSize: "18px",
  height: "55px",
  width: "170px",
  color: "#000000",
  border: "1px solid #b5b7ba",
  borderRadius: "20px",
  marginTop: "15px",
  marginRight: "5px",
}));

export default LoginButton;
