import { Typography, Box, Grid, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { commercialInusrances } from "src/data/static/insurances";
import { personalInsurances } from "src/data/static/insurances";

import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import MonopolyCard from "src/components/MonopolyCard";

const classes = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
    },
    padding: {
      xs: "40px 0",
    },
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
  },
  pageContent: {
    display: "flex",
  },
};

const Insurance = (): JSX.Element => {
  const commercialRef = useRef<HTMLDivElement | null>(null);
  const personalRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const queryParams = new URLSearchParams(location.search);
  const sectionId = queryParams.get("section");

  useEffect(() => {
    if (sectionId === "commercial") {
      const element = commercialRef.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (sectionId === "personal") {
      const element = personalRef.current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [sectionId]);

  return (
    <Container sx={classes.container}>
      <Typography sx={classes.pageTitle}>
        Commercial Insurance Policies
      </Typography>
      <Box ref={commercialRef} sx={classes.pageContent}>
        <Grid container>
          {commercialInusrances.map((item: any) => (
            <MonopolyCard
              key={item.description}
              insurance={item}
              isStatic
              isFocused
              isHovered
              isMobile={isMobile}
            />
          ))}
        </Grid>
      </Box>
      <Typography sx={classes.pageTitle}>
        Personal Insurance Policies
      </Typography>
      <Box ref={personalRef} sx={classes.pageContent}>
        <Grid container>
          {personalInsurances.map((item: any) => (
            <MonopolyCard
              key={item.description}
              insurance={item}
              isStatic
              isFocused
              isHovered
              isMobile={isMobile}
            />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Insurance;
