import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router";
import MonopolyCard from "src/components/MonopolyCard";
import insurances from "src/data/static/insurances";
import axiosConfig from "src/lib/axios";
import ResponseVerificationEmailDialog from "src/components/authentication/ResponseVerificationEmailDialog";
import Carousel from "./Carousel";
import HowItWorks from "./HowItWorks/HowItWorks";
import Welcome from "./Welcome";

const Home = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [focusedCard, setFocusedCard] = useState(1);
  const [middleCard, setMiddleCard] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const code = useParams();
  const [isVerified, setIsVerified] = useState(false);
  const [isOpenVerificationEmailDialog, setIsOpenVerificationEmailDialog] =
    useState(false);
  const [
    isOpenSuccessVerificationEmailDialog,
    setIsOpenSuccessVerificationEmailDialog,
  ] = useState(false);
  const [isLoginBtnCliked, setIsLoginBtnCliked] = useState(false);
  const [name, setName] = useState({
    first_name: "",
  });

  const handleMouseEnter = (card: number) => {
    setFocusedCard(card); // set the focused card on hover
    setIsHovering(true); // stop the timer when hover starts
  };

  const handleMouseLeave = (card: number) => {
    setFocusedCard(card - 1); // reset the focus when not hovering
    setIsHovering(false); // restart the timer when hover ends
  };

  const getVerificationMail = async () => {
    window.history.replaceState(null, "Home path", "/");
    await axiosConfig
      .get(`/api/v1/email/verify_email/${code.code}`)
      .then((res: any) => {
        setIsOpenVerificationEmailDialog(true);
        setIsOpenSuccessVerificationEmailDialog(true);
        setIsLoginBtnCliked(true);
        setName({
          first_name: res.data,
        });
      })
      .catch(() => {
        setIsOpenVerificationEmailDialog(true);
        setIsOpenSuccessVerificationEmailDialog(false);
        setIsLoginBtnCliked(false);
      });
  };

  useEffect(() => {
    if (code.code && !isVerified) {
      setIsVerified(true);
    } else if (code.code) {
      getVerificationMail();
    } else {
      const handleBeforeUnload = () => {
        window.scrollTo(0, 0);
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isVerified]);

  return (
    <>
      <Welcome />
      <HowItWorks />
      <Carousel
        showDesktop={3}
        showMobile={1}
        isMobile={isMobile}
        type="photo"
        infiniteLoop
        changeFocusedCard={(card) => setFocusedCard(card)}
        changeMiddleCard={(card) => setMiddleCard(card)}
        focusedCard={focusedCard}
        isHovering={isHovering}
      >
        {insurances.commercialInusrances.map((card, index) => {
          return (
            <MonopolyCard
              key={card.description}
              insurance={card}
              isFocused={index === focusedCard}
              isHovered={index === focusedCard && isHovering}
              isStatic={false}
              isMobile={isMobile}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(middleCard)}
            />
          );
        })}
      </Carousel>
      <Box>
        {isOpenVerificationEmailDialog && (
          <ResponseVerificationEmailDialog
            isOpen={isOpenVerificationEmailDialog}
            handleClose={() => setIsOpenVerificationEmailDialog(false)}
            isOpenSuccessVerificationDialog={
              isOpenSuccessVerificationEmailDialog
            }
            isLoginBtnCliked={isLoginBtnCliked}
            name={name.first_name}
          />
        )}
      </Box>
    </>
  );
};

export default Home;
