import { useEffect, useState } from "react";
import { Typography, Box, Link, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import kloutLogo from "../../assets/icons/klout-black-logo.svg";
import kloutLogoWhite from "../../assets/icons/klout-white-logo.svg";
import ExtendedNavbar from "./components/ExtendedNavbar";
import { HamburgerMenu } from "./HamburgerMenu";
import { LoggedUser } from "./LoggedUser";
import AuthDialog from "../authentication/AuthDialog";
import { useHover } from "../../hooks/useHover";
import classes from "./styles/styles";
const Navbar = () => {
  const [hoverRefInsurance, isHoveredInsurance] = useHover<HTMLDivElement>();
  const [hoverRefAdvisory, isHoveredAdvisory] = useHover<HTMLDivElement>();
  const [hoverRefIndustries, isHoveredIndustries] = useHover<HTMLDivElement>();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const [isLoginBtnCliked, setIsLoginBtnCliked] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [startScroll, setStartScroll] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const accountToken = window.localStorage.getItem("accountToken");
    if (token !== null || accountToken !== null) {
      setIsLoggedIn(true);
    }
  }, []);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 30) {
      setStartScroll(true);
    } else {
      setStartScroll(false);
    }
  });

  const handleOpenLoginDialog = () => {
    setOpenAuthDialog(true);
    setIsLoginBtnCliked(true);
  };

  const handleOpenRegisterDialog = () => {
    setOpenAuthDialog(true);
    setIsLoginBtnCliked(false);
  };

  return (
    <>
      <Box
        sx={
          startScroll || isOpenDropdown
            ? classes.navbarContainerWithScroll
            : classes.navbarContainer
        }
        ref={hoverRef}
      >
        {!openHamburgerMenu ? (
          <Link href="/" underline="none">
            <Box
              component="img"
              src={
                isHovered || startScroll || isOpenDropdown
                  ? kloutLogo
                  : kloutLogoWhite
              }
              height="50px"
              width="120px"
              marginLeft={"10px"}
              alt="Klout logo"
              sx={{
                position: { lg: "absolute", left: "24px", top: "0" },
                paddingTop: "8.5px",
              }}
            />
          </Link>
        ) : null}
        <IconButton
          aria-label="Show menu"
          onClick={() => {
            setOpenHamburgerMenu(true);
          }}
          sx={classes.menuIconBtn}
        >
          <MenuIcon
            sx={
              isHovered || startScroll
                ? classes.menuIcon
                : { ...classes.menuIcon, color: "white" }
            }
          />
        </IconButton>

        <Box sx={classes.navLinksWrapper}>
          <Box ref={hoverRefInsurance} sx={classes.titleBox}>
            <Link href="/insurance" underline="none">
              <ExtendedNavbar
                color={
                  isHovered || startScroll || isOpenDropdown ? "black" : "white"
                }
                title="Insurance"
                isHover={isHoveredInsurance}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box ref={hoverRefAdvisory} sx={classes.titleBox}>
            <Link href="/advisory" underline="none">
              <ExtendedNavbar
                title="Advisory"
                color={
                  isHovered || startScroll || isOpenDropdown ? "black" : "white"
                }
                isHover={isHoveredAdvisory}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box ref={hoverRefIndustries} sx={classes.titleBox}>
            <Link href="/industries" underline="none">
              <ExtendedNavbar
                title="Industries"
                color={
                  isHovered || startScroll || isOpenDropdown ? "black" : "white"
                }
                isHover={isHoveredIndustries}
                setOpenHamburgerMenu={setOpenHamburgerMenu}
              />
            </Link>
          </Box>
          <Box sx={classes.titleBox}>
            <Link href="/faq" underline="none">
              <Typography
                sx={
                  isHovered || startScroll || isOpenDropdown
                    ? classes.fontStyleBlack
                    : classes.fontStyleWhite
                }
              >
                FAQ
              </Typography>
            </Link>
          </Box>
          <Box sx={classes.titleBox}>
            <Link href="/support" underline="none">
              <Typography
                sx={
                  isHovered || startScroll || isOpenDropdown
                    ? classes.fontStyleBlack
                    : classes.fontStyleWhite
                }
              >
                Support
              </Typography>
            </Link>
          </Box>
        </Box>
        <LoggedUser
          isLoggedIn={isLoggedIn}
          isHovered={isHovered}
          startScroll={startScroll}
          handleOpenLoginDialog={handleOpenLoginDialog}
          handleOpenRegisterDialog={handleOpenRegisterDialog}
          isOpenDropdown={isOpenDropdown}
          setIsOpenDropdown={setIsOpenDropdown}
        />
      </Box>
      <Box>
        {openAuthDialog ? (
          <AuthDialog
            isOpen={openAuthDialog}
            isLoginBtnCliked={isLoginBtnCliked}
            handleClose={() => setOpenAuthDialog(false)}
            nextStep={() => {}}
            setIsLoggedIn={setIsLoggedIn}
          />
        ) : (
          <></>
        )}
      </Box>

      <Box>
        {openHamburgerMenu ? (
          <HamburgerMenu
            handleOpenLoginDialog={handleOpenLoginDialog}
            handleOpenRegisterDialog={handleOpenRegisterDialog}
            isLoggedIn={isLoggedIn}
            openHamburgerMenu={openHamburgerMenu}
            setOpenHamburgerMenu={setOpenHamburgerMenu}
          />
        ) : null}
      </Box>
    </>
  );
};

export default Navbar;
