export interface InsuranceCard {
  type: string;
  description: string;
  coverages: string[];
  href: string;
  color: string;
  whiteText: boolean;
  height: string;
}

export const commercialInusrances: InsuranceCard[] = [
  {
    type: "General Liability",
    description:
      "This policy covers common business risks including bodily injuries, customer property, and advertising injuries. It's required for most commercial leases.",
    coverages: [
      "Third party bodily injuries.",
      "Third party property damage.",
      "Product liability.",
      "Personal injuries & advertising injuries",
    ],
    href: "/insurance/general-liability",
    color: "blue",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Business Auto",
    description:
      "This policy is usually required for business-owned vehicles. It helps cover the costs of a vehicle accident, theft, or vandalism",
    coverages: [
      "Auto accident liability.",
      "Medical payments.",
      "Phyisical damage and collision.",
      "Accidents involving uninsured motorists.",
    ],
    href: "/insurance/business-auto",
    color: "orange",
    whiteText: false,
    height: "450px",
  },
  {
    type: "Cyber Liability",
    description:
      "This policy helps business survive data breaches and cyberattacks by helping pay for recovery expenses and associated costs.",
    coverages: [
      "Cyber ransom payments.",
      "Business interuption expenses.",
      "Breach response costs.",
    ],
    href: "/insurance/cyber-liability",
    color: "green",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Event Cancellation",
    description:
      "Event cancellation coverage reimburses you for last deposits and other charges when unforeseen circumstances cause you to cancel or postpone your function.",
    coverages: [
      "Adverse weather and natural disasters.",
      "Threats or acts of terrorism.",
      "Non-appearance of a key person.",
    ],
    href: "/insurance/event-cancellation",
    color: "purple",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Professional Liability",
    description:
      "This policy, also called errors and omissions insurance (E&O), can help cover legal expenses if a business is sued for unsatisfactory work.",
    coverages: [
      "Work mistakes and oversights.",
      "Undelivered services.",
      "Accusations of negligence.",
      "Missed deadlines.",
    ],
    href: "/insurance/professional-liability",
    color: "yellow",
    whiteText: false,
    height: "450px",
  },
  {
    type: "Property Coverage",
    description:
      "This policy covers the value of a business's physical structure and its contents, such as inventory, equipment, and furniture.",
    coverages: [
      "Business owned building.",
      "Furniture and equipment.",
      "Supplies and equipment.",
      "Inventory.",
    ],
    href: "/insurance/property-coverage",
    color: "red",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Liquor liability",
    description:
      "Liquor liability insurance provides coverage for legal fees, settlements, and medical costs if alcohol is sold to an intoxicated person who then harms others or damages property.- Third-party bodily injury",
    coverages: [
      "Third-party property damage",
      "Legal costs",
      "Additional claims, such as assault & battery",
    ],
    href: "insurance/liquor-liability",
    color: "blue",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Workers Compensation",
    description:
      "Workers’ compensation insurance covers medical costs and lost wages for work-related injuries and illnesses. This policy is required in almost every state for businesses that have employees.",
    coverages: [
      "Medical expenses",
      "Disability benefits",
      "Compensation for fatal injuries",
      "Lawsuits related to workplace injuries",
    ],
    href: "insurance/workers-compensation",
    color: "orange",
    whiteText: false,
    height: "450px",
  },
  {
    type: "Inland marine",
    description:
      "Inland marine insurance provides coverage for business property, such as products, tools, and equipment, while it’s in transit over land or stored at an off-site location.",
    coverages: [
      "Property in transit",
      "Unique or valuable property",
      "Property that transfers information",
      "Moveable property in a fixed location",
    ],
    href: "insurance/commerical-inland-marine",
    color: "green",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Trade Credit",
    description:
      "Trade credit insurance protects your small business from financial losses when you can’t collect money from clients or customers. This type of insurance is also called accounts receivable insurance.",
    coverages: [
      "Accounts receivable records damage or destruction in a covered loss",
      "Failed payment by a customer, client, user, etc. due to organizational conflicts",
    ],
    href: "insurance/trade-credit",
    color: "purple",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Umbrella Liability",
    description:
      "Once a policy’s limit is reached, commercial umbrella insurance provides additional coverage for liability claims made on general liability, commercial auto, or employer’s liability insurance.",
    coverages: [
      "Slip-and-fall injuries",
      "Third-party property damage",
      "Car accident liability",
      "Employee injury lawsuits",
    ],
    href: "insurance/umbrella-liability",
    color: "yellow",
    whiteText: false,
    height: "450px",
  },
];

export const personalInsurances: InsuranceCard[] = [
  {
    type: "Home",
    description:
      "Home insurance can help protect you if your home is damaged, belongings are stolen or someone gets injured on your property, it can help cover repairs or replacement, temporary housing, legal fees and more.",
    coverages: [
      "Dwelling coverage.",
      "Personal property coverage.",
      "Personal liability coverage.",
    ],
    href: "/insurance/home",
    color: "blue",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Auto",
    description:
      "Car insurance can help protect you from costs related to auto accidents and other types of losses, such as the theft of your car.",
    coverages: [
      "Liability coverage.",
      "Roadside assistance.",
      "Loan/Lease gap insurance.",
    ],
    href: "/insurance/auto",
    color: "orange",
    whiteText: false,
    height: "450px",
  },
  {
    type: "Umbrella",
    description:
      "Umbrella liability coverage helps protect you from the costs of covered claims when those costs exceed the limits of your home insurance or auto insurance policies.",
    coverages: [
      "Can help protect assets",
      "Claims like libel, slander, defamation of character and invasion of privacy",
      "Legal defense costs like attorney fees and other charges associated with lawsuits",
    ],
    href: "/insurance/umbrella",
    color: "green",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Inland Marine",
    description:
      "Personal inland marine insurance (also called scheduled personal property) can provide coverage for your personal property, whether or not it is transported from one location to another.",
    coverages: ["Jewelry", "Fine art", "Technology", "High value collections"],
    href: "insurance/personal-inland-marine",
    color: "purple",
    whiteText: true,
    height: "450px",
  },
  {
    type: "Watercraft",
    description:
      "Personal watercraft insurance helps insure you and your personal watercraft against accidents, vandalism, and liability.",
    coverages: [
      "Damage to another craft or dock",
      "Bodily injury or death to another person due to your negligence",
      "Physical damage to the hull, machinery, and equipment",
      "Towing assistance if you break down on the water",
    ],
    href: "insurance/watercraft",
    color: "yellow",
    whiteText: false,
    height: "450px",
  },
  {
    type: "Aircraft",
    description:
      "Aircraft insurance can protect you and your aircraft from the financial consequence of aviation accidents, vandalism, weather-related problems, or other damages.",
    coverages: [
      "Physical damage to aircraft",
      "Damage to airports, hangars, and other land based property",
      "Passenger liability",
    ],
    href: "insurance/aircraft",
    color: "red",
    whiteText: true,
    height: "450px",
  },
];

const insurances = {
  commercialInusrances,
  personalInsurances,
};

export default insurances;
