import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import profile from "../assets/icons/profile.svg";
import payments from "../assets/icons/payments.svg";
import report from "../assets/icons/report.svg";
import claims from "../assets/icons/claims.svg";
import certificates from "../assets/icons/certificates.svg";
import documents from "../assets/icons/documents.svg";
import training from "../assets/icons/training.svg";
import communication from "../assets/icons/communication.svg";
import support from "../assets/icons/support.svg";
import paymentsBlue from "../assets/icons/paymentsBlue.svg";
import profileBlue from "../assets/icons/profileBlue.svg";
import reportBlue from "../assets/icons/reportBlue.svg";
import claimsBlue from "../assets/icons/claimsBlue.svg";
import certificatesBlue from "../assets/icons/certificatesBlue.svg";
import documentsBlue from "../assets/icons/documentsBlue.svg";
import trainingBlue from "../assets/icons/trainingBlue.svg";
import communicationBlue from "../assets/icons/communicationBlue.svg";
import supportBlue from "../assets/icons/supportBlue.svg";
import { useHover } from "src/hooks/useHover";

const classes = {
  sidebar: {
    width: { xs: "100%", sm: "300px" },
    flexDirection: "column",
    pt: "10px",
    pl: { sm: "20px" },
  },
  sidebarButton: {
    padding: "0",
    borderRadius: "20px",
    marginRight: "30px",
    paddingLeft: "20px",
    marginBottom: { xs: "8px", sm: 0 },
    height: { xs: "55px", sm: "42px" },
    "&:hover": {
      borderRadius: "20px",
      marginRight: "30px",
      paddingLeft: "20px",
    },
  },
  sidebarText: {
    color: "black",
    "&:hover": {
      color: "#109CF1",
    },
  },
  icon: {
    mr: "5px",
    mt: "2px",
    "&:hover": {
      backgroundcolor: "#109CF1",
    },
  },
};
interface Props {
  openDashboardHamburgerMenu?: boolean;
  setIsClickSwitchAccount?: any;
}
const DashboardSidebar = ({
  openDashboardHamburgerMenu,
  setIsClickSwitchAccount,
}: Props) => {
  const [hoverRefProfile, isHoveredProfile] = useHover<HTMLDivElement>();
  const [hoverRefPayments, isHoveredPayment] = useHover<HTMLDivElement>();
  const [hoverRefReports, isHoveredReports] = useHover<HTMLDivElement>();
  const [hoverRefClaims, isHoveredClaims] = useHover<HTMLDivElement>();
  const [hoverRefCertificates, isHoveredCertificates] =
    useHover<HTMLDivElement>();
  const [hoverRefDocuments, isHoveredDocuments] = useHover<HTMLDivElement>();
  const [hoverRefTraining, isHoveredTraining] = useHover<HTMLDivElement>();
  const [hoverRefCommunication, isHoveredCommunication] =
    useHover<HTMLDivElement>();
  const [hoverRefSupport, isHoveredSupport] = useHover<HTMLDivElement>();

  return (
    <Box
      sx={{
        ...classes.sidebar,
        display: {
          xs: openDashboardHamburgerMenu ? "flex" : "none",
          sm: "flex",
        },
        flexDirection: "column",
      }}
    >
      <List>
        <Box ref={hoverRefProfile}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton} href={"/dashboard"}>
              <Box
                component="img"
                alt={"Profile"}
                src={isHoveredProfile ? profileBlue : profile}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Profile"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefPayments}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Payments"}
                src={isHoveredPayment ? paymentsBlue : payments}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Payments"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefReports}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Report"}
                src={isHoveredReports ? reportBlue : report}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Reports"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefClaims}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Claims"}
                src={isHoveredClaims ? claimsBlue : claims}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Claims"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefCertificates}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Certificates"}
                src={isHoveredCertificates ? certificatesBlue : certificates}
                sx={{ ...classes.icon, width: { xs: "16px", sm: "16px" } }}
              />
              <ListItemText
                primary="Certificates"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefDocuments}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Documents"}
                src={isHoveredDocuments ? documentsBlue : documents}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Documents"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefTraining}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Training"}
                src={isHoveredTraining ? trainingBlue : training}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Training"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefCommunication}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Communication"}
                src={isHoveredCommunication ? communicationBlue : communication}
                sx={{ ...classes.icon, width: { xs: "18px", sm: "16px" } }}
              />
              <ListItemText
                primary="Communication"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
        <Box ref={hoverRefSupport}>
          <ListItem disablePadding>
            <ListItemButton sx={classes.sidebarButton}>
              <Box
                component="img"
                alt={"Support"}
                src={isHoveredSupport ? supportBlue : support}
                sx={{ ...classes.icon, width: { xs: "20px", sm: "16px" } }}
              />
              <ListItemText
                primary="Support"
                primaryTypographyProps={{
                  fontSize: { xs: "20px", sm: "16px" },
                }}
                sx={classes.sidebarText}
              />
            </ListItemButton>
          </ListItem>
        </Box>
      </List>
      <Box
        sx={{
          display: "flex",
          marginTop: "350px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: "20px",
            textTransform: "none",
            width: "90%",
          }}
          onClick={() => {
            setIsClickSwitchAccount(true);
          }}
        >
          Switch to Company Account
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
