import {
  AppBar,
  Container,
  Box,
  Grid,
  Typography,
  Divider,
  Link,
} from "@mui/material";

import logo from "../assets/icons/klout-black-logo.svg";
import instagram from "../assets/icons/instagram.svg";
import facebook from "../assets/icons/facebook.svg";
import tiktok from "../assets/icons/tiktok.svg";
import linkedIn from "../assets/icons/linkedin.svg";

const classes = {
  fontTitle: {
    color: "black",
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: { xs: "20px", sm: "20px", md: 0 },
  },
  fontList: {
    color: "black",
    fontSize: "16px",
  },
  fontCopy: {
    color: "black",
    fontSize: "16px",
    lineHeight: "20px",
  },
  feedback: {
    color: "black",
    fontWeight: "bold",
  },
  button: {
    padding: 0,
  },
};
const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }} id="footer">
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          padding: "1em 0.5em",
          boxShadow: "none",
          borderTop: "2px solid black",
          position: "relative",
          zIndex: 1,
        }}
      >
        <Container>
          <Grid container sx={{ paddingBottom: "25px" }}>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  flexDirection: "column",
                  marginBottom: { xs: "20px", sm: "20px", md: 0 },
                  marginRight: "70px",
                }}
              >
                <img
                  src={logo}
                  alt="Klout logo"
                  style={{ height: "35px", marginBottom: "20px" }}
                />
                <Typography variant="h6" sx={classes.fontCopy}>
                  Klout is a company with a conscience, a place where
                  relationships are paramount & more important than
                  transactional velocity.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography component="h1" sx={classes.fontTitle} gutterBottom>
                WEB-APP
              </Typography>
              <Box
                sx={{
                  marginRight: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Link href="/" underline="none">
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    HOME
                  </Typography>
                </Link>

                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  HOW IT WORKS
                </Typography>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  GET QUOTES
                </Typography>
                <Link href="/faq" underline="none">
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    FAQ
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography component="h1" sx={classes.fontTitle} gutterBottom>
                SERVICES
              </Typography>
              <Box
                sx={{
                  marginRight: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Link href="/insurance" underline="none" sx={classes.button}>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    INSURANCE
                  </Typography>
                </Link>
                <Link href="/advisory" underline="none" sx={classes.button}>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    ADVISORY
                  </Typography>
                </Link>
                <Link href="/industries" underline="none" sx={classes.button}>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    INDUSTRIES
                  </Typography>
                </Link>
                <Link href="/support" underline="none" sx={classes.button}>
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    SUPPORT
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography component="h1" sx={classes.fontTitle} gutterBottom>
                COMPANY
              </Typography>
              <Box
                sx={{
                  marginRight: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  ABOUT US
                </Typography>
                <Link href="/careers" underline="none">
                  <Typography
                    variant="h6"
                    component="h1"
                    sx={classes.fontList}
                    gutterBottom
                  >
                    CAREERS
                  </Typography>
                </Link>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  PRESS
                </Typography>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  INSURANCE BLOG
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="h6"
                component="h1"
                sx={classes.fontTitle}
                gutterBottom
              >
                CONTACT US
              </Typography>
              <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={classes.fontList}
                  gutterBottom
                >
                  <Link
                    href="mailto:support@kloutgroup.com"
                    color="inherit"
                    underline="none"
                    sx={classes.feedback}
                  >
                    Questions,
                  </Link>
                  <Link
                    href="mailto:support@kloutgroup.com"
                    color="inherit"
                    underline="none"
                    sx={classes.feedback}
                  >
                    &nbsp;comments,
                  </Link>
                  <Link
                    href="mailto:support@kloutgroup.com"
                    color="inherit"
                    underline="none"
                    sx={classes.feedback}
                  >
                    &nbsp;feedback?
                  </Link>
                </Typography>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{ ...classes.fontList, lineHeight: "10px" }}
                  gutterBottom
                >
                  We would love to hear from you!
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box>
              <Typography sx={{ color: "black", marginTop: "15px" }}>
                Klout Group Inc. © 2022. All rights reserved.
              </Typography>
            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <img // Add aria-label when redirect link is ready
                src={instagram}
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "2px",
                }}
              />
              <img
                src={facebook}
                style={{
                  width: "30px",
                  height: "25px",
                  marginLeft: "10px",
                  marginBottom: "3px",
                }}
              />
              <img // Add aria-label when redirect link is ready
                src={tiktok}
                style={{
                  width: "30px",
                  height: "27px",
                  marginLeft: "10px",
                  marginBottom: "2px",
                }}
              />
              <Link
                aria-label="Linkedin page redirect"
                href="https://www.linkedin.com/company/kloutgroup"
              >
                <img
                  src={linkedIn}
                  style={{
                    width: "30px",
                    height: "30px",
                    marginLeft: "10px",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Footer;
