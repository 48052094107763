import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import MicrosoftLogin from "react-microsoft-login";
import axiosConfig from "src/lib/axios";
import jwt_decode from "jwt-decode";
import { SignButton } from "./SignButton";
import microsoft from "../../../assets/img/microsoft.svg";
import google from "../../../assets/img/google.svg";
import apple from "../../../assets/img/apple.svg";

interface Props {
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}
interface SignIcons {
  [key: string]: string;
}
const SignIcons: SignIcons = {
  google: google,
  apple: apple,
  microsoft: microsoft,
};
const AuthProvider = (props: Props): JSX.Element => {
  const [googleUser, setGoogleUser] = useState<any>({});
  const [microsoftUser, setMicrosoftUser] = useState<any>({});
  const navigate = useNavigate();
  const signUpUrl = "/api/v1/oauth2/oauth2_sign_up";

  type CreateUserResponse = {
    email: string;
    first_name: string;
    last_name: string;
  };

  const postGoogleUsers = () => {
    axiosConfig
      .post<CreateUserResponse>(signUpUrl, googleUser, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        props.setIsLoggedIn(true);
        navigate("/dashboard");
      });
  };

  const postMicrosoftUsers = () => {
    axiosConfig
      .post<CreateUserResponse>(signUpUrl, microsoftUser, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        props.setIsLoggedIn(true);
        navigate("/dashboard");
      });
  };

  const handleCallbackResponse = (response: any) => {
    localStorage.setItem("accountToken", response.credential);
    var userObj: any = jwt_decode(response.credential);
    setGoogleUser({
      email: userObj.email,
      first_name: userObj.given_name,
      last_name: userObj.family_name,
    });
  };

  const authHandler = (err: any, data: any) => {
    localStorage.setItem("accountToken", data.idToken);
    var userObj: any = jwt_decode(data.idToken);
    setMicrosoftUser({
      email: userObj.preferred_username,
      first_name: userObj.name.split(" ")[0],
      last_name: userObj.name.split(" ")[1],
    });
  };

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id:
        "220192803866-13ib6ji0ld357r5fbevo9tt3irojjutv.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });

    postGoogleUsers();
    postMicrosoftUsers();
  });
  const handleLoginButtonClick = () => {
    // Trigger the Google sign-in flow
    window.google.accounts.id.prompt();
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "10px",
        width: "100%",
        justifyContent: "center",
        gap: "24px"
      }}
    >
      <SignButton
        icon={google}
        title="GOOGLE"
        handleLoginButtonClick={handleLoginButtonClick}
      />
      <SignButton icon={apple} title="APPLE" />
      <MicrosoftLogin
        clientId="bf45baa1-cf10-4bb1-9906-6d0369984f33"
        authCallback={authHandler}
      >
        <SignButton icon={microsoft} title="MICROSOFT" />
      </MicrosoftLogin>
    </Box>
  );
};

export default AuthProvider;
