import React, { useState } from "react";
import { AxiosError } from "axios";
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axiosConfig from "src/lib/axios";
import ValidationEmailDialog from "./ValidationEmailDialog";
import StyledTextField from "../styled/StyledTextField";
import InsuranceButton from "../styled/InsuranceButton";

const style = {
  typography: {
    fontWeight: "regular", 
    fontSize: "18px", 
    marginBottom: "5px",
    color: "#888",
    marginTop: "15px"
  }
}

interface Message {
  show: boolean;
  text: string;
  severity: any; // error, warning, info, success
}

interface Register {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}

interface Props {
  nextStep(): void;
}

const Registration = ({ nextStep }: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [isOpenValidationEmailDialog, setIsOpenValidationEmailDialog] =
    useState(false);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
  });
  const [validated, setValidated] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
  });
  const [touched, setTouched] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
  });
  const [message, setMessage] = useState<Message>({
    show: false,
    text: "",
    severity: null,
  });

  const firstNameRegex: RegExp = /^[a-zA-Z]{2,15}$/;
  const lastnameRegex: RegExp = /^[a-zA-Z]{2,30}$/;

  const emailRegex: RegExp =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex: RegExp =
    /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*(.)\1\1)(?!.*\d{5})(?!.*(password|admin|user|123456|qwerty|letmein|monkey|dragon|baseball|football)).{8,}$/;

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
  };

  const handleInvalid = (): boolean => {
    if (
      !validated.email ||
      !validated.password ||
      !validated.first_name ||
      !validated.last_name
    ) {
      setMessage({
        show: true,
        text: "Fill in the fields",
        severity: "warning",
      });
    }
    return (
      !validated.email ||
      !validated.password ||
      !validated.first_name ||
      !validated.last_name
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setMessage({ ...message, show: false });
    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });

    setTouched({
      ...touched,
      [e.target.id]: e.target.value.length === 0 ? false : true,
    });

    switch (e.target.id) {
      case "first_name":
        return setValidated({
          ...validated,
          [e.target.id]: firstNameRegex.test(e.target.value),
        });
      case "last_name":
        return setValidated({
          ...validated,
          [e.target.id]: lastnameRegex.test(e.target.value),
        });
      case "email":
        return setValidated({
          ...validated,
          [e.target.id]: emailRegex.test(e.target.value),
        });
      case "password":
        return setValidated({
          ...validated,
          [e.target.id]: passwordRegex.test(e.target.value),
        });
    }
  };

  const handleRegister = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    if (handleInvalid()) return;

    const payload: Register = {
      email: user.email,
      password: user.password,
      first_name: user.first_name,
      last_name: user.last_name,
    };
    axiosConfig
      .post("/api/v1/user/register", payload)
      .then(() => {
        setIsOpenValidationEmailDialog(true); // Proveriti ovo gde treba da stoji
        setTimeout(() => {
          setMessage({
            show: false,
            text: "",
            severity: null,
          });
          setValidated({
            first_name: false,
            last_name: false,
            email: false,
            password: false,
          });
          setTouched({
            first_name: false,
            last_name: false,
            email: false,
            password: false,
          });
          nextStep();
        }, 30000);
      })
      .catch((reason: AxiosError) => {
        if (reason.response!.status === 400) {
          setMessage({
            show: true,
            text: "This user already exists, please log in",
            severity: "error",
          });
        } else if (reason.response!.status === 406) {
          setMessage({
            show: true,
            text: "ou have an unverified email. Please, check your inbox.",
            severity: "error",
          });
        } else {
          setMessage({
            show: true,
            text: "There was an error, please try again",
            severity: "error",
          });
        }
      });
  };

  const test = () => {
    setIsOpenValidationEmailDialog(true);
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "0.8em",
        }}
      >
      <Typography sx={{
        fontWeight: "regular", 
        fontSize: "18px", 
        marginBottom: "5px",
        color: "#888",
      }}>
         First Name*
       </Typography>
       <StyledTextField
          InputLabelProps={{
            shrink: false
          }}
          InputProps={{
            style: {
              borderRadius: "5px",
            }
          }}
          required
          fullWidth
          placeholder={'First Name*'}
          variant="outlined"
          id="first_name"
          onChange={handleChange}
          error={!validated.first_name && touched.first_name}
          helperText={
            !validated.first_name && touched.first_name
              ? "Please enter a valid first name"
              : ""
          }
        />
        <Typography sx={style.typography}>
         Last Name*
        </Typography>
        <StyledTextField
          InputLabelProps={{
            shrink: false,
          }}
          placeholder={'Last Name*'}
          InputProps={{
            style: {
              borderRadius: "5px",
            }
          }}
          required
          fullWidth
          variant="outlined"
          id="last_name"
          onChange={handleChange}
          error={!validated.last_name && touched.last_name}
          helperText={
            !validated.last_name && touched.last_name
              ? "Please enter a valid last name"
              : ""
          }
        />
        <Typography sx={style.typography}>
         Email*
        </Typography>
        <StyledTextField
          InputLabelProps={{
            shrink: false,
          }}
          placeholder={'Email*'}
          InputProps={{
            style: {
              borderRadius: "5px",
            }
          }}
          required
          fullWidth
          variant="outlined"
          id="email"
          inputProps={{
            autoComplete: "new-password",
          }}
          onChange={handleChange}
          error={!validated.email && touched.email}
          helperText={
            !validated.email && touched.email
              ? "Please enter a valid email adress"
              : ""
          }
        />
        <Typography sx={style.typography}>
         Password*
        </Typography>
        <StyledTextField
          InputLabelProps={{
            shrink: false
          }}
          placeholder={'Password*'}
          required
          type={showPassword ? "text" : "password"}
          // style= {"-webkit-text-security: square"}
          fullWidth
          variant="outlined"
          inputProps={{
            autoComplete: "new-password",
          }}
          id="password"
          onChange={handleChange}
          error={!validated.password && touched.password}
          helperText={
            !validated.password && touched.password
              ? "Password must contain at least 8 characters and 1 special character"
              : ""
          }
          InputProps={{
            style: {
              borderRadius: "5px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <InsuranceButton
          type="submit"
          fullWidth
          variant="contained"
          name="register"
          data-testid="register-button"
          onClick={handleRegister}
          sx={{ width: "400px" , borderRadius: "20px", height: "50px", marginLeft: "32px", marginTop: "15px"}}
        >
          <Typography>Sign In </Typography>
        </InsuranceButton>
      </Box>

      {message.show ? (
        <Alert
          variant="outlined"
          sx={{ margin: "0.8em 0", width: "80%" }}
          severity={message.severity}
        >
          {message.text}
        </Alert>
      ) : (
        <></>
      )}
      <Box>
        {isOpenValidationEmailDialog && (
          <ValidationEmailDialog
            isOpen={isOpenValidationEmailDialog}
            // isLoginBtnCliked={isLoginBtnCliked}
            handleClose={() => setIsOpenValidationEmailDialog(false)}
            email={user.email}
            name={user.first_name} // nextStep={() => {}}
            // setIsLoggedIn={setIsLoggedIn}
          />
        )}
      </Box>
    </>
  );
};
export default Registration;
