import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { Collapse, Link, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import classes from "../styles/styles";

interface Props {
  openInsurance: boolean;
  setOpenInsurance: (value: boolean) => void;
  setOpenAdvisory: (value: boolean) => void;
  setOpenIndustries: (value: boolean) => void;
}
export const InsuranceMenuMobile = ({
  openInsurance,
  setOpenInsurance,
  setOpenAdvisory,
  setOpenIndustries,
}: Props) => {
  return (
    <List>
      <ListItem
        onClick={() => {
          setOpenInsurance(!openInsurance);
          setOpenAdvisory(false);
          setOpenIndustries(false);
        }}
        sx={{ paddingTop: "20px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: openInsurance ? "#69B3C4" : "black",
              fontSize: "22px",
            }}
          >
            Insurance
          </Typography>

          {openInsurance ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItem>

      <Collapse in={openInsurance} timeout="auto" unmountOnExit>
        <List>
          <ListItem disablePadding>
            <Link sx={classes.linkStyle} href={"/insurance"}>
              <Typography sx={classes.listTitle}>COMMERCIAL</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/general-liability"}>
              <Typography sx={classes.listText}>General Liability</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/property-coverage"}>
              <Typography sx={classes.listText}>Property Coverage</Typography>
            </Link>
          </ListItem>

          <ListItem sx={classes.listItem}>
            <Link
              sx={classes.linkStyle}
              href={"/insurance/workers-compensation"}
            >
              <Typography sx={classes.listText}>
                Workers Compensation
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              sx={classes.linkStyle}
              href={"/insurance/commerical-inland-marine"}
            >
              <Typography sx={classes.listText}>Inland Marine</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/cyber-liability"}>
              <Typography sx={classes.listText}>Cyber Liability</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/umbrella-liability"}>
              <Typography sx={classes.listText}>Umbrella Liability</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/business-auto"}>
              <Typography sx={classes.listText}>Business Auto</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              sx={classes.linkStyle}
              href={"/insurance/professional-liability"}
            >
              <Typography sx={classes.listText}>
                Professional Liability
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/event-liability"}>
              <Typography sx={classes.listText}>Event Liability</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/event-cancellation"}>
              <Typography sx={classes.listText}>Event Cancellation</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/liquor-liability"}>
              <Typography sx={classes.listText}>Liquor Liability</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link sx={classes.linkStyle} href={"/insurance/trade-credit"}>
              <Typography sx={classes.listText}>Trade Credit</Typography>
            </Link>
          </ListItem>
        </List>
        <Divider variant="middle" />
        <ListItem disablePadding>
          <Link sx={classes.linkStyle} href={"/insurance"}>
            <Typography sx={classes.listTitle}>PERSONAL</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} href={"/insurance/auto"}>
            <Typography sx={classes.listText}>Auto</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} href={"/insurance/home"}>
            <Typography sx={classes.listText}>Home</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link
            sx={classes.linkStyle}
            href={"/insurance/personal-inland-marine"}
          >
            <Typography sx={classes.listText}>Inland Marine</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} href={"/insurance/watercraft"}>
            <Typography sx={classes.listText}>Watercraft</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} href={"/insurance/aircraft"}>
            <Typography sx={classes.listText}>Aircraft</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} href={"/insurance/umbrella"}>
            <Typography sx={classes.listText}>Umbrella</Typography>
          </Link>
        </ListItem>
      </Collapse>
    </List>
  );
};
