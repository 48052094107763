import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
export interface FormState {
  title: string[],
  page: number;
  generalFormData: Record<string, unknown>;
  formData: Record<string, unknown>;
  dataToSend: Record<string, unknown>;
  insuranceCommercialSelected: string[];
}

const initialState: FormState = {
  title: [],
  page: 0,
  generalFormData:{},
  formData: {},
  dataToSend: {},
  insuranceCommercialSelected: [],
};
const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    pageSet: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    formData: (state, action) => {
      state.formData = action.payload;
    },
    generalFormData: (state, action) => {
      state.generalFormData = action.payload;
    },
    formDataToSend: (state, action) => {
      state.dataToSend = action.payload;
    },
    insuranceCommercialSelected: (state, action) => {
      state.insuranceCommercialSelected = action.payload;
    },
    title: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const {
  title,
  formData,
  generalFormData,
  formDataToSend,
  pageSet,
  insuranceCommercialSelected,
} = formSlice.actions;


export default formSlice.reducer as Reducer<FormState>;
