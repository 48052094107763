import { Box, Grid, Typography, Link } from "@mui/material";
import { Container } from "@mui/system";

import email from "../assets/icons/email.svg";
import general_question from "../assets/icons/general_question.svg";
import billing_question from "../assets/icons/billing_question.svg";

const classes = {
  container: {
    mt: "67px", // height of Navbar
    display: "flex",
    flexDirection: "column",
    mb: "50px",
  },
  pageTitle: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
    },
    padding: {
      xs: "30px 20px 0 20px",
      sm: "20px 0 0 0",
    },
    fontWeight: "bold",
    textAlign: "center",
  },
  subtitle: {
    fontSize: {
      xs: "16px",
      sm: "18px",
      md: "20px",
    },
    padding: "10px",
    letterSpacing: "1.1px",
    textAlign: "center",
  },
  supportCards: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: "30px",
    marginRight: "20px",
    width: { xs: "100%", sm: "50%" },
  },
  icon: {
    width: { xs: "110px", md: "150px" },
  },
  cardTitle: {
    fontSize: { xs: "16px", sm: "18px", md: "22px" },
    margin: "16px",
  },
  cardContent: {
    fontSize: { xs: "16px", md: "18px" },
    margin: "10px",
  },
  cardMore: {
    fontSize: { xs: "16px", md: "18px" },
    marginBottom: "20px",
  },
  email: {
    color: "black",
    borderBotoom: "5px solid green",
    backround: "red",
  },
  position: {
    width: { sm: "40%", md: "33%" },
    margin: "30px 0",
    border: "2px solid black",
    textAlign: "center",
    padding: "15px",
  },
  positionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "10px",
  },
  positionDate: {
    fontSize: "16px",
    margin: "10px",
  },
  positionDescription: {
    fontSize: "18px",
  },
};
const Support = (): JSX.Element => {
  return (
    <Container>
      <Box sx={classes.container}>
        <Typography sx={classes.pageTitle}>CAREERS</Typography>
        <Typography sx={classes.subtitle}>Join out team!</Typography>
        <Typography
          sx={{
            ...classes.pageTitle,
            fontWeight: "normal",
            fontSize: { xs: "18px", sm: "25px" },
          }}
        >
          CURRENT OPENINGS
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={classes.position}>
            <Link
              href="https://www.linkedin.com/jobs/search/?currentJobId=3562294211&f_C=16190133&geoId=92000000&originToLandingJobPostings=3562294211"
              underline="none"
              color="inherit"
              target="_blank"
              rel="noopener"
            >
              <Typography sx={classes.positionTitle}>
                ASSOCIATE ACCOUNT MANAGER
              </Typography>
              <Typography sx={classes.positionDate}>
                Posted April 20th, 2023
              </Typography>
              <Typography sx={classes.positionDescription}>
                The Associate Account Manager will manage an existing portfolio
                of clients, as well as handle newly established client accounts.
              </Typography>
              <Typography sx={{ marginTop: "15px", fontSize: "18px" }}>
                This role is responsible for managing all aspects and stages of
                an insured client's file including initial quoting, coordination
                of underwriting and policy binding, all the way of potential
                claims handling.
              </Typography>
            </Link>
          </Box>
          <Box sx={classes.position}>
            <Typography sx={classes.positionTitle}>
              EXECUTIVE ASSISTANT
            </Typography>
            <Typography sx={classes.positionDate}>
              Posted April 24th, 2023
            </Typography>
            <Typography sx={classes.positionDescription}>
              We are seeking a highly skilled and efficient Executive Assistant
              to provide high-level support to our Executive Leadership team.
            </Typography>
            <Typography sx={{ marginTop: "15px", fontSize: "18px" }}>
              As the right-hand employee to our Executive Leaders, the Executive
              Assistant will play a vital role in ensuring the smooth
              functioning of our organization.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Support;
