import * as React from "react";
import {
  Box,
  Typography,
  Link,
  Drawer,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import kloutLogo from "../../assets/icons/klout-black-logo.svg";
import { InsuranceMenuMobile } from "./components/InsuranceMenuMobile";
import { AdvisoryMenuMobile } from "./components/AdvisoryMenuMobile";
import { IndustriesMenuMobile } from "./components/IndustriesMenuMobile";
import AccountMenu from "../authentication/AccountMenu";
import MobileLoginButton from "../styled/MobileLoginButton";

interface Props {
  setOpenHamburgerMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenLoginDialog: () => void;
  handleOpenRegisterDialog: () => void;
  isLoggedIn: boolean;
  openHamburgerMenu: boolean;
}
export const HamburgerMenu = ({
  openHamburgerMenu,
  setOpenHamburgerMenu,
  handleOpenLoginDialog,
  handleOpenRegisterDialog,
  isLoggedIn,
}: Props) => {
  const [openInsurance, setOpenInsurance] = React.useState(false);
  const [openAdvisory, setOpenAdvisory] = React.useState(false);
  const [openIndustries, setOpenIndustries] = React.useState(false);

  const toggleDrawer = () => {
    setOpenHamburgerMenu(false);
  };

  const list = () => (
    <Box>
      <Box>
        <Link href="/" underline="none">
          <img
            src={kloutLogo}
            alt="klout-logo"
            style={{
              paddingTop: "8.5px",
              marginLeft: "24px",
              height: "50px",
              width: "120px",
            }}
          />
        </Link>
      </Box>

      <Box>
        <InsuranceMenuMobile
          openInsurance={openInsurance}
          setOpenInsurance={setOpenInsurance}
          setOpenAdvisory={setOpenAdvisory}
          setOpenIndustries={setOpenIndustries}
        />
        <Divider variant="middle" />
        <AdvisoryMenuMobile
          openAdvisory={openAdvisory}
          setOpenAdvisory={setOpenAdvisory}
          setOpenInsurance={setOpenInsurance}
          setOpenIndustries={setOpenIndustries}
          setOpenHamburgerMenu={setOpenHamburgerMenu}
        />
        <Divider variant="middle" />
        <IndustriesMenuMobile
          openIndustries={openIndustries}
          setOpenIndustries={setOpenIndustries}
          setOpenAdvisory={setOpenAdvisory}
          setOpenInsurance={setOpenInsurance}
          setOpenHamburgerMenu={setOpenHamburgerMenu}
        />
        <Divider variant="middle" />
        <List>
          <ListItem>
            <Link href="/faq" underline="none" color="inherit">
              <Typography sx={{ padding: "5px 0px", fontSize: "22px" }}>
                FAQ
              </Typography>
            </Link>
          </ListItem>
          <Divider variant="middle" />

          <ListItem>
            <Link href="/support" underline="none" color="inherit">
              <Typography sx={{ padding: "5px 0px", fontSize: "22px" }}>
                Support
              </Typography>
            </Link>
          </ListItem>
          <Divider variant="middle" />
        </List>
      </Box>
      {!isLoggedIn ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            padding: "10px",
          }}
        >
          <MobileLoginButton onClick={handleOpenRegisterDialog}>
            Sign Up
          </MobileLoginButton>
          <MobileLoginButton onClick={handleOpenLoginDialog}>
            Log In
          </MobileLoginButton>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        open={openHamburgerMenu}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100vw", sm: "45vw", md: 0 },
          },
        }}
      >
        {isLoggedIn ? (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              right: 4,
              paddingTop: "15px",
            }}
          >
            <AccountMenu
              isHovered={true}
              openHamburgerMenu={openHamburgerMenu}
            />
          </Box>
        ) : (
          ""
        )}
        {list()}
      </Drawer>
    </div>
  );
};
