import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { Collapse, Link, Typography } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import classes from "../styles/styles";
import { useNavigate } from "react-router";

interface Props {
  openAdvisory: boolean;
  setOpenAdvisory: (value: boolean) => void;
  setOpenInsurance: (value: boolean) => void;
  setOpenIndustries: (value: boolean) => void;
  setOpenHamburgerMenu: (value: boolean) => void;
}
export const AdvisoryMenuMobile = ({
  openAdvisory,
  setOpenAdvisory,
  setOpenInsurance,
  setOpenIndustries,
  setOpenHamburgerMenu,
}: Props) => {
  const navigate = useNavigate();

  const handleClick = (id: number) => {
    setOpenHamburgerMenu(false);
    navigate("/advisory", { state: { scrollTo: id } });
  };
  return (
    <List>
      <ListItem
        onClick={() => {
          setOpenAdvisory(!openAdvisory);
          setOpenInsurance(false);
          setOpenIndustries(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{ color: openAdvisory ? "#69B3C4" : "black", fontSize: "22px" }}
          >
            Advisory
          </Typography>

          {openAdvisory ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItem>
      <Collapse in={openAdvisory} timeout="auto" unmountOnExit>
        <ListItem disablePadding>
          <Link sx={classes.linkStyle} onClick={() => handleClick(0)}>
            <Typography sx={classes.listTitle}>ACCOUNTING</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(1)}>
            <Typography sx={classes.listText}>Bookkeeping</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(2)}>
            <Typography sx={classes.listText}>CPA</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(3)}>
            <Typography sx={classes.listText}>Tax Advisory</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(4)}>
            <Typography sx={classes.listText}>Tax Filing</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(5)}>
            <Typography sx={classes.listText}>CFO services</Typography>
          </Link>
        </ListItem>
        <Divider variant="middle" />
        <ListItem disablePadding>
          <Link sx={classes.linkStyle} onClick={() => handleClick(6)}>
            <Typography sx={classes.listTitle}>BUSINESS MANAGEMENT</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(7)}>
            <Typography sx={classes.listText}>Implementation</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(8)}>
            <Typography sx={classes.listText}>Operations</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(9)}>
            <Typography sx={classes.listText}>Corporate Governance</Typography>
          </Link>
        </ListItem>
        <ListItem sx={classes.listItem}>
          <Link sx={classes.linkStyle} onClick={() => handleClick(10)}>
            <Typography sx={classes.listText}>Strategy Consulting</Typography>
          </Link>
        </ListItem>
      </Collapse>
    </List>
  );
};
