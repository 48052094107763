import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const axiosConfig = axios.create({
  baseURL: "https://klout-api.qcerris.net",
  // baseURL: "http://127.0.0.1:8000",
  timeout: 10000,
});

axiosConfig.defaults.headers.common["Content-Type"] = "application/json";

axiosConfig.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  (error: unknown) => {
    return Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: unknown) => {
    return Promise.reject(error);
  }
);

export default axiosConfig;
