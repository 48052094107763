import manufacturers from "../../assets/icons/manufacturers.svg"
import eCommerce from "../../assets/icons/e-commerce.svg"
import entertainment from "../../assets/icons/entertainment.svg"
import highNetWorthIndividuals from "../../assets/icons/high_net_worth_individual.svg"
import hospitality from "../../assets/icons/hospitality.svg"
import professionalServices from "../../assets/icons/professional_services.svg"
import realEstate from "../../assets/icons/real_estate.svg"
import technology from "../../assets/icons/technology.svg"

export interface IPageContent {
    title: string
    subtitle: string
    stepper: IStep[]
    main: ISection[]
}

export interface IStep {
    id: number
    label: string
    icon?: string
    main?: boolean
}

export interface ISection {
    stepId: number // this connects the section with a step
    title: string
    icon?: any
    subsections: ISubsection[]
}

export interface ISubsection {
    stepId?: number // this connects the subsection with a step
    title: string
    paragraphs: IParagraph[]
    id?: number
}

export interface IParagraph {
    content: string
}

const advisoryPage: IPageContent = {
    title: "Advisory Services",
    subtitle: 'Insurance to protect you & Advisory to help you grow. Your life long partners for handling complex problems with simple solutions & creative strategy.',
    stepper: [
        {
            label: "Accounting",
            id: 0,
            main: true,
        },
        {
            label: "Bookkeeping",
            id: 1,
            main: false,
        },
        {
            label: "CPA",
            id: 2,
            main: false,
        },
        {
            label: "Tax Advisory",
            id: 3,
            main: false,
        },
        {
            label: "Tax Filing",
            id: 4,
            main: false,
        },
        {
            label: "CFO services",
            id: 5,
            main: false,
        },
        {
            label: "Business Management",
            id: 6,
            main: true,
        },
        {
            label: "Implementation",
            id: 7,
            main: false,
        },
        {
            label: "Operations",
            id: 8,
            main: false,
        },
        {
            label: "Corporate Governance",
            id: 9,
            main: false,
        },
        {
            label: "Strategy Consulting",
            id: 10,
            main: false,
        },
    ],
    main: [
        {
            title: "Accounting",
            stepId: 0,
            subsections: [
                {
                    title: "Bookkeeping",
                    stepId: 1,
                    paragraphs: [
                        {
                            content: "Bookkeeping is the foundation of every successful business, and it is vital to keep track of financial transactions accurately. We offer bookkeeping services to help you manage your finances with ease. Our team of experts will ensure that your books are up to date and that you have access to accurate financial statements, giving you insight into your business's financial health. Our bookkeeping services include accounts payable and receivable, bank and credit card reconciliations, and financial reporting. With our bookkeeping services, you can focus on growing your business while we take care of the rest.",

                        }
                    ]
                },
                {
                    title: "CPA",
                    stepId: 2,
                    paragraphs: [
                        {
                            content: "At Klout, we understand the importance of having access to experienced and knowledgeable CPAs when you need them most. That's why we offer CPA availability as a service to our clients. With this service, you will have access to our team of CPAs who can provide guidance on complex tax and accounting issues, answer your questions, and help you make informed financial decisions. Whether you need assistance with tax planning, financial reporting, or any other accounting matter, our CPAs are here to help. Contact us today to learn more about how our CPA availability service can benefit your business.",
                        }
                    ],
                },
                {
                    title: "Tax Advisory",
                    stepId: 3,
                    paragraphs: [
                        {
                            content: "Effective tax planning and compliance are crucial to the success of any business. Our team of experienced tax advisors works closely with clients to identify opportunities to minimize tax liabilities and ensure compliance with applicable tax laws and regulations. We provide comprehensive tax planning and compliance services that are tailored to the unique needs of each client, including tax return preparation, tax optimization, tax dispute resolution, and more. Our goal is to help our clients achieve their financial objectives while minimizing their tax burden.",
                        }
                    ],
                },
                {
                    title: "Tax Filing",
                    stepId: 4,
                    paragraphs: [
                        {
                            content: "Let our experienced tax professionals take the stress out of tax season by handling your tax filings for you. Our team stays up to date on the latest tax laws and regulations to ensure accurate and timely filings. We offer personalized tax filing services for individuals, small businesses, and corporations, tailored to your unique needs and circumstances. With our help, you can be confident that your taxes are filed correctly and on time, while minimizing your tax liability and maximizing your tax savings.",
                        }
                    ],
                },
                {
                    title: "CFO services",
                    stepId: 5,
                    paragraphs: [
                        {
                            content: "Our CFO services provide businesses with access to experienced financial experts to help manage their finances and make informed decisions. We work with clients on a part-time or project basis, providing customized solutions to meet their unique needs. Our team of experienced CFOs can assist with financial planning and analysis, cash flow management, budgeting, financial reporting, and more. By partnering with us, you'll have a strategic financial partner to help guide your decisions, reduce risk, and improve your bottom line.",
                        }
                    ],
                },
            ],
        },
        {
            title: "Business Management",
            stepId: 6,
            subsections: [
                {
                    title: "Implementation",
                    stepId: 7,
                    paragraphs: [
                        {
                            content: "Our implementation service is designed to help businesses efficiently implement the necessary changes and improvements identified through our consulting services. Our team of experienced professionals will work with your organization to create a comprehensive implementation plan that is tailored to your specific needs and goals.",
                        },
                        {
                            content: "We understand that every business is unique and requires a customized approach to implementation. That's why we work closely with our clients to ensure that the implementation plan is practical, effective, and sustainable. Our goal is to help you achieve measurable improvements in your business operations, productivity, and profitability.",
                        },
                        {
                            content: "Our implementation service covers a broad range of areas, including process improvements, technology adoption, organizational change management, and project management. We provide hands-on support and guidance throughout the implementation process to ensure that your team is equipped with the knowledge, tools, and resources needed to achieve success.",
                        },
                        {
                            content: "At our firm, we are committed to delivering high-quality implementation services that meet our clients' needs and exceed their expectations.",
                        },
                    ],
                },
                {
                    title: "Operations",
                    stepId: 8,
                    paragraphs: [
                        {
                            content: "Effective operations management is critical to the success of any business. Our team of experienced consultants offers a range of operations services designed to help you optimize your processes and improve your bottom line. From supply chain management to inventory control to process improvement, we work with you to identify areas for improvement and develop actionable solutions that drive results. Whether you need help streamlining your production processes, managing your inventory, or improving your customer service, we have the expertise to help you achieve your goals.",
                        }
                    ],
                },
                {
                    title: "Corporate Governance",
                    stepId: 9,
                    paragraphs: [
                        {
                            content: "Corporate governance is crucial for the success and longevity of any business. Our business management services team has years of experience in helping businesses establish strong and effective governance frameworks. We offer tailored solutions to meet your unique needs, from implementing effective board structures and developing policies and procedures to ensuring compliance with legal and regulatory requirements. Our experts work closely with you to identify potential risks and develop strategies to mitigate them, helping to ensure the long-term success of your business. Let us help you navigate the complexities of corporate governance and achieve your business goals.",
                        },
                    ],
                },
                {
                    title: "Strategy Consulting",
                    stepId: 10,
                    paragraphs: [
                        {
                            content: "Gain a competitive edge with our expert strategy consulting services. Our team of experienced consultants will work with you to analyze your business objectives and market trends, and develop a comprehensive strategy to help you achieve your goals. We provide customized solutions to meet your unique needs and ensure that your business is positioned for success in today's fast-paced and ever-changing marketplace.",
                        },
                    ],
                },
            ],
        },
    ]
}

const industriesPage: IPageContent = {
    title: "Industry specialties",
    subtitle: 'Our bread & butter.',
    stepper: [
        {
            label: "Hospitality",
            id: 0,
            main: true,
        },
        {
            label: "Restaurants",
            id: 1,
            main: false,
        },
        {
            label: "Bars",
            id: 2,
            main: false,
        },
        {
            label: "Nightclubs",
            id: 3,
            main: false,
        },
        {
            label: "E-Commerce",
            id: 4,
            main: true,
        },
        {
            label: "Cosmetics & Body Care",
            id: 5,
            main: false,
        },
        {
            label: "Health & Wellness",
            id: 6,
            main: false,
        },
        {
            label: "Furniture & Decor",
            id: 7,
            main: false,
        },
        {
            label: "Digital Services",
            id: 8,
            main: false,
        },
        {
            label: "Food & Beverage",
            id: 9,
            main: false,
        },
        {
            label: "Professional Services",
            id: 10,
            main: true,
        },
        {
            label: "Photographers",
            id: 11,
            main: false,
        },
        {
            label: "Security",
            id: 12,
            main: false,
        },
        {
            label: "Lawyers & Attorneys",
            id: 13,
            main: false,
        },
        {
            label: "Financial Services",
            id: 14,
            main: false,
        },
        {
            label: "Consulting Firms",
            id: 15,
            main: false,
        },
        {
            label: "Construction",
            id: 16,
            main: false,
        },
        {
            label: "Advertising & Media",
            id: 17,
            main: false,
        },
        {
            label: "Real Estate",
            id: 18,
            main: true,
        },
        {
            label: "Residential Property Development",
            id: 19,
            main: false,
        },
        {
            label: "Commercial Property Development",
            id: 20,
            main: false,
        },
        {
            label: "Real Estate Brokerage",
            id: 21,
            main: false,
        },
        {
            label: "Entertainment",
            id: 22,
            main: true,
        },
        {
            label: "Venues",
            id: 23,
            main: false,
        },
        {
            label: "Music Festivals",
            id: 24,
            main: false,
        },
        {
            label: "Promoters",
            id: 25,
            main: false,
        },
        {
            label: "Event Production",
            id: 26,
            main: false,
        },
        {
            label: "Talent Agents",
            id: 27,
            main: false,
        },
        {
            label: "Talent Managers",
            id: 28,
            main: false,
        },
        {
            label: "Influencers",
            id: 29,
            main: false,
        },
        {
            label: "Performers",
            id: 30,
            main: false,
        },
        {
            label: "Technology",
            id: 31,
            main: true,
        },
        {
            label: "Media Platforms",
            id: 32,
            main: false,
        },
        {
            label: "Application Development",
            id: 33,
            main: false,
        },
        {
            label: "Software Development",
            id: 34,
            main: false,
        },
        {
            label: "Hardware Development",
            id: 35,
            main: false,
        },
        {
            label: "High Net Worth Ind.",
            id: 36,
            main: true,
        },
        {
            label: "Artists",
            id: 37,
            main: false,
        },
        {
            label: "Athletes",
            id: 38,
            main: false,
        },
        {
            label: "Entrepreneurs",
            id: 39,
            main: false,
        },
        {
            label: "Executives",
            id: 40,
            main: false,
        },
        {
            label: "Founders",
            id: 41,
            main: false,
        },
        {
            label: "Manufacturers",
            id: 42,
            main: true,
        },
        {
            label: "Food & Beverage",
            id: 43,
            main: false,
        },
        {
            label: "Machinery",
            id: 44,
            main: false,
        },
        {
            label: "Technology",
            id: 45,
            main: false,
        },
    ],
    main: [
        {
            icon: hospitality,
            title: "Hospitality",
            stepId: 0,
            subsections: [
                {
                    title: 'Restaurants',
                    stepId: 1,
                    paragraphs: [{
                        content: 'Restaurants are a sub-sector of the hospitality industry that primarily provides food and beverage services to customers, typically in a sit-down or take-out setting. The restaurant industry includes a wide range of establishments, from small independent cafes and bistros to large chain restaurants and fine dining establishments.'
                    }]
                },
                {
                    title: 'Bars',
                    stepId: 2,
                    paragraphs: [{
                        content: 'Bars are a sub-sector of the hospitality industry that primarily provides alcoholic beverages and, in some cases, food to customers in a casual setting. Bars range from small neighborhood pubs to large nightclubs and sports bars, and can offer a variety of entertainment options such as live music, games, or television screens for sports viewing.'
                    }]
                },
                {
                    title: 'Nightclubs',
                    stepId: 3,
                    paragraphs: [{
                        content: 'Nightclubs are a sub-sector of the hospitality industry that typically provides entertainment, music, and alcoholic beverages to customers in a late-night setting. Nightclubs can range from small intimate venues to large multi-level establishments with multiple dance floors, bars, and VIP areas.'
                    }]
                }
            ],
        },
        {
            icon: eCommerce,
            title: "E-Commerce",
            stepId: 4,
            subsections: [
                {
                    title: 'Cosmetics & Body Care',
                    stepId: 5,
                    paragraphs: [{
                        content: 'Cosmetics and beauty is a sub-sector of the e-commerce industry that primarily focuses on the sale of skincare, haircare, makeup, and fragrance products online. This sub-sector includes a wide range of businesses, from large established beauty retailers such as Sephora and Ulta to small independent direct-to-consumer brands.'
                    }]
                },
                {
                    title: 'Health & Wellness',
                    stepId: 6,
                    paragraphs: [{
                        content: 'The health and wellness sub-sector of the e-commerce industry focuses on the sale of products and services related to personal health and wellness, including fitness equipment, nutritional supplements, and wellness services such as meditation and yoga. This sub-sector includes a wide range of businesses, from established retailers such as GNC and Vitamin Shoppe to small independent e-commerce stores.'
                    }]
                },
                {
                    title: 'Furniture & Decor',
                    stepId: 7,
                    paragraphs: [{
                        content: 'The furniture and decor sub-sector of the e-commerce industry focuses on the sale of home furnishings and decorative items online, including furniture, lighting, rugs, and wall decor. This sub-sector includes a wide range of businesses, from large established retailers such as Wayfair and Ikea to small independent e-commerce stores.'
                    }]
                },
                {
                    title: 'Digital Services',
                    stepId: 8,
                    paragraphs: [{
                        content: 'The digital services sub-sector of the e-commerce industry focuses on the sale of online services such as streaming media, cloud storage, software, and digital marketing. This sub-sector includes a wide range of businesses, from established providers such as Netflix and Adobe to small independent e-commerce stores.'
                    }]
                },
                {
                    title: 'Food & Beverage',
                    stepId: 9,
                    paragraphs: [{
                        content: 'The food and beverage sub-sector of the e-commerce industry focuses on the sale of food and beverage products online, including groceries, meal kits, snacks, and beverages. This sub-sector includes a wide range of businesses, from established retailers such as Amazon and Walmart to small independent e-commerce stores.'
                    }]
                },
            ],
        },
        {
            icon: professionalServices,
            title: "Professional Services",
            stepId: 10,
            subsections: [
                {
                    title: 'Photographers',
                    stepId: 11,
                    paragraphs: [{
                        content: 'The photography sub-sector of the professional services industry is composed of individuals or businesses that offer photographic services for events, businesses, and personal use. This sub-sector includes a wide range of specialties, such as portrait photography, wedding photography, commercial photography, and nature photography.'
                    }]
                },
                {
                    title: 'Security',
                    stepId: 12,
                    paragraphs: [{
                        content: 'The security sub-sector of the professional services industry is composed of individuals or businesses that provide security services to protect people, assets, and information. This sub-sector includes a wide range of specialties, such as physical security, cybersecurity, and risk management.'
                    }]
                },
                {
                    title: 'Lawyers & Attorneys',
                    stepId: 13,
                    paragraphs: [{
                        content: 'The legal services sub-sector of the professional services industry is composed of lawyers and attorneys who provide legal advice and representation to individuals, businesses, and organizations. This sub-sector includes a wide range of specialties, such as corporate law, family law, criminal law, and intellectual property law.'
                    }]
                },
                {
                    title: 'Financial Services',
                    stepId: 14,
                    paragraphs: [{
                        content: 'The financial services sub-sector of the professional services industry is composed of businesses and individuals that provide financial advice, management, and services to individuals, businesses, and governments. This sub-sector includes a wide range of specialties, such as banking, insurance, investment management, accounting, and tax preparation.'
                    }]
                },
                {
                    title: 'Consulting Firms',
                    stepId: 15,
                    paragraphs: [{
                        content: 'The consulting sub-sector of the professional services industry is composed of businesses and individuals who provide expert advice and guidance to other businesses and organizations. This sub-sector includes a wide range of specialties, such as management consulting, IT consulting, marketing consulting, and human resources consulting.'
                    }]
                },
                {
                    title: 'Construction',
                    stepId: 16,
                    paragraphs: [{
                        content: 'The construction sub-sector of the professional services industry is composed of businesses and individuals who provide a range of services related to the design, planning, and execution of construction projects. This sub-sector includes a wide range of specialties, such as architectural design, engineering, project management, building inspection, and construction labor.'
                    }]
                },
                {
                    title: 'Advertising & Media',
                    stepId: 17,
                    paragraphs: [{
                        content: 'The advertising and media sub-sector of the professional services industry is composed of businesses and individuals who provide services related to marketing, advertising, public relations, and content creation. This sub-sector includes a wide range of specialties, such as media planning and buying, creative development, social media management, search engine optimization, event planning, and more.'
                    }]
                },
            ],
        },
        {
            icon: realEstate,
            title: "Real Estate",
            stepId: 18,
            subsections: [
                {
                    title: 'Residential Property Development',
                    stepId: 19,
                    paragraphs: [{
                        content: 'Residential property development is a sub-sector of the real estate industry that involves the construction and sale of residential properties such as single-family homes, townhouses, and condominiums. Property developers in this sub-sector may acquire land, obtain necessary permits and approvals, and then oversee the construction of new homes or the renovation of existing properties. They may also work with architects and contractors to design and build homes that meet the needs and preferences of potential buyers.'
                    }]
                },
                {
                    title: 'Commercial Property Development',
                    stepId: 20,
                    paragraphs: [{
                        content: 'Commercial property development is a sub-sector of the real estate industry that involves the acquisition, development, and management of non-residential properties such as office buildings, retail centers, industrial parks, and hotels. They may work with architects and contractors to design and build properties that meet the needs of tenants, and may also be involved in the leasing and management of the property once it is completed.'
                    }]
                },
                {
                    title: 'Real Estate Brokerage',
                    stepId: 21,
                    paragraphs: [{
                        content: 'Real estate brokerage is a sub-sector of the real estate industry that involves connecting buyers and sellers of real estate properties and facilitating transactions between them in exchange for a commission or fee.'
                    }]
                },
            ],
        },
        {
            icon: entertainment,
            title: "Entertainment",
            stepId: 22,
            subsections: [
                {
                    title: 'Venues',
                    stepId: 23,
                    paragraphs: [{
                        content: 'The venues sub-sector of the entertainment industry includes a wide range of physical spaces such as concert halls, theaters, sports arenas, convention centers, and other event spaces, that are designed to host performances, events, and gatherings of various kinds.'
                    }]
                },
                {
                    title: 'Music Festivals',
                    stepId: 24,
                    paragraphs: [{
                        content: 'Music festivals are a sub-sector of the entertainment industry that involve large-scale events featuring multiple performers or musicians, typically held outdoors over several days and attracting large crowds of attendees. Music festivals often have a specific genre or theme, and can include a range of activities beyond just music such as food, art, and other cultural experiences.'
                    }]
                },
                {
                    title: 'Promoters',
                    stepId: 25,
                    paragraphs: [{
                        content: 'In the entertainment industry, promoters are individuals or companies that organize and promote live events, such as concerts, festivals, and sporting events. Promoters work with agents and managers to secure the talent or acts for their events, and then market and advertise the event to sell tickets and generate interest.'
                    }]
                },
                {
                    title: 'Event Production',
                    stepId: 26,
                    paragraphs: [{
                        content: 'Event production is a sub-sector of the entertainment industry that involves planning and executing events, such as concerts, festivals, and corporate events, by coordinating technical, logistical, and creative aspects of the event to ensure it runs smoothly and achieves the desired outcome.'
                    }]
                },
                {
                    title: 'Talent Agents',
                    stepId: 27,
                    paragraphs: [{
                        content: 'Talent Agents are licensed professionals who represent performers, such as actors, musicians, and other entertainers, and help them secure contracts and employment opportunities within the entertainment industry. They typically work closely with clients to develop their careers, negotiate contracts and fees, and advise on various aspects of the entertainment business.'
                    }]
                },
                {
                    title: 'Talent Managers',
                    stepId: 28,
                    paragraphs: [{
                        content: 'Talent Managers are professionals who help to guide the careers of actors, musicians, and other performers in the entertainment industry. They work with their clients to develop strategies for building their careers, negotiating contracts, and managing their professional and personal lives. Talent Managers often have close relationships with their clients and can provide valuable guidance and support throughout their careers.'
                    }]
                },
                {
                    title: 'Influencers',
                    stepId: 29,
                    paragraphs: [{
                        content: 'Influencers are individuals who have developed a large following on social media platforms such as Instagram, YouTube, and TikTok. They use their social media presence to create and share content that appeals to their followers, and they often work with brands to promote products or services to their audience.'
                    }]
                },
                {
                    title: 'Performers',
                    stepId: 30,
                    paragraphs: [{
                        content: 'Performers as a sub-sector of the entertainment industry includes individuals or groups who provide live entertainment for audiences, such as actors, musicians, comedians, dancers, magicians, and circus performers. They may perform in various settings such as theaters, concert halls, arenas, clubs, and festivals.'
                    }]
                },
            ],
        },
        {
            icon: technology,
            title: "Technology",
            stepId: 31,
            subsections: [
                {
                    title: 'Media Platforms',
                    stepId: 32,
                    paragraphs: [{
                        content: 'Media platforms are technology-based companies that offer online or mobile-based services that allow users to consume and/or share different forms of media such as video, music, podcasts, and other content. These platforms can include social media networks, video-sharing platforms, music streaming services, podcast hosting services, and other online media platforms.'
                    }]
                },
                {
                    title: 'Application Development',
                    stepId: 33,
                    paragraphs: [{
                        content: 'Application development is a sub-sector of the technology industry that focuses on creating and maintaining software applications, such as mobile apps, web apps, and desktop applications, for various devices and platforms.'
                    }]
                },
                {
                    title: 'Software Development',
                    stepId: 34,
                    paragraphs: [{
                        content: 'Software development is a sub-sector of the technology industry that involves designing, creating, testing, and maintaining software applications and systems for various purposes, including business, entertainment, education, and more. This can range from developing desktop or mobile applications to building complex systems used by large organizations.'
                    }]
                },
                {
                    title: 'Hardware Development',
                    stepId: 35,
                    paragraphs: [{
                        content: 'Hardware development is a sub-sector of the technology industry that focuses on designing, developing, and manufacturing computer hardware components, such as processors, memory, circuit boards, and other electronic devices. This sub-sector also includes companies that manufacture consumer electronics, such as smartphones, laptops, and tablets, as well as industrial and medical devices, and other types of hardware products.'
                    }]
                },
            ],
        },
        {
            icon: highNetWorthIndividuals,
            title: "High Net Worth Individuals",
            stepId: 36,
            subsections: [
                {
                    title: 'Artists',
                    stepId: 37,
                    paragraphs: [{
                        content: 'In the context of high net worth individuals, "artists" generally refers to individuals who have achieved significant success and financial wealth in fields such as music, film, television, visual arts, and literature.'
                    }]
                },
                {
                    title: 'Athletes',
                    stepId: 38,
                    paragraphs: [{
                        content: 'Athletes are a sub-sector of high net worth individuals who have achieved significant success in the field of sports. They often generate significant income from their athletic endeavors, including contracts, sponsorships, and endorsements.'
                    }]
                },
                {
                    title: 'Entrepreneurs',
                    stepId: 39,
                    paragraphs: [{
                        content: 'Entrepreneurs are individuals who start and operate a business venture in order to make a profit. As a sub-sector of high net worth individuals, entrepreneurs are typically self-made and have accumulated a significant amount of wealth through the success of their business endeavors.'
                    }]
                },
                {
                    title: 'Executives',
                    stepId: 40,
                    paragraphs: [{
                        content: 'Executives are individuals who hold top-level management positions in organizations, such as CEOs, COOs, CFOs, CIOs, and other C-level positions. They are responsible for making strategic decisions that impact the direction and success of their company. As high net worth individuals, they often earn high salaries, stock options, and other benefits that increase their wealth. They may also receive bonuses and other compensation tied to their company\'s performance. As such, executives are an important sub-sector of high net worth individuals, and their financial situation can impact the wider economy.'
                    }]
                },
                {
                    title: 'Founders',
                    stepId: 41,
                    paragraphs: [{
                        content: 'Founders are a sub-sector of high net worth individuals who have founded and/or co-founded successful businesses that have generated significant wealth. They may have a wide range of backgrounds and expertise, but they share a common entrepreneurial spirit and a track record of building successful companies. As such, they often have access to significant financial resources and may invest in other startups, venture capital funds, or other high-risk, high-reward investment opportunities. Founders may also be active in philanthropic efforts or social impact investing.'
                    }]
                },
            ],
        },
        {
            icon: manufacturers,
            title: "Manufacturers",
            stepId: 42,
            subsections: [
                {
                    title: 'Food & Beverage',
                    stepId: 43,
                    paragraphs: [{
                        content: 'The food and beverage industry is a sub-sector of the manufacturing industry that involves the production, processing, packaging, and distribution of food and beverage products. This industry is responsible for the creation of a wide range of products, including dairy products, baked goods, meat products, beverages, snacks, and many others.'
                    }]
                },
                {
                    title: 'Machinery',
                    stepId: 44,
                    paragraphs: [{
                        content: 'Machinery is a sub-sector of the manufacturing industry that focuses on the production of machines, equipment, and other mechanical systems. The machinery sub-sector often involves the use of cutting-edge technologies and engineering principles to design and produce efficient and effective machines for a wide range of industrial applications. Machinery manufacturers may also provide maintenance, repair, and support services for their products, as well as customization and engineering consulting services for clients with specific needs.'
                    }]
                },
                {
                    title: 'Technology',
                    stepId: 45,
                    paragraphs: [{
                        content: 'The technology sub-sector of the manufacturing industry involves the production of electronic components, such as semiconductors, computer chips, circuit boards, and other high-tech devices. This sub-sector includes the manufacturing of everything from small consumer electronics, such as smartphones and laptops, to larger and more complex products like medical equipment, aerospace components, and industrial machinery. The technology manufacturing sub-sector often requires specialized facilities and equipment, as well as highly skilled workers with expertise in engineering, design, and programming.'
                    }]
                },
            ],
        },
    ],
}

const faqPage: IPageContent = {
    title: "FAQ",
    subtitle: 'To all those who\'ve asked before, we say: Thank you.',
    stepper: [
        {
            label: "Popular Questions",
            id: 0,
            main: true,
        },
        {
            label: "General Questions",
            id: 1,
            main: true,
        },
        {
            label: "Billing & Finance",
            id: 2,
            main: true,
        },
        {
            label: "Cancellations",
            id: 3,
            main: true,
        },
        {
            label: "Renewals",
            id: 4,
            main: true,
        },
        {
            label: "Claims",
            id: 5,
            main: true,
        },
        {
            label: "Line of Business Specific",
            id: 6,
            main: true,
        },
    ],
    main: [
        {
            title: "Popular Questions",
            stepId: 0,
            subsections: [
                {
                    title: 'How do I file an insurance claim?',
                    id: 0,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'Do I need to worry about renewing my policy?',
                    id: 1,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What if I don\'t want to renew my insurance policy?',
                    id: 2,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What do I do if I need to cancel one of my policies?',
                    id: 3,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'How to make a policy change?',
                    id: 4,
                    paragraphs: [
                        { content: 'Email us at support@kloutgroup.com or login into your portal to make the change.' }
                    ]
                },
                {
                    title: 'How do I obtain proof of insurance/certificates?',
                    id: 5,
                    paragraphs: [
                        { content: 'Email us at support@kloutgroup.com or login into your portal and request it.' }
                    ]
                },
                {
                    title: 'How do I make a payment?',
                    id: 6,
                    paragraphs: [
                        { content: 'Payment can be made directly on your portal or if you prefer to have a invoice link emailed to you with the invoice please email us (for people who don\'t want to use the portal).' }
                    ]
                },
            ],
        },
        {
            title: "General Questions",
            stepId: 1,
            subsections: [
                {
                    title: 'What is insurance?',
                    id: 7,
                    paragraphs: [
                        {
                            content: 'Insurance is a contract between an insurance company and a policyholder. The policyholder pays a premium, and in exchange, the insurance company agrees to pay for covered losses or damages in the event of an incident or accident.'
                        }
                    ]
                },
                {
                    title: 'Who is the insurance carrier?',
                    id: 8,
                    paragraphs: [
                        {
                            content: 'An insurance carrier is a company that provides insurance policies and covers financial risks in exchange for payment of premiums. The insurance carrier assumes the risk of financial loss for the policyholder in case of an insured event, such as an accident, illness, or property damage, among others. The carrier collects premiums from policyholders and uses the money to pay out claims to policyholders who have suffered a covered loss.'
                        }
                    ]
                },
                {
                    title: 'How do I change my address?',
                    id: 9,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What is retention?',
                    id: 10,
                    paragraphs: [
                        {
                            content: 'Retention refers to the amount of risk that an insured party is willing to accept and cover themselves, rather than transferring it to an insurance company through a policy. It is sometimes used interchangeably with the term deductible, which is the amount of money the insured party must pay out of pocket before the insurance policy kicks in. The higher the retention or deductible, the lower the insurance premium the insured party will likely pay, since they are assuming more of the risk themselves. Retention is often used in commercial insurance, where businesses may opt for higher retentions to save money on premiums, but must be prepared to pay for losses that fall within that retention amount.'
                        }
                    ]
                },
                {
                    title: 'What is a deductible?',
                    id: 11,
                    paragraphs: [
                        {
                            content: 'A deductible is the amount you pay out of pocket before your insurance coverage kicks in. For example, if you have a $500 deductible on your auto insurance policy and you get into an accident that causes $2,000 in damages, you would pay the first $500, and your insurance company would cover the remaining $1,500.'
                        }
                    ]
                },
                {
                    title: 'How do I obtain a Certificate of Insurance?',
                    id: 12,
                    paragraphs: [
                        {
                            content: 'You can request this in support or directly on your dashboard.'
                        }
                    ]
                },
                {
                    title: 'What\'s an insurance premium?',
                    id: 13,
                    paragraphs: [
                        {
                            content: 'An insurance premium is the amount of money an individual or organization pays to an insurance company for a policy that provides coverage against specific risks. The premium amount is determined by the insurer based on several factors, including the level of risk, the type of coverage, the size and scope of the policy, and the deductible amount. Premiums can be paid monthly, quarterly, annually, or in a lump sum, and are typically calculated based on the probability and expected cost of potential claims.'
                        }
                    ]
                },
                {
                    title: 'How much coverage do I need?',
                    id: 14,
                    paragraphs: [
                        {
                            content: 'The amount of insurance coverage you need depends on several factors, such as the value of your assets, your income, and your risk tolerance. It\'s a good idea to talk to an insurance agent or financial advisor to determine the right amount of coverage for your individual needs.'
                        }
                    ]
                },
                {
                    title: 'How to make a policy change',
                    id: 15,
                    paragraphs: [
                        {
                            content: 'Email us at support@kloutgroup.com or login into your portal to make the change'
                        }
                    ]
                },
                {
                    title: 'What do the effective and expiration dates mean?',
                    id: 16,
                    paragraphs: [
                        {
                            content: '"The effective date of an insurance policy is the date from which the policy\'s coverage begins. It\'s the date on which the insurance company agrees to start covering the policyholder for any losses or damages that may occur. The expiration date is the date on which the policy\'s coverage ends, unless the policy is renewed or extended.',
                        },
                        {
                            content: 'For example, if a policy is effective on January 1, 2023, and expires on December 31, 2023, then the policyholder is covered by the insurance company for any losses or damages that occur during that period of time. If the policyholder wishes to continue coverage beyond December 31, 2023, they will need to renew the policy before the expiration date.',
                        }
                    ]
                },
                {
                    title: 'Can insurance help cover anything before or after the policy period?',
                    id: 17,
                    paragraphs: [
                        {
                            content: 'Typically, insurance coverage only applies during the policy period, which is the time frame specified in the insurance contract. However, there are some situations where coverage may extend beyond the policy period, such as claims-made policies in which a claim can be made for incidents that occurred during the policy period but are reported after the policy has ended. Additionally, some policies may have provisions for tail coverage or extended reporting periods that provide coverage for certain claims that occur after the policy period ends but are related to incidents that occurred during the policy period. It\'s important to review the specific terms and conditions of your insurance policy to understand the scope of coverage provided.'
                        }
                    ]
                },
                {
                    title: 'Do I need an insurance agent and an insurance broker?',
                    id: 18,
                    paragraphs: [
                        {
                            content: '"An insurance agent works for an insurance company and sells policies directly to customers. They can provide advice on policy options and coverage limits offered by their company. They also handle the administrative aspects of the policy, such as billing and claims.',
                        },
                        {
                            content: 'On the other hand, an insurance broker works independently and can offer policies from multiple insurance companies. They do not work for any particular insurance company and can provide unbiased advice on policies from different companies. They can also help customers compare policies and find the best coverage for their needs."',
                        }
                    ]
                },
                {
                    title: 'What\'s the difference between an insurance agent and an insurance broker?',
                    id: 19,
                    paragraphs: [
                        {
                            content: 'An insurance agent works directly for an insurance company and sells policies on their behalf, while an insurance broker works for the client and shops around for the best policies from various insurance companies.'
                        }
                    ]
                },
                {
                    title: 'What\'s the difference between an insurance agency and an insurance carrier?',
                    id: 20,
                    paragraphs: [
                        {
                            content: '"An insurance agency is a business that sells insurance policies on behalf of one or more insurance carriers. They act as intermediaries between the customer and the insurance carrier, helping customers find the best policies for their needs and budget. Insurance agencies may also provide services such as policy management, claims assistance, and risk assessment.',
                        },
                        {
                            content: 'An insurance carrier, on the other hand, is the company that actually provides the insurance coverage. They assume the financial risk associated with insuring the policyholder, collecting premiums and paying out claims when necessary. Insurance carriers are typically larger organizations with significant resources, and they have the ability to underwrite policies and manage risk on a larger scale."',
                        }
                    ]
                },
                {
                    title: 'How do I know what insurance policies I need?',
                    id: 21,
                    paragraphs: [
                        {
                            content: 'A business can determine what type of insurance they need by assessing the risks associated with their industry, operations, and assets. They can work with an insurance agent or broker who can help them identify potential risks and recommend appropriate coverage options. Additionally, businesses can research industry-specific insurance requirements and regulations to ensure they are meeting any legal obligations. It\'s important for businesses to regularly review their insurance coverage to ensure they have adequate protection as their operations and risks may change over time.'
                        }
                    ]
                },
            ],
        },
        {
            title: "Billing & Finance",
            stepId: 2,
            subsections: [
                {
                    title: 'Are there ways to save money on my insurance premiums?',
                    id: 22,
                    paragraphs: [
                        {
                            content: 'Yes, there are several ways to save money on your insurance premiums, such as bundling policies, raising your deductible, and taking advantage of discounts offered by your insurance company.'
                        }
                    ]
                },
                {
                    title: 'Does my credit score impact my insurance rates?',
                    id: 23,
                    paragraphs: [
                        {
                            content: 'Insurance companies use credit scores as a factor in determining rates because studies have shown a correlation between credit score and the likelihood of filing a claim. Those with higher credit scores are often seen as less risky to insure and can receive lower rates as a result.'
                        }
                    ]
                },
                {
                    title: 'What factors impact my insurance rates?',
                    id: 24,
                    paragraphs: [
                        {
                            content: 'Several factors can affect your insurance rates, including your age, gender, driving record, credit score, and location.'
                        }
                    ]
                },
                {
                    title: 'Do I have to pay in full or are there options?',
                    id: 25,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'How do I make a payment?',
                    id: 26,
                    paragraphs: [
                        {
                            content: 'Payment can be made directly on your portal or if you prefer to have a invoice link emailed to you with the invoice please email us (for people who don\'t want to use the portal).'
                        }
                    ]
                },
                {
                    title: 'What happens if I miss an insurance payment?',
                    id: 27,
                    paragraphs: [{ content: '' }]
                },
            ],
        },
        {
            title: "Cancellations",
            stepId: 3,
            subsections: [
                {
                    title: 'What do I do if I need to cancel one of my policies?',
                    id: 28,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What happens if my policy is cancelled but I still need it?',
                    id: 29,
                    paragraphs: [{ content: '' }]
                },
            ],
        },
        {
            title: "Renewals",
            stepId: 4,
            subsections: [
                {
                    title: 'Do I need to worry about renewing my policy?',
                    id: 30,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What if I don\'t want to renew my insurance policy?',
                    id: 31,
                    paragraphs: [{ content: '' }]
                },
            ],
        },
        {
            title: "Claims",
            stepId: 5,
            subsections: [
                {
                    title: 'How long does claim processing usually take?',
                    id: 32,
                    paragraphs: [
                        {
                            content: 'The time it takes to process a claim varies depending on the type of claim and the insurance company. Some claims may be processed quickly, while others may take several weeks or months.'
                        }
                    ]
                },
                {
                    title: 'How do I file an insurance claim?',
                    id: 33,
                    paragraphs: [{ content: '' }]
                },
                {
                    title: 'What is an insurance claim?',
                    id: 34,
                    paragraphs: [
                        {
                            content: 'An insurance claim is a formal request made by an individual or business to an insurance company for compensation or coverage for a loss or damage covered by an insurance policy. The claim can be made for various reasons, such as damage to property, injury, illness, or death. The insurance company will evaluate the claim based on the terms and conditions of the policy and determine if the claim is covered. If the claim is approved, the insurance company will provide the policyholder with compensation or reimbursement for the loss or damage.'
                        }
                    ]
                },
                {
                    title: 'Do I need to make a claim?',
                    id: 35,
                    paragraphs: [
                        {
                            content: 'An insurance claim needs to be made when a policyholder experiences a loss or damage covered by their insurance policy. The claim should be made promptly after the incident occurs, as most insurance policies have a deadline for filing a claim. It is important to review the policy terms and conditions to determine the specific time frame for making a claim. Delays in filing a claim may result in a denial of coverage or reduced benefits.'
                        }
                    ]
                },
                {
                    title: 'When don\'t I need to make a claim?',
                    id: 36,
                    paragraphs: [
                        {
                            content: 'A person may not need to make an insurance claim if the damage or loss incurred is minor and within their deductible amount. For example, if a person\'s car incurs a minor scratch that would cost $200 to repair, but their deductible is $500, it may not be necessary to make an insurance claim. Additionally, if the cost of repairs is less than the increase in insurance premiums that would result from making a claim, it may be better to pay for the repairs out of pocket. It\'s important to consider the specific details of each situation to determine whether it is necessary or beneficial to make an insurance claim.'
                        }
                    ]
                },
            ],
        },
        {
            title: "Line of Bussiness Specific",
            stepId: 6,
            subsections: [
                {
                    title: 'Is workers compensation required?',
                    id: 37,
                    paragraphs: [
                        {
                            content: 'Yes, workers\' compensation insurance is required by law in almost every state in the United States. The exact requirements vary by state, but in general, employers are required to provide workers\' compensation insurance to their employees to cover medical expenses and lost wages resulting from on-the-job injuries or illnesses. Failure to provide workers\' compensation insurance can result in legal penalties, fines, and even criminal charges in some cases.'
                        }
                    ]
                },
                {
                    title: 'What are frequently required policies for hospitality related businesses?',
                    id: 38,
                    paragraphs: [
                        {
                            content: '"In the United States, there are several insurance policies that are frequently required for hospitality-related businesses, including:',
                        },
                        {
                            content: 'General liability insurance: This policy provides coverage for third-party bodily injury, property damage, and personal injury claims. It is often required by landlords or property owners.',
                        },
                        {
                            content: 'Liquor liability insurance: This policy provides coverage for claims related to the sale or service of alcoholic beverages. It is required for businesses that serve alcohol.',
                        },
                        {
                            content: 'Property insurance: This policy provides coverage for physical damage to the business property and its contents. It is often required by lenders or property owners.',
                        },
                        {
                            content: 'Workers\' compensation insurance: This policy provides coverage for work-related injuries or illnesses suffered by employees. It is required in most states for businesses with employees.',
                        },
                        {
                            content: 'Commercial auto insurance: This policy provides coverage for vehicles used for business purposes. It is required for businesses that own or lease vehicles.',
                        },
                        {
                            content: 'Employment practices liability insurance (EPLI): This policy provides coverage for claims related to wrongful termination, discrimination, harassment, and other employment-related issues. It is recommended for businesses with employees.',
                        },
                        {
                            content: 'Cyber liability insurance: This policy provides coverage for claims related to cyber attacks, data breaches, and other cyber-related incidents. It is recommended for businesses that collect and store sensitive customer information."',
                        }
                    ]
                },
                {
                    title: 'Is liquor liability required?',
                    id: 39,
                    paragraphs: [
                        {
                            content: 'Liquor liability insurance is typically required for businesses that sell, serve, or furnish alcohol, such as bars, restaurants, and event venues. The specific requirements vary by state and local jurisdiction, but many states require businesses to carry liquor liability insurance in order to obtain or renew a liquor license. Even in states where liquor liability insurance is not required by law, it is often recommended as a means of protecting businesses from the financial risks associated with alcohol-related accidents or incidents.'
                        }
                    ]
                },
            ],
        },
    ],
}

const pageContents = {
    advisoryPage,
    industriesPage,
    faqPage
}

export default pageContents