import { useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import './style.css'

const ScrollToTop = () => {
    const [visible, setVisible] = useState(false)

    window.addEventListener('scroll', () => {
        if (window.scrollY > 700) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    })

    return (
        <div className="scroll-to-top">
            {visible && (
                <button
                    className="scroll-to-top-button"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    <ArrowUpwardIcon />
                </button>
            )}
        </div>
    )
}

export default ScrollToTop