import { Box, Stepper, Typography, Step, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import classes from "../style/index";
import whatIsIt from "../../../assets/icons/whatIsIt.svg";
import doINeedIt from "../../../assets/icons/doINeedIt.svg";
import whatDoesnotItCover from "../../../assets/icons/whatDoesnotItCover.svg";
import whatDoesItCover from "../../../assets/icons/whatDoesItCover.svg";

const steps = [
  {
    label: "What is it?",
    icon: whatIsIt,
  },
  {
    label: "What does it cover?",
    icon: whatDoesItCover,
  },
  {
    label: "What doesn't it cover?",
    icon: whatDoesnotItCover,
  },
  {
    label: "Do I need it?",
    icon: doINeedIt,
  },
];

interface Props {
  activeStep: number;
  handleScroll(index: number): void;
}

const SideStepper = (props: Props): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={classes.stepperContainer}>
      <Stepper
        activeStep={props.activeStep}
        sx={classes.stepper}
        orientation={isMobile ? "horizontal" : "vertical"}
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            onClick={() => props.handleScroll(index)}
            sx={classes.step}
          >
            {isMobile ? (
              <></>
            ) : (
              <Box
                sx={{
                  ...classes.stepDot,
                  backgroundColor:
                    index === props.activeStep ? "black" : "white",
                }}
              />
            )}
            {isMobile ? (
              <img
                src={step.icon}
                alt={step.label}
                style={{ height: "26px", cursor: "pointer" }}
              />
            ) : (
              <Typography sx={classes.stepLabel}>{step.label}</Typography>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default SideStepper;
