import { Box } from "@mui/system";
import AccountMenu from "../authentication/AccountMenu";
import LoginButton from "../styled/LoginButton";

import { useEffect, useState } from "react";

interface Props {
  isLoggedIn: boolean;
  isHovered: boolean;
  startScroll?: boolean;
  handleOpenLoginDialog: () => void;
  handleOpenRegisterDialog: () => void;
  isOpenDropdown?: boolean;
  setIsOpenDropdown?: any;
}
export const LoggedUser = (props: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) {
    return <></>;
  }
  return (
    <>
      {props.isLoggedIn && !loading ? (
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            width: "40%",
          }}
        >
          <AccountMenu
            isHovered={props.isHovered}
            startScroll={props.startScroll}
            openHamburgerMenu={false}
            isOpenDropdown={props.isOpenDropdown}
            setIsOpenDropdown={props.setIsOpenDropdown}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            marginTop: "10px",
          }}
        >
          <LoginButton
            aria-label="Log in"
            onClick={props.handleOpenLoginDialog}
          >
            Log In
          </LoginButton>
          <LoginButton
            aria-label="Sign up"
            onClick={props.handleOpenRegisterDialog}
          >
            Sign Up
          </LoginButton>
        </Box>
      )}
    </>
  );
};
