import { Box, List, ListItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "../styles/styles";

interface Props {
  setOpenHamburgerMenu: (open: boolean) => void;
}

const IndustriesMenu = (props: Props) => {
  const closeHamburgerMenu = () => {
    props.setOpenHamburgerMenu(false);
  };

  return (
    <>
      <Box sx={classes.extendedMenu}>
        <List
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            width: "100%",
          }}
        >
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=0`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>Hospitality</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=22`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>Entertainment</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=4`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>E-Commerce</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=31`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>Technology</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=10`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>
                Professional Services
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=36`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>
                High Net Worth Individuals
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=42`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>Manufacturers</Typography>
            </Link>
          </ListItem>
          <ListItem sx={classes.listItem}>
            <Link
              to={`/industries-detailed?section=18`}
              style={{ textDecoration: "none" }}
              onClick={closeHamburgerMenu}
            >
              <Typography sx={classes.listText}>Real Estate</Typography>
            </Link>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default IndustriesMenu;
