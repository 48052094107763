import { Grid, Typography, Divider } from "@mui/material";
import { Box, Container } from "@mui/material";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import commercial from "../assets/icons/commercial_insurance.svg";
import personal from "../assets/icons/personal_insurance.svg";
import versus from "../assets/icons/versus.svg";
import { useState } from "react";

import Insurance from "../pages/Insurance";

const classes = {
  pageTitle: {
    textAlign: "center",
    fontSize: { xs: "20px", sm: "25px" },
    margin: "100px 50px 20px",
  },
  insuranceTitle: {
    fontSize: { xs: "20px", sm: "30px", md: "40px" },
    fontWeight: "bold",
    marginBottom: "20px",
  },
  insuranceDescription: {
    fontSize: "20px",
    marginTop: "20px",
    padding: { xs: "0 40px", sm: 0 },
  },
};

const CommercialVsPersonal = () => {
  const [openPurpose, setOpenPurpose] = useState(false);
  const [openCoverage, setOpenCoverage] = useState(false);
  const [openLiability, setOpenLiability] = useState(false);
  const [openCost, setOpenCost] = useState(false);

  return (
    <Container>
      <Typography sx={classes.pageTitle}>
        Commercial insurance and personal insurance are two different types of
        coverage designed to protect different types of risks.
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ textAlign: "center", marginBottom: "40px" }}
        >
          <Typography sx={classes.insuranceTitle}>
            COMMERCIAL INSURANCE
          </Typography>
          <img src={commercial} style={{ width: "200px" }} />
          <Typography sx={classes.insuranceDescription}>
            Commercial Insurance is designed to protect businesses against risks
            related to their operations.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: "center",
            marginTop: { xs: "10px", sm: "180px" },
            marginBottom: { xs: "70px" },
          }}
        >
          <img src={versus} style={{ width: "70px" }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{ textAlign: "center", marginBottom: "40px" }}
        >
          <Typography sx={classes.insuranceTitle}>
            PERSONAL INSURANCE
          </Typography>
          <img src={personal} style={{ width: "200px" }} />
          <Typography sx={classes.insuranceDescription}>
            Personal Insurance is designed to protect individuals and families
            against risks related to their personal lives.
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{ fontSize: { xs: "22px", sm: "25px" }, textAlign: "center" }}
      >
        How are they different?
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        There's actually a few key differences! Check them out below:
      </Typography>

      <Box sx={{ padding: { xs: "0 20px", sm: 0 } }}>
        <Box
          onClick={() => {
            setOpenPurpose(!openPurpose);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "22px", sm: "25px" },
                marginBottom: "20px",
              }}
            >
              PURPOSE
            </Typography>
            <Box>{openPurpose ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse in={openPurpose} timeout="auto" unmountOnExit>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Personal insurance is designed to protect individuals and families
              against risks related to their personal lives such as property
              damage, theft, or personal liability.
            </Typography>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Commercial insurance is designed to protect businesses against
              risks related to their operations such as property damage,
              liability, or business interruption.
            </Typography>
          </Collapse>
        </Box>
        <Divider sx={{ marginBottom: "30px" }} />

        <Box
          onClick={() => {
            setOpenCoverage(!openCoverage);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "22px", sm: "25px" },
                marginBottom: "20px",
              }}
            >
              COVERAGE
            </Typography>
            <Box>{openCoverage ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse in={openCoverage} timeout="auto" unmountOnExit>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Personal insurance typically covers individuals and their personal
              belongings.
            </Typography>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Commercial insurance covers businesses and their property,
              equipment, and employees.
            </Typography>
          </Collapse>
        </Box>
        <Divider sx={{ marginBottom: "30px" }} />

        <Box
          onClick={() => {
            setOpenLiability(!openLiability);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "22px", sm: "25px" },
                marginBottom: "20px",
              }}
            >
              LIABILITY
            </Typography>
            <Box>{openLiability ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse in={openLiability} timeout="auto" unmountOnExit>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Personal insurance usually includes personal liability coverage,
              which protects individuals from lawsuits if they are found liable
              for damages or injuries.
            </Typography>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Commercial insurance includes both general liability coverage and
              professional liability coverage, which protect businesses from
              lawsuits related to their operations.
            </Typography>
          </Collapse>
        </Box>
        <Divider sx={{ marginBottom: "30px" }} />

        <Box
          onClick={() => {
            setOpenCost(!openCost);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "22px", sm: "25px" },
                marginBottom: "10px",
              }}
            >
              COST
            </Typography>
            <Box>{openCost ? <ExpandLess /> : <ExpandMore />}</Box>
          </Box>
          <Collapse in={openCost} timeout="auto" unmountOnExit>
            <Typography sx={{ margin: "20px 0", fontSize: "20px" }}>
              Personal insurance is usually less expensive than commercial
              insurance, since the risks involved are generally lower.
            </Typography>
            <Typography sx={{ margin: "20px 0 0", fontSize: "20px" }}>
              Commercial insurance premiums are based on the size of the
              business, the nature of its operations, and the risks it faces.
            </Typography>
          </Collapse>
        </Box>
      </Box>

      <Insurance />
    </Container>
  );
};
export default CommercialVsPersonal;
