import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    background: "#FFF",
    borderRadius: "8px",
    height: "42px",
    padding: "12px 16px",
    width: "400px",

    "&.Mui-focused fieldset": {
      border: "1px solid #888888",
    },
  },

  "& .MuiOutlinedInput-input": {
    borderRadius: "8px",
    height: "16px",
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 100px #fff inset',
    }
  },
  
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
    fontWeight: "400",
    marginLeft:"32px"
  },

});

export default StyledTextField;
