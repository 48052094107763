import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    Stepper, Step, Typography, Box, useMediaQuery, Autocomplete, TextField, ListItem, Collapse
} from '@mui/material'
import InputAdornment from "@mui/material/InputAdornment"
import { useTheme } from "@mui/material/styles"
import { IPageContent, ISection, ISubsection, IParagraph } from 'src/data/static/pages'
import { Link } from 'react-scroll'
import { useSpring, animated } from '@react-spring/web'
import Footer from '../Footer'
import ScrollToTop from '../ScrollToTop/ScrollToTop'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SearchIcon from "@mui/icons-material/Search"

const classes = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "67px 20px 50px 20px", // height of Navbar is 67px
    },
    main: {
        position: "relative",
        display: "flex",
        flexDirection: {
            xs: "column",
            md: "row",
        },
        width: "100%",
        mt: {
            xs: "20px",
            sm: "40px",
        },
        mb: {
            xs: "30px",
            md: "50px",
        },
    },
    pageTitle: {
        fontSize: {
            xs: "18px",
            sm: "24px",
            md: "29px",
            lg: "34px",
        },
        padding: {
            xs: "30px 20px 0 20px",
            sm: "20px 0 0 0",
        },
        fontWeight: "bold",
        textTransform: "uppercase",
        textAlign: "center",
    },
    subtitle: {
        fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
        },
        padding: {
            xs: "10px",
        },
        letterSpacing: "1.1px",
        textAlign: "center",
        width: {
            xs: "100%",
            md: '60%'
        }
    },
    stepperContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        mb: {
            xs: "40px",
            md: "0",
        },
        position: { md: "fixed" },
        height: "400px",
        overflowY: "auto",
        "-webkit-overflow-scrolling": "touch",
        "::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
    },
    stepper: {
        position: "sticky",
        width: "100%",
        maxWidth: "300px",
    },
    step: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
    },
    stepDot: {
        mr: "8px",
        ml: "8px",
        height: "8px",
        width: "8px",
        border: "1px solid black",
        borderRadius: "50%",
    },
    stepLabel: {
        textDecoration: "none",
        textTransform: "none",
        color: "black",
        ":hover": {
            cursor: "pointer",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        flex: 4,
        marginLeft: { md: "200px" },
    },
    contentBox: {
        padding: {
            md: "0 30px",
        },
        mb: "2em"
    },
    contentBoxTitle: {
        fontSize: {
            xs: "16px",
            sm: "21px",
            md: "23px",
        },
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    optionBox: {
        margin: {
            xs: "20px 0",
            md: "40px 0",
        },
        padding: {
            xs: "0 30px",
            md: "0 180px 0 30px",
        },
        paddingLeft: { md: "50px" }
    },
    optionTitle: {
        fontSize: {
            xs: "14px",
            sm: "17px",
            md: "19px",
        },
        textTransform: "uppercase",
        textDecoration: {
            xs: "none",
            sm: "underline",
        },
        fontWeight: {
            xs: "bold",
            sm: "normal",
        },
    },
    paragraph: {
        margin: {
            xs: "10px 0 20px 0",
        },
        fontSize: {
            xs: "15px",
            md: "16px",
        },
    },
    sectionTitle: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: {
            md: '18px'
        }
    },
    listItem: {
        width: {
            xs: '100%',
            md: '600px'
        },
        display: 'flex',
        justifyContent: 'space-between'
    },
    questionText: {
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: '17px',
    },
    answerText: {
        margin: '10px 0',
        paddingLeft: '16px',
        fontSize: '15px',
        width: {
            xs: '100%',
            md: '550px'
        },
    },
    autocomplete: {
        width: {
            xs: '80%',
            sm: '50%'
        },
        display: 'flex',
        justifyContent: 'center',
        margin: {
            xs: '20px 0',
            sm: '30px 0'
        }
    }
}

const StepperPage: React.FC<{ pageContent: IPageContent, isFaq?: boolean }> = ({ pageContent, isFaq = false }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const location = useLocation()
    const [activeStep, setActiveStep] = useState(0)
    const [stepperVisible, setStepperVisible] = useState(true)
    const [openQuestions, setOpenQuestions] = useState<Array<boolean>>(pageContent.main.flatMap((section) => section.subsections.map(() => false)))
    const sectionsRef = useRef<(HTMLElement | null)[]>([])
    const questionRefs = useRef<{ [key: string]: HTMLElement | null }>({})
    const queryParams = new URLSearchParams(location.search)
    const sectionId = queryParams.get('section')

    const springProps = useSpring({
        opacity: stepperVisible ? 1 : 0,
        config: { tension: 210, friction: 20 },
    })

    useEffect(() => {
        const handleScroll = () => {
            let index = 0
            for (let i = 0; i < sectionsRef.current.length; i++) {
                const section = sectionsRef.current[i]
                if (section) {
                    const rect = section.getBoundingClientRect()
                    if (rect.top >= 72) { // Adjust for navbar height
                        index = i
                        break
                    }
                }
            }
            setActiveStep(index)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const footerElement = document.getElementById("footer")
        if (footerElement) {
            const footerObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        setStepperVisible(!entry.isIntersecting)
                    })
                },
                { threshold: 0.1 } // trigger when 10% of the footer is visible
            )

            footerObserver.observe(footerElement)

            return () => {
                footerObserver.unobserve(footerElement)
            }
        }
    }, [])

    useEffect(() => {
        if (sectionId) {
            const index = pageContent.stepper.findIndex(step => step.id.toString() === sectionId)
            if (index >= 0) {
                const section = sectionsRef.current[index]
                if (section) {
                    const y =
                        section.getBoundingClientRect().top + window.scrollY - 72 // Adjust for navbar height
                    window.scrollTo({ top: y, behavior: 'smooth' })
                    setActiveStep(index)
                }
            }
        }
    }, [sectionId, pageContent.stepper])

    const renderParagraphs = (paragraphs: IParagraph[]) => paragraphs.map((p, index) => (
        <Typography sx={classes.paragraph} key={index}>{p.content}</Typography>
    ))

    const renderSubSections = (subsections: ISubsection[]) => subsections.map((s, index) => (
        <div
            id={s.stepId ? s.stepId.toString() : `subsection-${index}`}
            ref={ref => {
                if (s.stepId !== undefined) {
                    sectionsRef.current[s.stepId] = ref
                }
            }}
            key={index}
        >
            <Box sx={classes.optionBox}>
                <Typography sx={classes.optionTitle}>{s.title}</Typography>
                {renderParagraphs(s.paragraphs)}
            </Box>
        </div>
    ))

    const renderSections = (sections: ISection[]) => sections.map((s, index) => (
        <div id={s.stepId.toString()} ref={ref => sectionsRef.current[s.stepId] = ref} key={index}>
            <Box sx={classes.contentBox}>
                <Typography sx={classes.contentBoxTitle}>{s.title}</Typography>
                {renderSubSections(s.subsections)}
            </Box>
        </div>
    ))

    const handleQuestionClick = (index: number) => {
        const newOpenQuestions = [...openQuestions]
        newOpenQuestions[index] = !newOpenQuestions[index]
        setOpenQuestions(newOpenQuestions)
    }

    const renderFAQs = (faqs: ISection[]) => faqs.map((faq, sectionIndex) => (
        <div id={faq.stepId.toString()} ref={ref => sectionsRef.current[faq.stepId] = ref} key={sectionIndex}>
            <Box key={sectionIndex} sx={{ ...classes.contentBox, paddingLeft: { md: '100px' } }}>
                <Typography sx={classes.contentBoxTitle}>{faq.title}</Typography>
                {
                    faq.subsections.map((question, questionIndex) => {
                        const overallIndex = sectionIndex * faq.subsections.length + questionIndex
                        return <Box sx={classes.optionBox} key={questionIndex}>
                            <ListItem sx={classes.listItem} onClick={() => handleQuestionClick(overallIndex)}
                                ref={(ref) => {
                                    if (question.id !== undefined) {
                                        questionRefs.current[question.id] = ref
                                    }
                                }}
                            >
                                <Typography sx={classes.questionText}>{question.title}</Typography>
                                {openQuestions[overallIndex] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </ListItem>
                            <Collapse in={openQuestions[overallIndex]} timeout='auto' unmountOnExit>
                                {
                                    question.paragraphs.map((paragraph, pIndex) => {
                                        return <Typography sx={classes.answerText} key={pIndex}>
                                            {paragraph.content}
                                        </Typography>
                                    })
                                }
                            </Collapse>
                        </Box>
                    })
                }
            </Box>
        </div>
    ))

    return <React.Fragment>
        <Box sx={classes.container}>
            <Typography sx={classes.pageTitle}>{pageContent.title}</Typography>
            <Typography sx={classes.subtitle}>
                {pageContent.subtitle}
            </Typography>
            {
                isFaq ? <Autocomplete
                    freeSolo
                    options={pageContent.main.flatMap(section => section.subsections.map(q => q.title))}
                    onInputChange={(_, value) => {
                        const question = pageContent.main.flatMap(section => section.subsections).find(q => q.title === value)
                        if (question && question.id !== undefined && questionRefs.current[question.id]) {
                            const offsetPosition = questionRefs.current[question.id]!.offsetTop - 72 // Adjust for navbar height 
                            window.scrollTo({
                                top: offsetPosition,
                                behavior: "smooth"
                            })
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search Your FAQ!"
                            aria-labelledby="Search Your FAQ!"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiInputBase-input::placeholder': {
                                    color: 'black',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                },
                            }}
                        />
                    )}
                    sx={classes.autocomplete}
                /> : <></>
            }
            <Box sx={classes.main}>
                {isMobile ? <></> : <animated.div style={springProps}>
                    <Box sx={classes.stepperContainer}>
                        <Stepper activeStep={activeStep} orientation="vertical" sx={classes.stepper}
                        >
                            {pageContent.stepper.map((step, index) => (
                                <Step key={step.id} sx={classes.step}
                                >
                                    {isMobile ? (
                                        <></>
                                    ) : (
                                        <Box
                                            sx={{
                                                ...classes.stepDot,
                                                backgroundColor:
                                                    index === activeStep ? "black" : "white",
                                            }}
                                        />
                                    )}
                                    {isMobile ? (
                                        <img
                                            src={step.icon}
                                            alt={step.label}
                                            style={{ height: "26px", cursor: "pointer" }}
                                        />
                                    ) : (
                                        <Link
                                            to={step.id.toString()}
                                            smooth={true}
                                            offset={-72} // Adjust for navbar height 
                                        >
                                            <Typography
                                                sx={{
                                                    ...classes.stepLabel,
                                                    fontSize: step.main ? "15px" : "12px",
                                                }}
                                            >
                                                {step.label}
                                            </Typography>
                                        </Link>
                                    )}
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </animated.div>
                }
                <Box sx={classes.content}>
                    {isFaq ? renderFAQs(pageContent.main) : renderSections(pageContent.main)}
                </Box>
            </Box>
        </Box>
        <ScrollToTop />
        <Footer />
    </React.Fragment>

}

export default StepperPage
