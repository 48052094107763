import { ChangeEvent, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import axiosConfig from "src/lib/axios";
import http from "src/services/http";
import DashboardTextField from "./DashboardTextField";
import classes from "./styles/styles";

const PersonalDashboard = (): JSX.Element => {
  const current = new Date();
  const date = `${current.getFullYear()}-0${
    current.getMonth() + 1
  }-0${current.getDate()}`;

  const [isChange, setIsChange] = useState(false);
  const [idValue, setIdValue] = useState<any>("");
  const [emailValue, setEmailValue] = useState<any>("");
  const [firstNameValue, setFirstNameValue] = useState<any>("");
  const [lastNameValue, setLastNameValue] = useState<any>("");
  const [birthDateValue, setBirthDateValue] = useState<any>(date);
  const [addressValue, setAddressValue] = useState<any>("");
  const [cityValue, setCityValue] = useState<any>("");
  const [stateValue, setStateValue] = useState<any>("");
  const [zipCodeValue, setZipCodeValue] = useState<any>("");
  const [driverLicenseValue, setDriverLicenseValue] = useState<any>("");
  const [driverLicenseStateValue, setDriverLicenseStateValue] =
    useState<any>("");
  const [phoneValue, setPhoneValue] = useState<any>("");
  const [occupationValue, setOccupationValue] = useState<any>("");

  const onChangeFirstName = (e: any) => {
    console.log(e.target);
    setFirstNameValue(e.target.value);
    setIsChange(true);
  };

  const onChangeLastName = (e: any) => {
    console.log(e.target);
    setLastNameValue(e.target.value);
    setIsChange(true);
  };

  const onChangeDateOfBirth = (e: any) => {
    setBirthDateValue(e.target.value);
    setIsChange(true);
  };

  const onChangeAddress = (e: any) => {
    setAddressValue(e.target.value);
    setIsChange(true);
  };
  const onChangeCity = (e: any) => {
    setCityValue(e.target.value);
    setIsChange(true);
  };

  const onChangeState = (e: any) => {
    setStateValue(e.target.value);
    setIsChange(true);
  };

  const onChangeZipCode = (e: any) => {
    setZipCodeValue(e.target.value);
    setIsChange(true);
  };

  const onChangeDriverLicense = (e: any) => {
    setDriverLicenseValue(e.target.value);
    setIsChange(true);
  };

  const onChangeDriverLicenseState = (e: any) => {
    setDriverLicenseStateValue(e.target.value);
    setIsChange(true);
  };

  const onChangePhone = (e: any) => {
    setPhoneValue(e.target.value);
    setIsChange(true);
  };

  const onChangeOccupation = (e: any) => {
    setOccupationValue(e.target.value);
    setIsChange(true);
  };

  const getUserOurForm = () => {
    const token = window.localStorage.getItem("token");
    if (token !== null) {
      http.get("/api/v1/user/login", {}, { token: token }).then((res: any) => {
        setIdValue(res.data.id);
        setEmailValue(res.data.email);
        setFirstNameValue(res.data.first_name);
        setLastNameValue(res.data.last_name);
        setBirthDateValue(res.data.birth_date);
        setAddressValue(res.data.address);
        setCityValue(res.data.city);
        setStateValue(res.data.state);
        setZipCodeValue(res.data.zip_code);
        setDriverLicenseValue(res.data.drivers_licence);
        setDriverLicenseStateValue(res.data.drivers_licence_state);
        setPhoneValue(res.data.phone);
        setOccupationValue(res.data.occupation);
      });
    }
  };

  const getUserDefaultForm = () => {
    const accountToken = window.localStorage.getItem("accountToken");
    if (accountToken !== null) {
      const userObj: any = jwt_decode(accountToken);
      const email: string = userObj.email
        ? userObj.email
        : userObj.preferred_username;
      axiosConfig
        .get(`/api/v1/user/get_by_email?email=${email}`)
        .then((res: any) => {
          console.log(email);
          setIdValue(res.data.id);
          setEmailValue(res.data.email);
          setFirstNameValue(res.data.first_name);
          setLastNameValue(res.data.last_name);
          setBirthDateValue(res.data.birth_date.split("T")[0]);
          setAddressValue(res.data.address);
          setCityValue(res.data.city);
          setStateValue(res.data.state);
          setZipCodeValue(res.data.zip_code);
          setDriverLicenseValue(res.data.drivers_licence);
          setDriverLicenseStateValue(res.data.drivers_licence_state);
          setPhoneValue(res.data.phone);
          setOccupationValue(res.data.occupation);
        });
    }
  };

  const onClickSave = () => {
    updateUserPersonalInfo();
    setIsChange(false);
  };

  const updateUserPersonalInfo = () => {
    const updateUrl = `/api/v1/user/update_base_info/${idValue}`;
    axiosConfig
      .patch(updateUrl, {
        first_name: firstNameValue,
        last_name: lastNameValue,
        birth_date: birthDateValue,
        address: addressValue,
        city: cityValue,
        state: stateValue,
        zip_code: zipCodeValue,
        drivers_licence: driverLicenseValue,
        drivers_licence_state: driverLicenseStateValue,
        phone: phoneValue,
        occupation: occupationValue,
      })
      .then((response) => console.log(response.data))
      .catch((error) => console.error(error));
  };

  const [files, setFiles] = useState<File[]>([]);
  const [isUploadFile, setIsUploadFile] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUploadFile(false);
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setFiles(selectedFiles);
    }
  };

  const handleUploadFiles = async () => {
    if (!files.length) {
      return;
    }

    const url = "/api/v1/form/upload_policy";
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("documents", file);
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
    };

    axiosConfig.post(url, formData, config).then((response) => {
      setIsUploadFile(true);
      console.log(response.data);
    });
  };

  useEffect(() => {
    getUserOurForm();
    getUserDefaultForm();
  }, []);

  return (
    <Box sx={classes.rightSideContainer}>
      <Box sx={classes.boxesContainar}>
        <Typography sx={classes.primaryText}>
          Hello {firstNameValue}, Welcome back!
        </Typography>
        <Typography sx={classes.primaryText}>Profile</Typography>
        <Box sx={classes.addCoApplicationContainer}>
          <Button
            sx={{
              textTransform: "none",
              mr: "30px",
              color: "#109CF1",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            + add co-applicant
          </Button>
          <Button
            variant="contained"
            sx={{ borderRadius: "20px", textTransform: "none" }}
            disabled={!isChange}
            onClick={onClickSave}
          >
            Save
          </Button>
        </Box>
        <Box sx={classes.formContainer}>
          <DashboardTextField
            value={firstNameValue}
            onChange={onChangeFirstName}
          />
          <DashboardTextField
            value={lastNameValue}
            onChange={onChangeLastName}
          />
          <DashboardTextField
            type={"date"}
            value={birthDateValue}
            onChange={onChangeDateOfBirth}
          />
        </Box>
        <Box sx={classes.formContainer}>
          <DashboardTextField
            text={"Street address"}
            value={addressValue}
            onChange={onChangeAddress}
          />
          <DashboardTextField
            text={"City"}
            value={cityValue}
            onChange={onChangeCity}
          />
          <DashboardTextField
            text={"State"}
            value={stateValue}
            onChange={onChangeState}
          />
          <DashboardTextField
            text={"Zip code"}
            value={zipCodeValue}
            maxLength={5}
            onChange={onChangeZipCode}
          />
        </Box>
        <Box sx={classes.formContainer}>
          <DashboardTextField
            text={"Drivers License #"}
            value={driverLicenseValue}
            onChange={onChangeDriverLicense}
          />
          <DashboardTextField
            text={"Driver License State"}
            value={driverLicenseStateValue}
            select={true}
            onChange={onChangeDriverLicenseState}
          />
        </Box>
        <Box sx={classes.formContainer}>
          <DashboardTextField
            text={"Phone"}
            value={phoneValue}
            maxLength={11}
            onChange={onChangePhone}
          />
          <DashboardTextField
            type={"email"}
            value={emailValue}
            disabled={true}
          />
          <DashboardTextField
            text={"Occupation"}
            value={occupationValue}
            onChange={onChangeOccupation}
          />
        </Box>
      </Box>
      <Box sx={classes.policiesContainer}>
        <Typography sx={classes.primaryText}>Your Policies</Typography>
        <Typography sx={classes.secondaryText}>
          You have no policies available to display.
        </Typography>
        <Button variant="contained" sx={classes.uploadButton}>
          Shop for coverage
        </Button>
      </Box>
      <Box sx={classes.policiesContainer}>
        <Typography sx={classes.primaryText}>
          Non - Embroker Policies
        </Typography>
        <Typography sx={classes.secondaryText}>
          <strong>Have a non-Embroker policy?</strong> Upload it here to manage
          all your policies in one place or transfer it to Embroker.
        </Typography>
        <Box sx={classes.uploadFileBox}>
          <input type={"file"} multiple onChange={handleFileChange} />
        </Box>
        {!isUploadFile ? (
          <Button
            variant="contained"
            onClick={handleUploadFiles}
            sx={classes.uploadButton}
          >
            Upload
          </Button>
        ) : (
          <Typography sx={classes.successUploadText}>
            Successfully uploaded {files.length} file!
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PersonalDashboard;
