import { useEffect, useState, forwardRef } from 'react'
import { Box, Typography, List, ListItem, Collapse, Divider, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Coverage } from 'src/data/static/insuranceInfo'

import CircleIcon from '@mui/icons-material/Circle'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

import classes from '../style/index'

interface Props {
    coverage: Coverage
}

const InsuranceCoverage = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [isOpen, setIsOpen] = useState<{ id: number, isOpen: boolean }[]>([])

    const handleClick = (index: number) => {
        const newArray = isOpen.map((obj) => {
            if (obj.id === index) {
                obj.isOpen = !obj.isOpen
            }

            return obj
        })
        setIsOpen(newArray)
    }


    const createParagraph = (list: { text: string, isBold: boolean, bulletList?: string[] }[]): JSX.Element => {
        const boldedTexts = list.map((obj, i) => {
            const { text, isBold } = obj
            const key = `text-${i}`
            if (isBold) {
                return <Typography key={key} component="span" fontWeight="bold">{text}</Typography>
            } else {
                return <span key={key}>{text}</span>
            }
        })

        const bulletList = list.map((obj, i) => {
            const { bulletList } = obj
            const key = `bullet-${i}`
            if (bulletList) {
                return <List key={key}>
                    {bulletList.map((bullet, i) => {
                        return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircleIcon sx={classes.dot} />
                            <ListItem key={`bullet-${i}`} sx={classes.bulletText}>{bullet}</ListItem>
                        </Box>
                    })}
                </List>
            } else {
                return <></>
            }
        })

        return <>
            <Typography sx={classes.paragraph} variant="body1" component="p">
                {boldedTexts}
            </Typography>
            <Box mb='20px'>
                {bulletList}
            </Box>
        </>
    }

    useEffect(() => {
        let expandableOptions: { id: number, isOpen: boolean }[] = []

        for (let i = 0; i < props.coverage.list.length; i++) {
            const element = props.coverage.list[i]

            for (let j = 0; j < element.dropDown.length; j++) {
                expandableOptions.push({
                    id: element.dropDown[j].id,
                    isOpen: false
                })
            }
        }

        setIsOpen(expandableOptions)
    }, [])


    return <Box sx={classes.sectionContainer} ref={ref}>
        <Typography sx={classes.sectionTitle}>
            {props.coverage.question}
        </Typography>
        {
            props.coverage.paragraphs.map((paragraph) => {
                return createParagraph(paragraph)
            })
        }
        <Box sx={classes.expandableSection}>
            {
                props.coverage.list.map((option) => {
                    return <Box>
                        {option.label ? <Typography sx={classes.label}>{option.label}</Typography> : <></>}
                        {
                            option.dropDown.map((dropDown) => {
                                return <Box sx={{ ...classes.coverageSection, }}>
                                    <ListItem sx={classes.listItem} key={dropDown.title} onClick={() => handleClick(dropDown.id)}>
                                        <Typography sx={classes.listItemTitle}>{dropDown.title}</Typography>
                                        {isOpen.find((obj) => obj.id === dropDown.id)?.isOpen ? <RemoveIcon /> : <AddIcon />}
                                    </ListItem>
                                    <Collapse
                                        in={isOpen.find((obj) => obj.id === dropDown.id)?.isOpen}
                                        timeout='auto'
                                        unmountOnExit
                                    >
                                        <List component='li' disablePadding >
                                            {
                                                dropDown.paragraphs.map((paragraph) => {
                                                    return createParagraph(paragraph)
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                    {isMobile ? <></> : <Divider />}
                                </Box>
                            })
                        }
                    </Box>
                })
            }
        </Box>
        {
            props.coverage.extraParagraphs?.map((paragraph) => {
                return createParagraph(paragraph)
            })
        }
    </Box>
})

export default InsuranceCoverage