import { Typography, Box, List, ListItem, Grid } from "@mui/material"
import { Link } from 'react-router-dom'
import classes from "../styles/styles"

const AdvisoryMenu = () => {
  return (
    <>
      <Box sx={classes.extendedMenu}>
        <Grid container spacing={1}>
          <Grid item xs={6} sx={classes.rightBorder}>
            <Link to={`/advisory?section=0`} style={{ textDecoration: 'none' }}>
              <Typography sx={classes.listTitle}>Accounting</Typography>
            </Link>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <List>
                <ListItem>
                  <Link to={`/advisory?section=1`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>Bookkeeping</Typography>
                  </Link>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Link to={`/advisory?section=2`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>CPA</Typography>
                  </Link>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Link to={`/advisory?section=3`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>Tax Advisory</Typography>
                  </Link>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Link to={`/advisory?section=4`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>Tax Filing</Typography>
                  </Link>
                </ListItem>
              </List>
              <List sx={{ marginRight: "70px" }}>
                <ListItem>
                  <Link to={`/advisory?section=5`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>CFO services</Typography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Link to={`/advisory?section=6`} style={{ textDecoration: 'none' }}>
              <Typography sx={classes.listTitle}>Business Management</Typography>
            </Link>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <List>
                <ListItem>
                  <Link to={`/advisory?section=7`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>
                      Implementation
                    </Typography>
                  </Link>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Link to={`/advisory?section=8`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>Operations</Typography>
                  </Link>
                </ListItem>
              </List>
              <List>
                <ListItem>
                  <Link to={`/advisory?section=9`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>
                      Corporate Governance
                    </Typography>
                  </Link>
                </ListItem>
              </List>
              <List sx={{ marginRight: "70px" }}>
                <ListItem>
                  <Link to={`/advisory?section=10`} style={{ textDecoration: 'none' }}>
                    <Typography sx={classes.listText}>
                      Strategy Consulting
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default AdvisoryMenu
