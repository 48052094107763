import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Collapse, Typography } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import IndustriesMenu from "./IndustriesMenu";

interface Props {
  openIndustries: boolean;
  setOpenIndustries: (value: boolean) => void;
  setOpenInsurance: (value: boolean) => void;
  setOpenAdvisory: (value: boolean) => void;
  setOpenHamburgerMenu: (value: boolean) => void;
}
export const IndustriesMenuMobile = ({
  openIndustries,
  setOpenIndustries,
  setOpenInsurance,
  setOpenAdvisory,
  setOpenHamburgerMenu,
}: Props) => {
  return (
    <List>
      <ListItem
        onClick={() => {
          setOpenIndustries(!openIndustries);
          setOpenInsurance(false);
          setOpenAdvisory(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: openIndustries ? "#69B3C4" : "black",
              fontSize: "22px",
            }}
          >
            Industries
          </Typography>

          {openIndustries ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItem>

      <Collapse in={openIndustries} timeout="auto" unmountOnExit>
        <IndustriesMenu setOpenHamburgerMenu={setOpenHamburgerMenu} />
      </Collapse>
    </List>
  );
};
