import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const InsuranceButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#109CF1",
  height: "54px",
  width: "477px",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#3567C8",
  },
}));
export default InsuranceButton;
