import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Alert, Link as MaterialLink } from "@mui/material";
import { Box, Typography, InputAdornment, IconButton } from "@mui/material";
import StyledTextField from "../styled/StyledTextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InsuranceButton from "../styled/InsuranceButton";
import http from "src/services/http";
import { Message, RequestOptions } from "src/data/interfaces/index";

import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import AuthProvider from "./components/AuthProvider";

const style = {
  typography: {
    fontWeight: "regular", 
    fontSize: "18px", 
    marginBottom: "5px",
    color: "#888",
    marginLeft: "32px",
    marginTop: "15px"
  }
}

interface Token {
  username: string;
  password: string;
}

interface Props {
  handleClose(): void;
  nextStep(): void;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login = (props: Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState({
    email: false,
    password: false,
  });
  const [touched, setTouched] = useState({
    email: false,
    password: false,
  });
  const [message, setMessage] = useState<Message>({
    show: false,
    text: "",
    severity: null, // error, warning, info, success
  });

  const navigate = useNavigate();

  const emailRegex: RegExp =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegex =
    /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*(.)\1\1)(?!.*\d{5})(?!.*(password|admin|user|123456|qwerty|letmein|monkey|dragon|baseball|football)).{8,}$/;

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleInvalid = (): boolean => {
    if (!validated.email || !validated.password) {
      setMessage({
        show: true,
        text: "Fill in the fields",
        severity: "warning",
      });
      setTimeout(() => {
        setMessage({
          show: false,
          text: "",
          severity: null,
        });
        setValidated({
          email: false,
          password: false,
        });
      }, 5000);
    }
    return !validated.email || !validated.password;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setMessage({ ...message, show: false });

    setUser({
      ...user,
      [e.target.id]: e.target.value,
    });

    setTouched({
      ...touched,
      [e.target.id]: e.target.value.length === 0 ? false : true,
    });

    switch (e.target.id) {
      case "email":
        return setValidated({
          ...validated,
          [e.target.id]: emailRegex.test(e.target.value),
        });
      case "password":
        return setValidated({
          ...validated,
          [e.target.id]: passwordRegex.test(e.target.value),
        });
    }
  };

  const handleLogin = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    if (handleInvalid()) return;

    const payload: Token = {
      username: user.email,
      password: user.password,
    };

    const tokenOptions: RequestOptions = {
      contentType: "application/x-www-form-urlencoded",
    };

    http
      .post("/api/v1/user/token", payload, tokenOptions)
      .then((response: any) => {
        const loginOptions: RequestOptions = {
          token: response.data.access_token,
        };
        window.localStorage.setItem("token", response.data.access_token);
        http
          .get("/api/v1/user/login", {}, loginOptions)
          .then(() => {
            props.setIsLoggedIn(true);
            setMessage({
              show: true,
              text: "Login successfull!",
              severity: "success",
            });
            setTimeout(() => {
              setMessage({
                show: false,
                text: "",
                severity: null,
              });
              setValidated({
                email: false,
                password: false,
              });
              setTouched({
                email: false,
                password: false,
              });
              props.nextStep();
              props.handleClose();
              navigate("/dashboard");
            }, 3000);
          })
          .catch((_) => {
            setMessage({
              show: true,
              text: "There was an error, please try again",
              severity: "error",
            });
          });
      })
      .catch((_) => {
        setMessage({
          show: true,
          text: "There was an error, please try again",
          severity: "error",
        });
      });
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "477px",
          width: "100%",
          height: "180px",
        }}
      >
        <Typography sx={{
          fontWeight: "regular", 
          fontSize: "18px", 
          marginBottom: "5px",
          color: "#888",
          marginLeft: "32px",
        }}>
         Email address
        </Typography>
        <StyledTextField
          fullWidth
          placeholder="Email"
          variant="outlined"
          inputProps={{
            autoComplete: "new-password",
          }}
          InputProps={{
            style: {
              marginLeft: "32px",
              borderRadius: "5px",
            }
          }}
          id="email"
          onChange={handleChange}
          error={!validated.email && touched.email}
          helperText={
            !validated.email && touched.email
              ? "Please enter a valid email adress"
              : ""
          }
        />
        <Typography sx={style.typography}>
         Password
        </Typography>
        <StyledTextField
          type={showPassword ? "text" : "password"}
          fullWidth
          placeholder="Password"
          variant="outlined"
          inputProps={{
            autoComplete: "new-password",
          }}
          id="password"
          onChange={handleChange}
          error={!validated.password && touched.password}
          helperText={
            !validated.password && touched.password
              ? "Password must contain at least 8 characters and 1 special character"
              : ""
          }
          InputProps={{
            style: {
              marginLeft: "32px",
              borderRadius: "5px",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
        <MaterialLink
          to=""
          component={RouterLink}
          variant="body2"
          underline="none"
          sx={{ color: "#22284c", mb: "0.8em" }}
        >
          <Typography
          sx={{
            color: "#4299E1",
            fontSize: "20px",
            marginTop: "5px"
          }}>
            Forgot password?
            </Typography>
        </MaterialLink>
      </Box>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <InsuranceButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleLogin}
          sx={{ width: "400px", borderRadius: "20px", marginLeft: "32px", height: "50px" }}
        >
          <Typography>Log In</Typography>
        </InsuranceButton>
      </Box>

      {message.show ? (
        <Alert
          variant="outlined"
          sx={{ margin: "0.8em 0", width: "80%" }}
          severity={message.severity}
        >
          {message.text}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};
export default Login;
