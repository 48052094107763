import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "src/assets/styles/theme";
import AuthDialog from "./AuthDialog";
import successVerificationEmail from "../../assets/icons/successVerificationEmail.svg";
import expiredVerificationEmail from "../../assets/icons/expiredVerificationEmail.svg";

const style = {
  box: {
    display: "flex",
    width: "100vw",
    alignItems: "center",
    justifyContent: "end",
    flexDirection: "row",
    mt: "25px",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  messageSuccess: {
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    color: "#252525",
    mb: "30px",
  },
  messageError: {
    fontWeight: "normal",
    fontSize: "20px",
    textAlign: "center",
    color: "#252525",
    mb: "30px",
    mx: { xs: "40px", md: "0px" },
  },
  middleText: {
    fontWeight: "bold",
    color: "#000000",
  },
  button: {
    textTransform: "none",
    width: "50%",
    borderRadius: "20px",
    fontWeight: "bold",
    fontSize: "18px",
    mt: "46px",
    mb: "36px",
  },
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isOpenSuccessVerificationDialog: boolean;
  isLoginBtnCliked: boolean;
  name: string;
}

const ResponseVerificationEmailDialog = ({
  isOpen,
  handleClose,
  isOpenSuccessVerificationDialog,
  isLoginBtnCliked,
  name,
}: Props): JSX.Element => {
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const openRegistrationDialog = () => {
    setOpenAuthDialog(true);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 30,
            width: isMobile ? "100vw" : "463px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            paddingRight: isMobile ? "0px" : "32px",
            paddingLeft: isMobile ? "0px" : "32px",
          },
        }}
        fullScreen={isMobile}
        open={isOpen}
        onClose={handleClose}
      >
        {isMobile && (
          <Box sx={style.box}>
            <IconButton
              aria-label="Close welcome message window"
              onClick={() => handleClose()}
            >
              <CloseIcon
                sx={{
                  color: "black",
                  fontSize: "35px",
                  mt: "5px",
                  mr: "15px",
                }}
              />
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          <Box
            component="img"
            alt={
              isOpenSuccessVerificationDialog
                ? "SuccessVerificationEmail"
                : "ExpiredVerificationEmail"
            }
            src={
              isOpenSuccessVerificationDialog
                ? successVerificationEmail
                : expiredVerificationEmail
            }
            sx={{
              width: "128px",
              height: "128px",
              mt: isMobile ? "120px" : "70px",
              mb: "24px",
            }}
          />
        </Box>
        <DialogTitle sx={style.dialogTitle}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "24px",
              textAlign: "center",
              color: isOpenSuccessVerificationDialog ? "#4EAE6B" : "#BA3D3D",
            }}
          >
            {isOpenSuccessVerificationDialog
              ? "Your account has been verified."
              : "Verification link expired."}
          </Typography>
        </DialogTitle>
        {isOpenSuccessVerificationDialog ? (
          <Typography sx={style.messageSuccess}>
            Welcome to Klout {name}!
          </Typography>
        ) : (
          <Typography sx={style.messageError}>
            When you registered for Klout you received an email with
            confirmation link that expires{" "}
            <span style={style.middleText}>after 24 hours</span>. We take
            security seriously - sometimes that means taking extra precautions.
            Please, sign up again.
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={openRegistrationDialog}
          sx={style.button}
        >
          {isOpenSuccessVerificationDialog ? "Login" : "Sign Up"}
        </Button>
      </Dialog>
      <Box>
        {openAuthDialog && (
          <AuthDialog
            isOpen={openAuthDialog}
            isLoginBtnCliked={isLoginBtnCliked}
            handleClose={() => setOpenAuthDialog(false)}
            nextStep={() => {}}
            setIsLoggedIn={() => {}}
          />
        )}
      </Box>
    </>
  );
};
export default ResponseVerificationEmailDialog;
