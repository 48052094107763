import general_liability from "../../assets/icons/general_liability.svg";
import commercial_auto from "../../assets/icons/commercial_auto.svg";
import cyber_liability from "../../assets/icons/cyber_liability.svg";
import event_cancellation from "../../assets/icons/event_cancellation.svg";
import liquor_liability from "../../assets/icons/liquor_liability.svg";
import workers_compensation from "../../assets/icons/workers_compensation.svg";
import event_liability from "../../assets/icons/event_liability.svg";
import property_coverage from "../../assets/icons/property_coverage.svg";
import inland_marine from "../../assets/icons/inland_marine.svg";
import professional_liability from "../../assets/icons/professional_liability.svg";
import umbrella_liability from "../../assets/icons/umbrella_liability.svg";
import trade_credit from "../../assets/icons/trade_credit.svg";
import watercraft_insurance from "../../assets/icons/watercraft.svg";
import aircraft_insurance from "../../assets/icons/aircraft.svg";
import automotive_insurance from "../../assets/icons/automotive.svg";
import homeowners_insurance from "../../assets/icons/homeowners.svg";

import contract from "../../assets/icons/contract.svg";
import gavel from "../../assets/icons/gavel.svg";
import requirementsByState from "../../assets/icons/requirements_by_state.svg";
import autoPersonalVsCommercial from "../../assets/icons/auto_personal_vs_commercial.svg";
import selfEmployedBusinessOwner from "../../assets/icons/self_employed_business_owner.svg";
import workersCompRequired from "../../assets/icons/workers_compensation_required_by_law.svg";
import retailersWholesalers from "../../assets/icons/retailers_wholesalers_and_manufacturers.svg";
import itTech from "../../assets/icons/it_and_technology.svg";
import realEstate from "../../assets/icons/real_estate.svg";
import construction from "../../assets/icons/construction.svg";
import accountantsAuditors from "../../assets/icons/accountants_and_auditors.svg";
import architects from "../../assets/icons/architect.svg";
import consultants from "../../assets/icons/consultant.svg";
import engineers from "../../assets/icons/engineer.svg";
import healthcare from "../../assets/icons/healthcare.svg";
import privateAircraftOwners from "../../assets/icons/private_aircraft_owners_-a.svg";
import pilots from "../../assets/icons/pilots_-a.svg";
import charterCompanies from "../../assets/icons/charter_companies_-a.svg";
import flightSchools from "../../assets/icons/flight_school_-a.svg";
import aircraftLenders from "../../assets/icons/aircraft_lenders_-a.svg";
import watercraftOwners from "../../assets/icons/watercraft_owners_-_w.svg";
import renters from "../../assets/icons/renters_-_w.svg";
import operators from "../../assets/icons/operators_-_w.svg";
import charterCompaniesW from "../../assets/icons/charter_companies_-_w.svg";
import highNetWorthIndividuals from "../../assets/icons/high_net_worth_individuals_-_u.svg";
import businessOwners from "../../assets/icons/business_owners_-_u.svg";
import homeownersIcon from "../../assets/icons/homeowners_-_u.svg";
import drivers from "../../assets/icons/driver_-u.svg";
import landlords from "../../assets/icons/landlord_-u.svg";

export interface WhatIsIt {
  question: string;
  paragraphs: { text: string; isBold: boolean; bulletList?: string[] }[][];
  graphic: any;
}

export interface Coverage {
  question: string;
  paragraphs: { text: string; isBold: boolean; bulletList?: string[] }[][];
  list: {
    label?: string;
    isTwoColumns?: boolean;
    dropDown: {
      title: string;
      id: number;
      paragraphs: { text: string; isBold: boolean; bulletList?: string[] }[][];
    }[];
  }[];
  extraParagraphs?: {
    text: string;
    isBold: boolean;
    bulletList?: string[];
  }[][];
}

export interface WhoIsItFor {
  question: string;
  paragraphs: { text: string; isBold: boolean; bulletList?: string[] }[][];
  list: {
    label?: string;
    dropDown: {
      title: string;
      id: number;
      graphic: any;
      paragraphs: { text: string; isBold: boolean; bulletList?: string[] }[][];
    }[];
  }[];
  extraParagraphs?: {
    text: string;
    isBold: boolean;
    bulletList?: string[];
  }[][];
}

export interface InsuranceInfo {
  name: string;
  whatIsIt: WhatIsIt;
  coverage: Coverage;
  noCoverage: Coverage;
  whoIsItFor: WhoIsItFor;
}

const generalLiability: InsuranceInfo = {
  name: "General Liability Insurance",
  whatIsIt: {
    question: "What is general liability?",
    paragraphs: [
      [
        {
          text: "General liability insurance covers common business risks like customer injury, customer property damage, and advertising injury. It protects your small business from the high costs of lawsuits and helps you qualify for leases and contracts.",
          isBold: false,
        },
      ],
    ],
    graphic: general_liability,
  },
  coverage: {
    question: "What does general liability cover?",
    paragraphs: [
      [
        {
          text: "General liability insurance ",
          isBold: true,
        },
        {
          text: "provides coverage for common liability claims from third parties (people outside your business).",
          isBold: false,
        },
      ],
      [
        {
          text: "This business insurance policy covers legal defense costs if someone sues over a bodily injury, property damage, or advertising injury. Your policy can pay for everything from hiring a lawyer to court-ordered judgments and settlements.",
          isBold: false,
        },
      ],
      [
        {
          text: "Specifically, your commercial general liability (CGL) policy ",
          isBold: false,
        },
        {
          text: "will cover:",
          isBold: true,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Third-party bodily injuries",
            id: 0,
            paragraphs: [
              [
                {
                  text: "If a customer is hurt in an accident at your business, general liability insurance can help pay for medical expenses. It also covers legal expenses if a customer sues over the injury.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Product liability",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Not all property damage or customer injuries happen inside a store. If a business manufactures, distributes, or sells products, it can be sued over the harm its products cause to people or property.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Most commercial general liability (CGL) policies include product liability insurance. This type of coverage helps pay for lawsuits and other financial losses related to defective products, labeling errors that harm customers, and other product risks.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Personal injuries and advertising injuries",
            id: 2,
            paragraphs: [
              [
                {
                  text: "If someone sues a business owner or employee over slander, libel, false arrest, or copyright infringement, general liability insurance can help pay for legal defense costs and any resulting settlement or judgment.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't general liability cover?",
    paragraphs: [
      [
        {
          text: "General liability insurance doesn’t cover every kind of claim. For some, you’ll need different types of coverage to give your business more protection.",
          isBold: false,
        },
      ],
      [
        {
          text: "A general liability insurance policy ",
          isBold: false,
        },
        {
          text: "will not cover:",
          isBold: true,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title:
              "Commercial auto accidents that you or your employees cause while driving for work.",
            id: 3,
            paragraphs: [
              [
                {
                  text: "A commercial auto insurance policy can help you cover the cost of damages. If your small business doesn’t have company-owned vehicles, hired and non-owned auto coverage could help, too. Many states require certain types and amounts of auto coverage, like bodily injury liability coverage or property damage liability coverage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee injuries or illnesses due to their work.",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Workers’ compensation insurance gives your employees benefits to help them recover from a work-related injury or illness. For example, it can help cover an employee’s medical bills and ongoing care expenses.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Damage to your own business property.",
            id: 5,
            paragraphs: [
              [
                {
                  text: "You’ll need to get a commercial property insurance policy to help protect your owned or rented building and business equipment.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title:
              "Mistakes or errors in the professional services given to customers.",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance can help cover your legal costs if a client sues you for a mistake in the services provided.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Claims that cost more than your liability limits.",
            id: 7,
            paragraphs: [
              [
                {
                  text: "You’ll need a commercial umbrella insurance policy for expensive claims. This extends the limits of certain liability insurance policies.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title:
              "Illegal acts or wrongdoings that you or your employees did purposefully.",
            id: 8,
            paragraphs: [
              [
                {
                  text: "",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need general liability insurance?",
    paragraphs: [
      [
        {
          text: "Most companies need this insurance, ",
          isBold: true,
        },
        {
          text: "especially if you rent or own an office or commercial space. Even if that doesn't apply to you, small businesses that work directly with clients and customers benefit from general liability insurance.",
          isBold: false,
        },
      ],
      [
        {
          text: "This insurance policy can keep your company financially stable if you’re sued by a customer or competitor. ",
          isBold: true,
        },
        {
          text: "Because general liability insurance provides such important coverage, most small business owners buy the policy right after they start their business.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Is general liability insurance required by law?",
            graphic: gavel,
            id: 9,
            paragraphs: [
              [
                {
                  text: "Not always, but ",
                  isBold: false,
                },
                {
                  text: "specific states may mandate it for certain industries ",
                  isBold: true,
                },
                {
                  text: "like construction and cannabis. Depending on your type of business, ",
                  isBold: false,
                },
                {
                  text: "you may also need coverage to get a professional license ",
                  isBold: true,
                },
                {
                  text: "in your state. It is also ",
                  isBold: false,
                },
                {
                  text: "likely required by your landlord, mortgage company, business partners, or clients.",
                  isBold: true,
                },
              ],
              [
                {
                  text: "A landlord or client may ask your business to show an ",
                  isBold: false,
                },
                {
                  text: "ACORD certificate of insurance ",
                  isBold: true,
                },
                {
                  text: "as proof of your general liability insurance coverage. This ",
                  isBold: false,
                },
                {
                  text: "verifies that your business can handle personal injury or property damage lawsuits. ",
                  isBold: true,
                },
                {
                  text: "The certificate also gives your property manager, bank, or customer peace of mind that they won’t be stuck paying the costs of a lawsuit against your company.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title:
              "Do independent contractors need general liability insurance?",
            graphic: contract,
            id: 10,
            paragraphs: [
              [
                {
                  text: "Independent contractors can be sued and held liable for customer injuries, customer property damage, or advertising injuries like any other business owner. ",
                  isBold: true,
                },
                {
                  text: "While contractors who don’t have a physical location or expensive equipment probably don’t need a business owner’s policy or commercial property insurance, there are still benefits from general liability insurance.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If you don’t want to purchase a commercial general liability (CGL) policy, ",
                  isBold: false,
                },
                {
                  text: "you can ask your clients to add you to their policies as an additional insured. ",
                  isBold: true,
                },
                {
                  text: "This will cover you for as long as you work for the client who insures you. Keep in mind that your ",
                  isBold: false,
                },
                {
                  text: "clients might pay a higher insurance premium if they add you ",
                  isBold: true,
                },
                {
                  text: "to their general liability policies, and ",
                  isBold: false,
                },
                {
                  text: "your coverage will end once the contract expires.",
                  isBold: true,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const commercialAuto: InsuranceInfo = {
  name: "Business Auto",
  whatIsIt: {
    question: "What is business auto insurance?",
    paragraphs: [
      [
        {
          text: "Business auto is also referred to as commercial auto insurance, commercial vehicle insurance, commercial car insurance, truck insurance, or fleet insurance—depending on what types of vehicles you need to insure.",
          isBold: false,
        },
      ],
      [
        {
          text: "The coverage itself is pretty self-explanatory; a commercial auto policy protects your company against financial responsibility if you or an employee is at fault in an accident involving injury to people or property. It also protects your vehicles from losses resulting from theft, vandalism, certain weather events, and collisions.",
          isBold: false,
        },
      ],
      [
        {
          text: "Picking the right business auto policy is significantly more complicated than purchasing insurance for your personal vehicles. There are many options & a few key considerations:",
          isBold: false,
          bulletList: [
            "Commercial vehicles are exposed to a greater variety of risk on an everyday basis",
            "The policy protects you and your assets as the business owner",
            "The policy also protects your employees & any other contracted drivers hired for business purposes of any kind",
          ],
        },
      ],
      [
        {
          text: "A majority of U.S. states make it mandatory for business owners to purchase liability insurance for both property damage and bodily injury that could occur as a result of one of your vehicles getting into an accident while you or one of your employees is operating it. You can also get physical damage coverage for all of the vehicles your business uses, whether you own, lease or hire the vehicles.",
          isBold: false,
        },
      ],
    ],
    graphic: commercial_auto,
  },
  coverage: {
    question: "What does business auto cover?",
    paragraphs: [
      [
        {
          text: "Before hopping into what does it cover, let's review who does it cover...",
          isBold: false,
        },
      ],
      [
        {
          text: "There are three categories of people who can be covered by business auto insurance:",
          isBold: false,
          bulletList: [
            "Named Insured: If it’s your business and you’re taking out the policy, then you would be the named insured and you would have the broadest coverage possible. The named insured will usually be covered even if they didn’t participate in an accident directly.",
            "Permissive Users: employees or anyone else you have given permission to drive the vehicle for which you have purchased coverage.",
            "Omnibus Users: Omnibus users are people who can be held liable for any accident or negligence committed by you or your permissive users.",
          ],
        },
      ],
      [
        {
          text: "When it comes to coverage, as we mentioned before, there's the basics & the additions (what are known as 'endorsements').",
          isBold: false,
        },
      ],
      [
        {
          text: "Let's start with the basics:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        label: "Policy Basics",
        dropDown: [
          {
            title: "Property and Bodily Injury Liability",
            id: 0,
            paragraphs: [
              [
                {
                  text: "If you or your drivers are found liable for an accident that led to third-party property damage or bodily injury, you’ll be covered. Your policy will pay repair bills, medical bills, lost wages, legal fees, even funeral expenses in a worst-case scenario.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Medical Payments",
            id: 1,
            paragraphs: [
              [
                {
                  text: "No matter which party was at fault, a commercial auto policy covers medical expenses for you, your drivers, and any passengers that are injured. Many states require that your commercial auto policy covers medical payments.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Collision Coverage",
            id: 2,
            paragraphs: [
              [
                {
                  text: "ll damages to your vehicle will be covered. Collision coverage provides repair costs in both accidents that involve other vehicles and collisions with objects such as trees and street signs, for example.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Gap Insurance",
            id: 3,
            paragraphs: [
              [
                {
                  text: "If you are leasing the vehicle and it is damaged beyond repair, insurance will cover the money that you still owe on it.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
      {
        label: "Common Policy Endorsements",
        dropDown: [
          {
            title: "Comprehensive",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Provides coverage for damage that doesn’t result from a collision. Comprehensive will cover damage from vandalism, fire, inclement weather, theft, and more.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Uninsured Motorist",
            id: 5,
            paragraphs: [
              [
                {
                  text: "If the third party involved in the accident is uninsured, you will still be covered by your policy and receive payment for damages and medical fees.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Hired and Not-Owned Liability",
            id: 6,
            paragraphs: [
              [
                {
                  text: "If your business relies heavily on vehicles that are not owned by the business, this coverage is a must. If you or your drivers are involved in an accident while driving a vehicle that is not yours, this coverage will kick in to cover medical bills and third-party damage. You can add a hired auto physical damage rider if you want damages to the car you were driving to be covered as well.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Roadside Assistance",
            id: 7,
            paragraphs: [
              [
                {
                  text: "You can also add a roadside assistance rider to your commercial auto policy to provide 24/7 coverage whenever you need a tow, fuel, a flat tire fixed, or your car battery jump-started.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
    extraParagraphs: [
      [
        {
          text: "Commonly Covered Vehicles:",
          isBold: false,
          bulletList: [
            "Passenger cars",
            "Vans",
            "Pickups",
            "SUVs",
            "Cement mixers",
            "Delivery Vans",
            "Tractor Trailers",
            "Flatbeds",
            "Cranes",
          ],
        },
      ],
      [
        {
          text: "Looking to insure trucks? It would be best to purchase commercial truck insurance, which is specifically designed to provide coverage for larger vehicles and the risk profile that comes with operating those types of vehicles commercially.",
          isBold: false,
        },
      ],
    ],
  },
  noCoverage: {
    question: "What doesn't business auto cover?",
    paragraphs: [
      [
        {
          text: "As is with most policies, there are exclusions when it comes to business auto insurance for risks that are either uninsurable or covered by other types of policies.",
          isBold: false,
        },
      ],
      [
        {
          text: "Here is a rundown of common exclusions:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Contractual Liability",
            id: 8,
            paragraphs: [
              [
                {
                  text: "Any liability that you assume under some type of business contract.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employer's Liability",
            id: 9,
            paragraphs: [
              [
                {
                  text: "Claims made against you by injured employees. These types of claims are covered by employment practices liability insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Expected or Intended Injury",
            id: 10,
            paragraphs: [
              [
                {
                  text: "Bodily injury or property damage that was expected or intended from the standpoint of the insured. This usually applies when the insured named is involved in a claim for intentionally causing bodily injury or property damage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Fellow Employee Injuries",
            id: 11,
            paragraphs: [
              [
                {
                  text: "Claims by one employee against another for an injury sustained on the job.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Handling of Property",
            id: 12,
            paragraphs: [
              [
                {
                  text: "Claims related to injuries or damage that occur before you have begun loading property onto a vehicle. Commercial auto insurance will cover injuries or damages incurred during the process of loading property in and out of the vehicle, however.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Movement of Property",
            id: 13,
            paragraphs: [
              [
                {
                  text: "Unless it’s a hand truck or a device that is attached to your vehicle, auto insurance will not cover injury or damage that results from the movement of property by a mechanical device.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Operation of Mobile Equipment",
            id: 14,
            paragraphs: [
              [
                {
                  text: "Injuries or damages that occurred on any mobile equipment that is not covered by the commercial auto policy (such as a forklift).",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Pollution",
            id: 15,
            paragraphs: [
              [
                {
                  text: "Injuries resulting from automotive pollution are not covered.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need business auto?",
    paragraphs: [
      [
        {
          text: "If the vehicle in question is primarily used for business purposes, you should get commercial auto insurance coverage, since most personal auto policies – whether yours or that of one of your employees – do not provide coverage for business-related accidents and incidents.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Requirements by State",
            id: 16,
            graphic: requirementsByState,
            paragraphs: [
              [
                {
                  text: "When purchasing commercial auto insurance, it’s important to note that the amount of insurance you are going to have to purchase will be defined by either state or federal laws. Each state has its own set of laws regarding insurance requirements related to characteristics of your fleet such as the weight of each vehicle and the number of passengers that each vehicle can accommodate.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Personal vs. Commercial",
            id: 17,
            graphic: autoPersonalVsCommercial,
            paragraphs: [
              [
                {
                  text: "If a small business owner or one of their employees uses a personal vehicle to perform business tasks such as picking up clients or delivering goods, it’s important to know that their personal auto insurance will not cover any damages if an accident occurs while they were driving the vehicle for business reasons.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Personal auto insurance policies almost always exclude business use. If you’re a sole proprietor and you are using your personal vehicle for business on a regular basis, the best course of action would be to purchase a commercial auto policy as well.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const cyberLiability: InsuranceInfo = {
  name: "Cyber liability",
  whatIsIt: {
    question: "What is cyber liability?",
    paragraphs: [
      [
        {
          text: "A cyber insurance policy, also referred to as “cyber risk insurance” or “cyber liability insurance” coverage, is a financial product that enables businesses to transfer the costs involved with recovery from a cyber-related security breach or similar events.",
          isBold: false,
        },
      ],
      [
        {
          text: "Typically, the most important aspect of cyber insurance will be network security coverage. This coverage will respond in the event of a network security failure – such as data breaches, malware, ransomware attacks and business account, and email compromises. However, the policy will also respond to liability claims and ancillary expenses of an attack or breach.",
          isBold: false,
        },
      ],
    ],
    graphic: cyber_liability,
  },
  coverage: {
    question: "What does cyber liability cover?",
    paragraphs: [
      [
        {
          text: "Cyber insurance is as dynamic as the companies it protects and is consequently far from standardized. However, some of the issues that cyber liability insurance typically covers include:",
          isBold: false,
          bulletList: [
            "Data loss, recovery, and recreation",
            "Business interruption/ loss of revenue due to a breach",
            "Loss of transferred funds",
            "Computer fraud",
            "Cyber extortion",
          ],
        },
      ],
      [
        {
          text: "Should hackers expose or steal personal information, such as Social Security numbers, driver’s’ license number (in some states), addresses, and bank account information, a cyber liability insurance policy would cover:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Notification Costs",
            id: 1,
            paragraphs: [
              [
                {
                  text: "This expense is significant because the company bears the burden of both identifying potential victims, which requires an internal investigation, and providing notification that’s reasonably calculated to give actual notice.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Credit Monitoring",
            id: 2,
            paragraphs: [
              [
                {
                  text: "In effect, your cyber insurance policy pays for victims’ insurance policies. Regulators usually dictate the kind of credit monitoring to provide and it’s a safe bet they will not be satisfied with the cheapest available protection.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Civil Damages",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Most of these liability lawsuits are class actions, with hundreds of thousands of dollars in damages at a minimum, even for a very small company.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Computer Forensics",
            id: 4,
            paragraphs: [
              [
                {
                  text: "This covers costs to hire computer forensics consultants working under the direction of your attorneys to determine whether a data breach occurred, to contain and prevent further damage, and to investigate the cause and scope of the breach.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Reputational Damage",
            id: 5,
            paragraphs: [
              [
                {
                  text: "Data breaches can have profound PR implications for any business. A preferred policy will help you handle the potential fallout by covering the damages stemming from brand aversion due to a cyber incident for a certain amount of time after the breach. It can also help mitigate the potential cost by paying for PR management experts.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't cyber libility cover?",
    paragraphs: [
      [
        {
          text: "Like most coverages, there are certain exclusions that a cyber policy usually will not cover - for example, the policy will not respond if you are sued for any potential vulnerabilities in your systems before a breach occurs.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        label: "What else does Cyber Liability not cover?",
        dropDown: [
          {
            title: "Cyber-Attack or Data Breach",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Cyber insurance policies will typically not reimburse you for future profits lost due to a cyber-attack or data breach.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If you have concerns about losses due to theft of your intellectual property, you’ll have to look towards a specifically tailored intellectual property insurance policy. In addition, allegations that the policy holder’s patents infringe upon those of a third party will also not be afforded coverage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Act of War",
            id: 7,
            paragraphs: [
              [
                {
                  text: "If an agent of a foreign power causes the breach, the coverage can be denied under the acts of war exclusion.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Additionally, the cost to improve your security and technology systems after an attack will not be included in most policies.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Social Engineering",
            id: 8,
            paragraphs: [
              [
                {
                  text: "Social engineering refers to attacks that rely on psychological manipulation to gain access to sensitive information or funds. Victims following instructions from fraudulent emails or calls is not considered a computer system breach.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Therefore, a special policy social engineering extension needs to be added to the cyber insurance.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need it?",
    paragraphs: [
      [
        {
          text: "Many traditional insurance policies, most notably general liability insurance (CGL), weren’t designed with cyber risks in mind.",
          isBold: false,
        },
      ],
      [
        {
          text: "This means that they don’t have precise language either implicitly including or excluding cyber exposures. However, in practice this means that CGL policies generally won’t cover cyber liability, and if they do the coverage will be minimal at best.",
          isBold: false,
        },
      ],
      [
        {
          text: "Does your business store customer data such as names, addresses, credit card information, Social Security numbers, and more, on any type of computer system on or offline?",
          isBold: false,
        },
      ],
      [
        {
          text: "",
          isBold: false,
          bulletList: [
            "Then there is a regulatory obligation to keep that data secure, and therefore, a higher price tag in the event of a breach.",
          ],
        },
      ],
    ],
    list: [],
  },
};

const eventCancellation: InsuranceInfo = {
  name: "Event cancellation",
  whatIsIt: {
    question: "What is event cancellation?",
    paragraphs: [
      [
        {
          text: "Event cancellation insurance protects event revenues and expenses against the risks of cancellation, postponement, curtailment, relocation or abandonment of the event for reasons beyond the control of the event organizer.",
          isBold: false,
        },
      ],
      [
        {
          text: "What has the biggest impact on cost and coverage?",
          isBold: false,
          bulletList: [
            "Event Type (athletic event, concert, fair/festival, conference, consumer show, etc.)",
            "Amount of Revenue or Expense relating to event",
            "Dates of the event",
            "Location of the event",
            "Whether the event has an outdoor exposure or non‐appearance component",
          ],
        },
      ],
    ],
    graphic: event_cancellation,
  },
  coverage: {
    question: "What does event cancellation cover?",
    paragraphs: [
      [
        {
          text: "Event cancellation due to:",
          isBold: false,
          bulletList: [
            "Property damage and money cover at no additional cost",
            "Additional bespoke offerings provided at request",
            "Adverse weather cover",
            "Terrorism & TRIA",
            "Enforced reduced attendance",
            "Civil commotion",
            "National mourning",
            "Failure to vacate",
            "Extra expense to mitigate a loss",
            "Active shooter",
            "Earthquake",
            "Windstorm",
          ],
        },
      ],
      [
        {
          text: "Coverage for almost any event, including:",
          isBold: false,
          bulletList: [
            "Music, cultural, food and literature festivals",
            "Carnivals, parades and fun days, fairs",
            "Conferences, exhibitions and trade shows",
            "Parties, banquets, dinners and dances",
            "Concerts, shows and theater productions",
            "Triathlons, marathons and fun runs",
            "Weddings and birthday parties",
            "Sporting events and much more",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't event cancellation cover?",
    paragraphs: [
      [
        {
          text: "Like almost all policies, Event Cancellation insurance does have exclusions. Below are some of the key policy exclusions:",
          isBold: false,
          bulletList: [
            "Financial Insolvency",
            "Lack of Interest or Support- Fear of Travel",
            "War and Military Action",
            "Biological, Chemical, or Nuclear Hazards",
            "Pollution or Contamination",
            "Pre‐existing existing or threatened circumstances",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need event cancellation?",
    paragraphs: [
      [
        {
          text: "Even the best planned event can face circumstances beyond its control. These unforeseen and uncontrollable circumstances can force cancellation, abandonment, postponement, interruption or relocation of the event.",
          isBold: false,
        },
      ],
      [
        {
          text: "There are a variety of instances that could jeopardize an event and result in an event cancellation insurance claim.",
          isBold: false,
        },
      ],
      [
        {
          text: "Event cancellation insurance is right for you if you're hosting an event and want to:",
          isBold: false,
          bulletList: [
            "Protect a significant revenue stream",
            "Protect the visibility of your organization",
            "Ensure the continuity of your event, year after year",
            "Ensure the ability to provide refunds to your guests, should the event be cancelled",
          ],
        },
      ],
    ],
    list: [],
  },
};

const liquorLiability: InsuranceInfo = {
  name: "Liquor Liability",
  whatIsIt: {
    question: "What is liquor liability?",
    paragraphs: [
      [
        {
          text: "Liquor liability insurance helps protect businesses that sell, serve or distribute alcohol. This type of business insurance can help cover claims of bodily injury or property damage that an intoxicated customer causes after a company served them alcohol.",
          isBold: false,
        },
      ],
      [
        {
          text: "You can get this as a standalone policy or add it as an endorsement to general liability insurance coverage.",
          isBold: false,
        },
      ],
    ],
    graphic: liquor_liability,
  },
  coverage: {
    question: "What does liquor liability cover?",
    paragraphs: [
      [
        {
          text: "If your business gets sued, liquor liability insurance helps protect your business by covering your:",
          isBold: false,
          bulletList: [
            "Legal costs",
            "Settlements or judgments",
            "Repairs costs to fix property damage",
            "Medical bills to treat an injury",
          ],
        },
      ],
    ],
    list: [
      {
        label: "Liquor liability can cover claims such as:",
        dropDown: [
          {
            title: "Assault & Battery",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Coverage in the case a customer that your business served alcohol to physically hurts another person.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Drunk Driving",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Coverage in the case an intoxicated person your business served or sold alcohol to damages property or causes an accident that leaves another driver with a bodily injury.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Property Damage",
            id: 2,
            paragraphs: [
              [
                {
                  text: "Coverage should a customer under the influence of alcohol cause damage to another person’s belongings.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't liquor liability cover?",
    paragraphs: [
      [
        {
          text: "There are exclusions that a liquor liability policy will not cover, such as:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Libel & Slander",
            id: 4,
            paragraphs: [
              [
                {
                  text: "For claims of libel and slander, general liability insurance can provide protection, as long as the policy doesn’t have an exemption for alcohol-related claims.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Damage to Business Property",
            id: 5,
            paragraphs: [
              [
                {
                  text: "Liquor liability insurance does not cover damage to your business property, even if it is caused by an intoxicated customer. Commercial property insurance can help pay for the cost of repairing or replacing your business property when it’s damaged or stolen.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Incidents Related to Underage Drinking",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Though incidents related to underage drinking cannot be protected against with insurance, you may have legal recourse if the minor had a realistic-looking fake ID. Train your employees to card every individual and be on the lookout for fake IDs.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need liquor liability?",
    paragraphs: [
      [
        {
          text: "Any business that provides alcoholic beverages in one way or another should be protected by liquor liability coverage as an add-on to its commercial general liability insurance or other policy.",
          isBold: false,
        },
      ],
      [
        {
          text: "Businesses commonly needing liquor liability coverage:",
          isBold: false,
          bulletList: [
            "Restaurants",
            "Liquor stores",
            "Bars",
            "Convenience stores",
            "Breweries",
            "Wineries",
            "Grocery stores",
          ],
        },
      ],
      [
        {
          text: "In the case of liquor liability, the size of the business doesn’t matter. Even a small restaurant or neighborhood grocer can be sued for things like serving minors or providing alcohol to customers who are visibly intoxicated and subsequently get into a car accident.",
          isBold: false,
        },
      ],
      [
        {
          text: "Similarly, a business would need liquor liability insurance for a wedding or other event, even if it’s only a one-off situation. For any event, in order to serve alcohol, liquor liability coverage is vital.",
          isBold: false,
        },
      ],
    ],
    list: [],
  },
};

const workersCompensation: InsuranceInfo = {
  name: "Workers Compensation",
  whatIsIt: {
    question: "What is workers compensation?",
    paragraphs: [
      [
        {
          text: "Workers’ compensation insurance covers medical costs and lost wages for work-related injuries and illnesses. This policy is required in almost every state for businesses that have employees",
          isBold: false,
        },
      ],
      [
        {
          text: "Even when not required by law, this policy provides important protection against medical expenses and employee lawsuits related to workplace injuries, such as a slip and fall in the office, an injury from tools or machinery, or an occupational illness.",
          isBold: false,
        },
      ],
      [
        {
          text: "You can also rely on workers’ comp if you or an employee needs medical treatment or time off due to a workplace injury – or if an injured employee sues you for failing to prevent an accident.",
          isBold: false,
        },
      ],
      [
        {
          text: "If you don’t carry workers’ comp insurance, your business will be responsible for any medical bills and legal fees. And most states levy costly penalties for noncompliance.",
          isBold: false,
        },
      ],
    ],
    graphic: workers_compensation,
  },
  coverage: {
    question: "What does workers compensation cover?",
    paragraphs: [
      [
        {
          text: "What is covered under a workers' compensation policy?",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Medical Expenses",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Workers’ compensation insurance covers the cost of immediate medical care for workplace accidents, such as ambulance rides, emergency room visits, surgical procedures, and other medical bills. Ongoing care, such as medication and physical rehabilitation, is also covered.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Disability Benefits",
            id: 1,
            paragraphs: [
              [
                {
                  text: "A serious injury can prevent an employee from returning to work for days, weeks, or even months. Workers’ comp benefits cover part of the wages lost while an employee is recovering from a workplace injury or occupational illness.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Compensation for Fatal Injuries",
            id: 2,
            paragraphs: [
              [
                {
                  text: "When a work-related incident is fatal, workers’ compensation pays death benefits that cover funeral expenses and help support the deceased individual’s family members.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Lawsuits Related to Workplace Injuries",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Workers’ compensation insurance typically includes employer’s liability insurance. This type of insurance protects employers from lawsuits related to work injuries.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For instance, a worker might claim that a lack of basic workplace safety led to their injury. If the worker sues their employer, this coverage would pay for:",
                  isBold: false,
                  bulletList: [
                    "Attorney’s fees",
                    "Court costs",
                    "Settlements or judgments",
                  ],
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn’t workers compensation cover?",
    paragraphs: [
      [
        {
          text: "",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title:
              "Injuries caused by intoxication, drugs or company policy violations",
            id: 4,
            paragraphs: [
              [
                {
                  text: "If an employee is under the influence of alcohol or drugs and gets injured due to intoxication, workers' compensation benefits would not apply.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "In addition, if an employee does not follow company policies, such as wearing a hard hat when working with heavy equipment, and hurts themselves, they are not entitled to workers' compensation benefits.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Injuries claimed after firing or layoff",
            id: 5,
            paragraphs: [
              [
                {
                  text: "If an employee gets injured or makes claims of injury after experiencing a termination (including being fired or laid off), they are not eligible for workers' compensation benefits. A worker must be an active employee at a company in order to receive workers' compensation coverage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Wages for a replacement worker",
            id: 6,
            paragraphs: [
              [
                {
                  text: "If your employee is unable to work due to injury and you have to bring on a replacement worker, workers' compensation would not cover the replacement worker's salary. The injured employee would still be entitled to wage loss benefits.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "OSHA fines",
            id: 7,
            paragraphs: [
              [
                {
                  text: "OSHA requires workplaces that operate heavy machinery, such as manufacturers, to follow set safety guidelines. In the event that an employee gets injured for not following OSHA safety procedures, the company may receive non-compliance fines that would not be covered by workers' comp.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need workers compensation?",
    paragraphs: [
      [
        {
          text: "While workers’ comp laws vary by state, small businesses typically need a policy in place as soon as they hire their first employee (or a certain number of workers).",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Is workers’ compensation insurance required by law?",
            id: 8,
            graphic: workersCompRequired,
            paragraphs: [
              [
                {
                  text: "Each state has unique workers' compensation laws and penalties. In most states, workers' comp is required as soon as a business hires its first employee. Other states don’t mandate coverage until a business has two, three, four, or more employees.",
                  isBold: false,
                  bulletList: [
                    "Texas and South Dakota are the only states where business owners are never required to purchase workers’ compensation insurance coverage.",
                    "All other states impose penalties for not carrying workers’ compensation. These can range from fines to jail time – or both.",
                    "Most states allow business owners to buy workers’ compensation insurance from private insurers or use self-insurance plans.",
                    "North Dakota, Ohio, Washington, and Wyoming require employers to purchase workers’ comp insurance through a state fund.",
                  ],
                },
              ],
            ],
          },
          {
            title:
              "Do self-employed business owners and independent contractors need workers’ comp insurance?",
            id: 9,
            graphic: selfEmployedBusinessOwner,
            paragraphs: [
              [
                {
                  text: "Typically not by law. States generally require businesses with employees to purchase workers' compensation insurance.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Sole proprietors, independent contractors, and other self-employed business owners may buy this policy to fulfill the terms of a contract. Clients who don’t want to deal with the expense and hassle of a workplace injury might require contractors who work for them to carry their own business insurance, including workers' compensation.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Workers' compensation also helps to protect your income, as most health insurance policies exclude coverage for work-related injuries and illnesses. As an independent contractor with workers compensation insurance, your medical bills will be covered when you’re injured on the job and the insurance can also partially replace wages lost while taking time off to recover from a work-related injury.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const inlandMarine: InsuranceInfo = {
  name: "Inland Marine",
  whatIsIt: {
    question: "What is inland marine?",
    paragraphs: [
      [
        {
          text: "Inland marine insurance provides coverage for business property, such as products, tools, and equipment, while it’s in transit over land or stored at an off-site location.",
          isBold: false,
        },
      ],
      [
        {
          text: 'Why is it called inland marine insurance? That would be because it\'s an offshoot of ocean marine insurance – which protects property transported over water. Marine insurance came first – hence the distinction "inland" marine for land transportation coverage.Inland marine policies cover insured property no matter where it’s located, such as:',
          isBold: false,
          bulletList: [
            "The kitchen of a food truck",
            "A carpenter's tools that move to different worksites",
            "Fine art on display at a bar or cafe",
            "A performer's musical instruments while on tour",
          ],
        },
      ],
    ],
    graphic: inland_marine,
  },
  coverage: {
    question: "What does inland marine cover?",
    paragraphs: [
      [
        {
          text: "",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        isTwoColumns: true,
        dropDown: [
          {
            title: "Property in transit",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Inland marine insurance protects business property in transit, which is typically excluded from commercial property insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Property in temporary care",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Inland marine insurance can protect property that's temporarily in your care.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Movable property in a fixed location",
            id: 2,
            paragraphs: [
              [
                {
                  text: "Inland marine insurance covers business property that's in a fixed but movable location, such as heavy equipment installed inside a van or truck.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Property that moves around",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Inland marine insurance covers property that moves from location to location, such as a contractor's tools that move to different worksites.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Unique or high-value property",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Your business may have special decorations or artwork that can't be insured with standard policies.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Property that transfers information",
            id: 5,
            paragraphs: [
              [
                {
                  text: "This may include computer equipment that travels to different locations and elecronic data.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't inland marine cover?",
    paragraphs: [
      [
        {
          text: "Inland Marine insurance will not cover:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Vehicles",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Commercial auto insurance is the policy that covers business-owned vehicles. A small business can protect personal, rented, or leased vehicles used for work purposes with hired and non-owned auto insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Exclusions",
            id: 7,
            paragraphs: [
              [
                {
                  text: "An open perils policy, also called an all-risk policy, covers all types of perils unless otherwise stated. Usually, it includes a list of exclusions that details events the policy won't cover.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Open perils policies typically cover fire, windstorms, natural disasters, collisions, and theft. Make sure to read the exclusions carefully so you know exactly what's covered.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Items not listed in the policy",
            id: 8,
            paragraphs: [
              [
                {
                  text: "A named perils policy only covers events listed in the policy and nothing else. Because its coverage is more limited, it often costs less than an open perils policy.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need inland marine?",
    paragraphs: [
      [
        {
          text: "Inland marine insurance is a “floater” policy, which simply means the coverage goes where the insured property goes. A small business that ships valuables, transports tools, or owns a truck with specialized equipment may need this policy.",
          isBold: false,
        },
      ],
      [
        {
          text: "Commercial property insurance only covers business property at the location listed on the policy. Inland marine insurance protects against damage and theft outside your place of business.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const tradeCredit: InsuranceInfo = {
  name: "Trade Credit",
  whatIsIt: {
    question: "What is trade credit?",
    paragraphs: [
      [
        {
          text: "Trade credit insurance (TCI) is a method for protecting your business against customers’ inability to pay for products or services, whether because of bankruptcy, insolvency, or political upheaval.",
          isBold: false,
        },
      ],
      [
        {
          text: "TCI—sometimes referred to as accounts receivable insurance, debtor insurance, or export credit insurance — helps protect your capital and stabilize cash flows. It can also help you secure better financing terms from banks, which have the confidence that their customers’ accounts receivable will be repaid.",
          isBold: false,
        },
      ],
    ],
    graphic: trade_credit,
  },
  coverage: {
    question: "What does trade credit cover?",
    paragraphs: [
      [
        {
          text: "There are four types of trade credit insurance, as described below.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Whole Turnover",
            id: 0,
            paragraphs: [
              [
                {
                  text: "This type of trade credit insurance protects against non-payment of commercial debt from all customers. You can select if this coverage applies to all domestic sales, international sales, or both.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Key Accounts",
            id: 1,
            paragraphs: [
              [
                {
                  text: "With this type of insurance, you decide to insure your largest customers whose non-payment would pose the greatest risk to your business.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Single Buyer",
            id: 2,
            paragraphs: [
              [
                {
                  text: "If most of your transactions are with one customer, you can select a trade credit insurance policy that insures against potential default from just that customer.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Transactional",
            id: 3,
            paragraphs: [
              [
                {
                  text: "This form of trade credit insurance protects against non-payment on a transaction-by-transaction basis and is best for companies with few sales or only one customer.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't trade credit cover?",
    paragraphs: [
      [
        {
          text: "It is also important to know what trade credit insurance is not. Credit insurance is not a substitute for prudent, thoughtful credit management. Sound credit management practices should be the foundation of any credit insurance policy and partnership. Credit insurance goes beyond indemnification and does not replace a company’s credit practices, but rather supplements and enhances the job of a credit professional.",
          isBold: false,
        },
      ],
      [
        {
          text: "Trade credit insurance only covers business-to-business accounts receivable from commercial and political risks. Outstanding debts are not covered unless there is direct trade between your business and a customer (another business).",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need trade credit?",
    paragraphs: [
      [
        {
          text: "Trade credit insurance is suitable and of benefit to all types of businesses – irrespective of size, sector, or whether trade is domestic, export, or both. Corporations, financial institutions, and any other business offering goods or services on credit terms to another business should consider obtaining trade credit insurance. It protects your company from domestic default, as well as default by international buyers. For entities that operate on a financial basis that does not necessarily include immediate payment, trade credit insurance provides protection for a company’s assets as business activity scales upward.",
          isBold: false,
        },
      ],
      [
        {
          text: "Business types that commonly elect trade credit insurance include manufacturing companies, commodity traders, and service providers.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const professionalLiability: InsuranceInfo = {
  name: "Professional Liability",
  whatIsIt: {
    question: "What is professional liability?",
    paragraphs: [
      [
        {
          text: "Professional liability insurance is designed for businesses that make a living off their expertise.",
          isBold: false,
        },
      ],
      [
        {
          text: "Even the most experienced and thorough professional service provider makes mistakes. Unfortunately, clients may not be so understanding if your error costs them considerable time or revenue.",
          isBold: false,
        },
      ],
      [
        {
          text: "Professional liability insurance helps protect you from lawsuits filed by unsatisfied clients.",
          isBold: false,
        },
      ],
    ],
    graphic: professional_liability,
  },
  coverage: {
    question: "What does professional liability cover?",
    paragraphs: [
      [
        {
          text: "When a dissatisfied client sues over a mistake made by your business, professional liability insurance can help cover the cost of legal defense, including the cost of hiring a lawyer.",
          isBold: false,
        },
      ],
      [
        {
          text: "Specifically, professional liability insurance will help provide coverage for:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Work mistakes and oversight",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Sometimes a simple mistake can cause one of your clients to lose money. When a client sues over an error made by your business, professional liability insurance can help pay for your legal defense expenses and more.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Undelivered Services",
            id: 1,
            paragraphs: [
              [
                {
                  text: "If your business promises to provide a service and fails to deliver, the client could choose to sue you. This may be even more likely if the client believes that your work negatively impacted their bottom line.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Accusations of Negligence",
            id: 2,
            paragraphs: [
              [
                {
                  text: "When a client hires you based on your specialized skills, they are entitled to a reasonable standard of care. If your work fails to meet industry standards or causes financial damage to a customer, your business can be accused of professional negligence and could face a professional liability lawsuit.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Missed Deadlines",
            id: 3,
            paragraphs: [
              [
                {
                  text: "If you or an employee at your small business misses a deadline, it may have tremendous repercussions for your client. Professional liability provides protection for your small business if a client sues over late work.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't professional liability cover?",
    paragraphs: [
      [
        {
          text: "While professional liability insurance covers many aspects related to legal action from a client, it does have a number of coverage exclusions. For example, it only covers the cost of defending against lawsuits – it doesn't pay for lawsuits you initiate.",
          isBold: false,
        },
      ],
      [
        {
          text: "Unless your policy has prior acts coverage, it will only cover any claims filed while the policy is active and for incidents that occurred after you bought the policy. In this case, endorsements can fill gaps in your professional liability coverage.",
          isBold: false,
        },
      ],
      [
        {
          text: "Other exclusions from professional liability insurance coverage include:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Customer Injuries or Property Damage",
            id: 4,
            paragraphs: [
              [
                {
                  text: "If a customer is injured on your premises or you accidentally damage a customer’s property, general liability insurance can help pay for medical expenses or the cost of repairing or replacing the damaged item. It can also cover legal expenses if the customer sues.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee Injuries",
            id: 5,
            paragraphs: [
              [
                {
                  text: "Workers' compensation insurance will cover medical expenses and partial lost wages for employees with work-related injuries or illnesses.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee Discrimination Lawsuits",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Employment practices liability insurance (EPLI) will cover lawsuit expenses related to claims of harassment, discrimination, and wrongful termination.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Damage to Business Property",
            id: 7,
            paragraphs: [
              [
                {
                  text: "The property coverage included in a business owner’s policy (BOP) can pay for items that are damaged, destroyed, stolen, or lost.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Vehicles Used by a Business",
            id: 8,
            paragraphs: [
              [
                {
                  text: "Business-owned vehicles must be covered by commercial auto insurance. Personal or leased vehicles used by a business should be covered by hired and non-owned auto insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Contingent Bodily Injury",
            id: 9,
            paragraphs: [
              [
                {
                  text: "Product liability insurance can pay for contingent bodily injuries – which are client or customer injuries that can be indirectly linked to your professional service.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need professional liability?",
    paragraphs: [
      [
        {
          text: "Professional liability insurance benefits a variety of industries that provide services directly to a client or customer. It is designed for both businesses and independent contractors that make a living off of their expertise in these industries.",
          isBold: false,
        },
      ],
      [
        {
          text: "However, there are a few specific professions who need professional liability coverage more often than others, including:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Accountants & Auditors",
            id: 10,
            graphic: accountantsAuditors,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance for accountants and auditors covers the costs of lawsuits based on accounting errors, data entry errors, miscalculations, or lost documentation.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For example, if an accountant incorrectly enters a client's financial transactions into a spreadsheet and the budget falls short, the client may trace the mistake back and file a lawsuit. Professional liability would cover the accountant's cost of hiring a lawyer.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Because the policy offers indirect financial protection for the client, some clients will require proof of insurance before they agree to work with your firm.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Architects",
            id: 11,
            graphic: architects,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance protects architects against several accusations, including that a building design does not meet project specifications, was delivered late, or contains errors.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For example, if an architect designs a building for a client and it fails to meet the project’s specifications, the client could accuse the architect of negligence and file a lawsuit.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Some client contracts might include a clause that requires professional liability insurance coverage for architects.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Consultants",
            id: 12,
            graphic: consultants,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance for consultants offers financial compensation for lawsuits alleging ineffective advice or inaccurate projections.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For example, a management consultant promises a company that it will improve its profits by 20% by a certain date. When the date arrives, profits have gone up, but not as much as projected. Professional liability insurance would help cover the consultant’s legal defense costs if the client sues the consultant over its financial troubles.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Some clients may ask for proof of professional liability insurance before they agree to work with a consultant.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Engineers",
            id: 13,
            graphic: engineers,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance for engineers provides financial protection from lawsuits over disputes such as cost overrun, delivery delays, and problems with construction materials.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For example, if an engineer estimates that a building extension for a client would be completed by a certain date, but a concrete delivery issue forces the project completion to be delayed by a month. Professional liability insurance would help cover the engineer's cost of hiring a lawyer if the client files a lawsuit over the missed deadline.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Engineers may need to provide proof of professional liability insurance to a client, partner, or licensing board for some projects.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Healthcare Professionals",
            id: 14,
            graphic: healthcare,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance for healthcare professionals offers the protection your healthcare business needs to continue providing its services while handling a lawsuit.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "For instance, if a patient claims that neglect from a nurse caused bed sores, they could file a lawsuit against them. Professional liability insurance would cover legal expenses for the nurse, such as the cost of hiring an attorney.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "While this coverage is sometimes referred to as medical malpractice insurance, the difference is that professional liability coverage often relates to a healthcare provider’s business activities, while malpractice covers your legal defense and settlement costs if you’re accused of being responsible for a patient’s injury or death.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const eventLiability: InsuranceInfo = {
  name: "Event Liability",
  whatIsIt: {
    question: "What is event liability?",
    paragraphs: [
      [
        {
          text: "Event liability coverage helps protect you from financial loss if you're held responsible for an accident that hurts someone or causes property damage at your event. You may even be covered if one of your guests creates havoc. Many venues now require you to have liability protection before you can even book there.",
          isBold: false,
        },
      ],
    ],
    graphic: event_liability,
  },
  coverage: {
    question: "What does event liability cover?",
    paragraphs: [
      [
        {
          text: "While event insurance is a popular choice for weddings, it can be purchased for thousands of other events, including birthday parties, meetings, festivals, and silent charity auctions. Exactly what's covered varies based on the event, the insurer, and the policy.",
          isBold: false,
        },
      ],
      [
        {
          text: "Events that are covered by special event insurance include but are not limited to:",
          isBold: false,
          bulletList: [
            "Weddings",
            "Engagement parties",
            "Business meetings",
            "Non-Profit functions",
            "Retirement parties",
            "Anniversary parties",
            "Corporate eventsEvent liability insurance may provide protection for:",
            "Non-refundable deposits",
            "Cancellation fees",
            "Injury",
            "Liability",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't event liability cover?",
    paragraphs: [
      [
        {
          text: "If you purchase wedding cancellation insurance, you won't be covered for getting cold feet. Cancellation coverage is specifically for things outside of your control that force you to cancel your wedding.Special event insurance is not available for every event, including:",
          isBold: false,
          bulletList: [
            "Sporting events",
            "Events with greater than 500 guests",
            "Events at remote locations",
            "Bachelor/Bachelorette parties",
            "Color runs",
            "Gun shows",
            "Animal shows",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need event liability?",
    paragraphs: [
      [
        {
          text: "It's a good idea to purchase a policy as soon as you begin making deposits, because unexpected issues can crop up at any point. That said, you need to buy event cancellation coverage at least 14 days before your function date and liability coverage at least one day prior. You can buy both up to 24 months in advance.",
          isBold: false,
        },
      ],
      [
        {
          text: "Many vendors don't offer refunds no matter the reason for cancellation or postponement.",
          isBold: false,
        },
      ],
      [
        {
          text: "A lot of venues require clients to purchase an event liability policy because they don't want to take on sole responsibility for damage to their property. Home insurance often doesn't provide enough protection. Typically, home policies will only cover the event if it's located at the home of the host and will only cover event liability, not cancellation.",
          isBold: false,
        },
      ],
      [
        {
          text: "No one wants to think about something unpleasant when planning an important day, but it's nice to know there's a policy that can help protect you from the unexpected.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const propertyCoverage: InsuranceInfo = {
  name: "Property Coverage",
  whatIsIt: {
    question: "What is property coverage?",
    paragraphs: [
      [
        {
          text: "Commercial property insurance pays to repair or replace stolen, lost, or damaged business property. It covers your business’s physical location and other assets like equipment.Commercial property insurance protects against the risks of owning or renting an office, storefront, or other business property. It covers property losses when a fire damages your office, or a pipe bursts and floods your store.",
          isBold: false,
        },
      ],
    ],
    graphic: property_coverage,
  },
  coverage: {
    question: "What does property coverage cover?",
    paragraphs: [
      [
        {
          text: "Commercial property insurance covers your business’s real estate and its contents. It helps pay for repair or replacement when business property is lost, damaged, or destroyed.",
          isBold: false,
        },
      ],
      [
        {
          text: "Your business assets aren’t just expensive – they keep your business running. With commercial property insurance, you’ll be able to afford repairs for necessary equipment after an unexpected event like a fire or a break-in.",
          isBold: false,
        },
      ],
      [
        {
          text: "A commercial property insurance policy can cover your:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Business-owned building",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Commercial property insurance protects a building owned by your business against fire, vandalism, and other types of damage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Furnitures and fixtures",
            id: 1,
            paragraphs: [
              [
                {
                  text: "The furniture and fixtures inside your owned or rented building are protected by commercial property insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Supplies and equipment",
            id: 2,
            paragraphs: [
              [
                {
                  text: "If your business’s supplies or equipment are damaged, lost, or stolen, commercial property insurance coverage can help repair or replace them.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Inventory",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Commercial property insurance protects your inventory against theft, fire, and other causes of loss.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't property coverage cover?",
    paragraphs: [
      [
        {
          text: "Commercial property insurance covers a commercial building and its contents. However, it doesn't cover every risk or type of property. Here are a few situations where you may need additional coverage.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Goods in transit and mobile equipment",
            id: 4,
            paragraphs: [
              [
                {
                  text: "If your business needs coverage for items in transit or equipment that moves to different worksites, consider inland marine insurance or equipment floater insurance. These policies cover equipment, tools, and other possessions that move from place to place. It also includes items in a business’s temporary care, such as loaned artwork.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Damaged Customer Property",
            id: 5,
            paragraphs: [
              [
                {
                  text: "General liability insurance covers property belonging to your customers and clients. It helps cover legal costs if someone sues over a broken item or an accidental injury.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Business Interruption",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Business interruption insurance, also called business income insurance, covers financial losses when a disaster forces a business to close temporarily. It can pay for loss of income, employee wages, and other day-to-day expenses.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "This policy sometimes includes extra expense coverage, which covers costs beyond your routine operating expenses. For example, you might need to move to a temporary location or hire additional employees after a disaster.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee Theft",
            id: 7,
            paragraphs: [
              [
                {
                  text: "Employee dishonesty coverage reimburses your clients if an employee steals items or money from them. This coverage includes fidelity bonds, which protect your business and its clients against dishonest employees.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Malfunctioning Equiptment",
            id: 8,
            paragraphs: [
              [
                {
                  text: "Equipment breakdown coverage pays to replace malfunctioning equipment after a mechanical or electrical failure. You can add this extra coverage as an endorsement on your commercial property insurance policy.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Destroyed Payment Records",
            id: 9,
            paragraphs: [
              [
                {
                  text: "If a burst pipe or other incident destroys customer records, your business could have trouble collecting outstanding customer payments. Commercial property insurance doesn't cover the extra cost of recovering these payments unless your policy has an accounts receivable endorsement.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "DO I need property coverage?",
    paragraphs: [
      [
        {
          text: "Landlords often require proof of commercial property coverage, liability coverage, or another type of business renter's insurance from their business tenants. Though landlords carry property insurance for their buildings, they won’t take responsibility for any expensive business property a tenant keeps in the space.",
          isBold: false,
        },
      ],
      [
        {
          text: "Lenders also often mandate business property insurance for the life of the mortgage.",
          isBold: false,
        },
      ],
      [
        {
          text: "Even when not required, commercial property insurance is important for any business that owns a building, valuable equipment, or expensive inventory. It helps your business recover financially from fires, theft, and other damaging events.",
          isBold: false,
        },
      ],
      [
        {
          text: "Specific professions who shouold consider commercial property insurance are:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Retailers, wholesalers, and manufacturers",
            id: 10,
            graphic: retailersWholesalers,
            paragraphs: [
              [
                {
                  text: "Property insurance is crucial for any business that sells, distributes, or manufactures goods. For example, if a pipe bursts in a grocery store, a commercial property policy would cover the cost of replacing all of the food that had to be thrown away.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If you rent a commercial space, you can purchase a policy that covers only owned items in the space. You may also need to insure equipment that you lease, depending on your arrangement with the owner.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "IT and Technology",
            id: 11,
            graphic: itTech,
            paragraphs: [
              [
                {
                  text: "Tech companies rely on their computers and other expensive electronics on a daily basis.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If a thief breaks into your office and makes off with thousands of dollars' worth of monitors, PCs, and laptops, a commercial property policy will cover the cost of replacing the stolen items.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Construction",
            id: 12,
            graphic: construction,
            paragraphs: [
              [
                {
                  text: "Construction companies and contractors rely on several different types of specialized property insurance to cover their risks. They might need contractors tools and equipment coverage to protect power tools that travel to different job sites, or builder's risk insurance to cover a structure in progress.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If a fire destroys your tools and equipment, or someone vandalizes a construction site, these policies will help you recover financially.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Real Estate",
            id: 13,
            graphic: realEstate,
            paragraphs: [
              [
                {
                  text: "Commercial landlords, property managers, and other real estate professionals likely need a policy that covers the actual building and all contents, including mechanical and electrical systems.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If you rent a space, you may want to ask your tenants to carry insurance to make sure their own items are protected as well. You should also consider purchasing lessor's risk only (LRO) insurance to protect yourself from legal fees if an accident happens on your property, as well as a building insurance, which covers property damage to buildings, structures, and completed additions that you lease to commercial tenants.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const umbrellaLiability: InsuranceInfo = {
  name: "Umbrella Liability",
  whatIsIt: {
    question: "What is umbrella liability?",
    paragraphs: [
      [
        {
          text: "Business owners who purchase commercial umbrella insurance usually need it to fulfill a contract that requests higher than standard policy limits.",
          isBold: false,
        },
      ],
      [
        {
          text: "Commercial umbrella insurance offers extra liability coverage for the most expensive lawsuits. For example, if a covered lawsuit maxes out your general liability insurance policy but you still owe money for damages, business umbrella coverage can provide additional funds to make up the difference.Umbrella liability insurance can supplement your coverage provided by:",
          isBold: false,
          bulletList: [
            "General liability insurance",
            "Employer's liability insurance (often included in workers' compensation)",
            "Commercial auto insurance or hired and non-owned auto insurance (HNOA)",
          ],
        },
      ],
      [
        {
          text: "Commercial umbrella insurance can boost coverage for any (or all) of these policies. Before you can purchase umbrella liability insurance, however, an insurer will require you to carry a certain amount of coverage for the underlying policy.",
          isBold: false,
        },
      ],
    ],
    graphic: umbrella_liability,
  },
  coverage: {
    question: "What does umbrella liability cover?",
    paragraphs: [
      [
        {
          text: "Commercial umbrella insurance policies have the same terms and cover the same risks as the underlying business insurance policy.",
          isBold: false,
        },
      ],
      [
        {
          text: "Specifically, umbrella insurance can help provide additional coverage for the following:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Slip-and-fall Injuries",
            id: 0,
            paragraphs: [
              [
                {
                  text: "When you add commercial umbrella insurance (or excess liability insurance) to your general liability insurance, it can cover your legal expenses if someone injures themselves on your business’s property.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Third-party Property Damage",
            id: 1,
            paragraphs: [
              [
                {
                  text: "When you add commercial umbrella insurance to a general liability policy, it helps pay legal bills related to destroyed or damaged third-party property.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Car Accident Liability",
            id: 2,
            paragraphs: [
              [
                {
                  text: "When you add commercial umbrella insurance to a commercial auto insurance or hired and non-owned auto insurance policy, it helps cover costs if someone sues for damages caused by your vehicle.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee Injury Lawsuits",
            id: 3,
            paragraphs: [
              [
                {
                  text: "When you add commercial umbrella insurance to employer's liability insurance (typically included in workers' compensation insurance), it helps pay for employee lawsuits over work injuries.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "Whar doesn't umbrella liability cover?",
    paragraphs: [
      [
        {
          text: "While umbrella insurance does expand coverage limits for a number of policies, it does not provide all the protection that a small business might need.",
          isBold: false,
        },
      ],
      [
        {
          text: "For instance, an umbrella policy does not cover:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Damages withing the Underlying Policy Limits",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Umbrella liability insurance does not become active until the underlying policy has reached its limits. And as with any policy, it does not provide coverage beyond its own policy limits.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Business Property Damage",
            id: 5,
            paragraphs: [
              [
                {
                  text: "The commercial property insurance portion of a business owner's policy (BOP) or commercial package policy (CPP) can help pay for repair or replacement when your business property is damaged by fire, theft, or covered weather-related events. Business umbrella insurance can only be added to liability policies, not property insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Professional Errors",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Professional liability insurance, also called errors and omissions insurance (E&O) or malpractice insurance, can cover lawsuits over professional mistakes, including undelivered services and missed deadlines.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "You can boost the limits on this policy with excess liability insurance, also called excess E&O, which is very similar to umbrella insurance.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Employee Theft",
            id: 7,
            paragraphs: [
              [
                {
                  text: "Commercial crime insurance, a type of fidelity bond, will be required to financially protect your business from criminal acts committed by employees against customers or clients.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "Unlike insurance, bonds only reimburse damages up to the size of the coverage that was purchased. Umbrella insurance could not be used to provide coverage beyond that limit.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need umbrella liability?",
    paragraphs: [
      [
        {
          text: "Think of commercial umbrella insurance coverage as a safety net. Your other liability policies are your first line of defense. When you need an added layer of protection on a claim that exceeds the limits of your primary policy, your business umbrella insurance can pick up the slack. Here's when you need it:",
          isBold: false,
        },
      ],
      [
        {
          text: "Let's say you have a commercial general liability policy with a $2 million per-occurrence limit, which means it can pay up to $2 million toward your legal expenses.",
          isBold: false,
        },
      ],
      [
        {
          text: "Should a client suffer a serious injury after tripping and falling at your business, and a drawn-out legal battle follows, the medical expenses, legal fees, and damages could add up to $2.5 million. After hitting your general liability limit, you would still have a $500,000 bill. Here's how it helps cover expenses:",
          isBold: false,
        },
      ],
      [
        {
          text: "An umbrella liability policy can help cover the expenses that exceed your underlying policy's limit. For the case above, a business umbrella policy can cover the additional $500,000.",
          isBold: false,
        },
      ],
      [
        {
          text: "Basically, you can make a claim on umbrella insurance when each of the following occurs:",
          isBold: false,
        },
      ],
      [
        {
          text: "Your business is sued over third-party bodily injury or property damage. The cost of the lawsuit is more than the limit of the underlying insurance policy designed to cover it.Commercial umbrella insurance used to be a policy that only big businesses bought. These days, more small businesses invest in commercial umbrella insurance due to the rising cost of lawsuits.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const auto: InsuranceInfo = {
  name: "Auto",
  whatIsIt: {
    question: "What is auto insurance?",
    paragraphs: [
      [
        {
          text: "Auto insurance can help protect you from costs related to auto accidents and other types of losses, such as the theft of your car. Your auto insurance policy is a contract for which you pay a certain amount or — “premium” — to your insurance company in exchange for the coverages you select.",
          isBold: false,
        },
      ],
    ],
    graphic: automotive_insurance,
  },
  coverage: {
    question: "What does auto insurance cover?",
    paragraphs: [
      [
        {
          text: "Typically, liability coverage is required in most states. This coverage helps cover someone else’s bodily injury or property damages when you are determined to be at fault for the accident. There are also other coverages that can help cover the costs of damage to you or your auto. To get the most complete protection for your individual needs, discuss available coverage options with your insurance representative.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Liability Coverage",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Liability coverage includes both bodily injury and property damage liability coverage and is required in most states. This coverage provides compensation for injuries to others, and for the damage your vehicle does to another person's property if you cause an accident.",
                  isBold: false,
                },
              ],
              [
                {
                  text: "If you are found responsible for causing damages as a result of an accident, this coverage may pay up to the limit you select, and it can provide for a legal defense if you're sued.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Roadside Assistance",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Roadside assistance is coverage you can add to your car insurance policy to help when you find yourself stranded due to unexpected car trouble — like a dead car battery. These unexpected things can happen when you're at work, out running errands, or even when you’re at home.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Loan/Lease Gap",
            id: 2,
            paragraphs: [
              [
                {
                  text: "Gap insurance, also known as loan/lease insurance, can help protect you if your vehicle is financed or leased. If your vehicle is totaled (deemed a total loss), this coverage may pay the difference between the actual cash value of the vehicle and the unpaid balance of the auto loan or lease.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't auto insurance cover?",
    paragraphs: [
      [
        {
          text: "What is commonly not covered by auto insurance?- Other individuals driving your car, who aren't included on the policy",
          isBold: false,
          bulletList: [
            "Regular repairs & general maintenance",
            "Personal items stolen from the vehicle",
            "Normal wear & tear damage",
            "Intentional damage - sometimes DUIs",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need auto insurance?",
    paragraphs: [
      [
        {
          text: "If you’re involved in an accident, you may have expenses for car repairs, property damage, medical bills, lost wages, and more. Without the coverages you need, you could have to pay those bills on your own.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const personalInlandMarine: InsuranceInfo = {
  name: "Inland Marine",
  whatIsIt: {
    question: "What is inland marine?",
    paragraphs: [
      [
        {
          text: "Personal inland marine insurance (also called scheduled personal property) can provide coverage for your personal property, whether or not it is transported from one location to another.",
          isBold: false,
        },
      ],
    ],
    graphic: inland_marine,
  },
  coverage: {
    question: "What does inland marine cover?",
    paragraphs: [
      [
        {
          text: "Inland marine coverage can expand upon the coverage in your home policy to protect your investment in items such as:",
          isBold: false,
          bulletList: [
            "Jewelry",
            "Furs",
            "Fine art",
            "Cameras",
            "Silverware",
            "Musical instruments",
            "Firearms",
            "Coin or stamp collections",
            "Golf equipment",
            "Computers and related equipment",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't inland marine cover?",
    paragraphs: [
      [
        {
          text: "A personal inland marine insurance policy will not extend to any items that are not specified in your insurance policy.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need inland marine?",
    paragraphs: [
      [
        {
          text: "If you are purchasing or already purchased a high-value item it is important to reach out to your insurance agent to verify that it is insured.",
          isBold: false,
        },
      ],
      [
        {
          text: "In many cases, your typical home policy may not cover the full value of the item so you may need additional coverage. This is where Inland Marine Insurance comes in handy.",
          isBold: false,
        },
      ],
      [
        {
          text: "It is used to insure valuable personal property that often needs broader coverage than your standard home policy provides.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const home: InsuranceInfo = {
  name: "Home",
  whatIsIt: {
    question: "What is home insurance?",
    paragraphs: [
      [
        {
          text: "Home insurance can help protect you from the unexpected. If your home is damaged, your belongings are stolen or someone gets injured on your property, it can help cover repairs or replacement, temporary housing, legal fees and more.",
          isBold: false,
        },
      ],
    ],
    graphic: homeowners_insurance,
  },
  coverage: {
    question: "What does home insurance cover?",
    paragraphs: [
      [
        {
          text: "A typical policy can cover:",
          isBold: false,
          bulletList: [
            "The structure of your home",
            "Other structures on your property (e.g. garage, shed)",
            "Your personal belongings",
            "Additional living expenses if you cannot live in your home due to a covered loss",
            "Your personal liability or legal fees",
            "Limited valuable items (extra coverage can usually be added)",
          ],
        },
      ],
      [
        {
          text: "Be sure to explore options to customize your limits and coverages to meet your needs. Your home insurance policy includes coverages in the following categories to help ensure your home and assets are appropriately protected.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Dwelling Coverage",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Dwelling coverage can help pay to repair or rebuild your house if it’s damaged by a covered cause of loss. Some examples of the types of risks that may be covered include:",
                  isBold: false,
                  bulletList: [
                    "House fires",
                    "Severe weather",
                    "Damage from plumbing or appliance leaks",
                    "Vandalism/theft",
                  ],
                },
              ],
            ],
          },
          {
            title: "Personal Property Coverage",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Personal property coverage can pay you for the personal items in your home that may be damaged or destroyed by a covered cause of loss, which could include:",
                  isBold: false,
                  bulletList: [
                    "Your furniture",
                    "Clothes",
                    "Sporting goods",
                    "Electronics",
                  ],
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't home insurance cover?",
    paragraphs: [
      [
        {
          text: "While a standard home insurance policy covers your home and personal belongings from most types of damage, there are also several common disasters and liabilities that are not covered. Damage or loss due to flooding, earthquakes, and pest infestations are just a few of the several common exclusions.Frequent home policy exclusions are:",
          isBold: false,
          bulletList: [
            "Flooding",
            "Earth movement",
            "Pest infestations",
            "Mold or wet rot",
            "Wear & tear or neglect",
            "Power surges caused by an outside source",
            "Home-based business liability",
            "Intentional damage",
            "War",
            "Government action",
          ],
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need home insurance?",
    paragraphs: [
      [
        {
          text: "A home policy is recommended for anyone who owns a home or condo and may even be required by your mortgage lender. You will need separate coverage to help protect your home and personal belongings against damage due to floods or earthquakes.",
          isBold: false,
        },
      ],
      [
        {
          text: "If the unexpected happens, home insurance can help you restore your life back to normal.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
};

const watercraft: InsuranceInfo = {
  name: "Watercraft",
  whatIsIt: {
    question: "What is watercraft insurance?",
    paragraphs: [
      [
        {
          text: "Personal watercraft insurance is a type of insurance policy that provides coverage for individuals who own or operate personal watercraft, such as jet skis or wave runners. This type of insurance is designed to protect watercraft owners and operators from financial losses that may result from accidents, damage, or other liabilities related to the use of their watercraft.",
          isBold: false,
        },
      ],
    ],
    graphic: watercraft_insurance,
  },
  coverage: {
    question: "What does watercraft insurance cover?",
    paragraphs: [
      [
        {
          text: "Personal watercraft insurance policies typically include coverage for a range of potential risks, such as damage to the watercraft, injuries or property damage caused by the watercraft to third parties, and liability for injury or damage caused to passengers on board the watercraft. Depending on the specific policy, coverage may also be available for risks such as theft, vandalism, and weather-related damage.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Physical Damage to Watercraft",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance typically provides coverage for damage to the watercraft resulting from accidents, weather-related events, vandalism, and theft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Liability for Third-Party Injuries or Property Damage",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance can provide coverage for legal liabilities that may arise if the watercraft causes injury or property damage to a third party.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Liability for Injuries to Passengers",
            id: 2,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance can provide coverage for injuries sustained by passengers on board the watercraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Medical Expenses",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance may provide coverage for medical expenses incurred as a result of an accident or injury related to the use of the watercraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Emergency Expenses",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance may provide coverage for emergency expenses such as towing and salvage costs.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Personal Effects Coverage",
            id: 5,
            paragraphs: [
              [
                {
                  text: "Some personal watercraft insurance policies may provide coverage for loss or damage to personal belongings that are on board the watercraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Uninsured Watercraft Coverage",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance can provide coverage if the watercraft causing damage to the insured's watercraft is uninsured or underinsured.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't watercraft insurance cover?",
    paragraphs: [
      [
        {
          text: "Personal watercraft insurance policies typically have specific exclusions, which may vary depending on the policy and insurance provider. Some examples of items that personal watercraft insurance policies may not cover include:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Normal Wear & Tear",
            id: 7,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance policies typically do not provide coverage for damage that occurs as a result of normal wear and tear, maintenance issues, or mechanical breakdowns.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Intentional Acts",
            id: 8,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance policies do not cover damage that results from intentional acts or illegal activities, such as racing or driving the watercraft under the influence of drugs or alcohol.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Damage to Other Property",
            id: 9,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance typically does not cover damage to other property owned by the watercraft owner or operator, such as a dock or other structures.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Commercial Use",
            id: 10,
            paragraphs: [
              [
                {
                  text: "Some personal watercraft insurance policies may exclude coverage for watercraft that are used for commercial purposes.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Environment Damage",
            id: 11,
            paragraphs: [
              [
                {
                  text: "Personal watercraft insurance may not cover damage to the environment, such as oil spills or other pollution caused by the watercraft.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need watercraft insurance?",
    paragraphs: [
      [
        {
          text: "Anyone who owns or operates a personal watercraft, such as a jet ski or wave runner, should consider purchasing personal watercraft insurance. Personal watercraft insurance can provide important coverage for a range of risks and liabilities related to the use of the watercraft, including damage to the watercraft, liability for injuries or property damage caused by the watercraft, and liability for injuries sustained by passengers on board the watercraft.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        label:
          "Some specific groups of individuals who may need personal watercraft insurance include:",
        dropDown: [
          {
            title: "Watercraft Owners",
            id: 12,
            graphic: watercraftOwners,
            paragraphs: [
              [
                {
                  text: "Individuals who own personal watercraft may face significant financial losses in the event of an accident, theft, or damage to the watercraft. Personal watercraft insurance can help to mitigate these risks and provide coverage for potential liabilities.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Renters",
            id: 13,
            graphic: renters,
            paragraphs: [
              [
                {
                  text: "Individuals who rent personal watercraft should also consider purchasing personal watercraft insurance to protect themselves from potential liabilities and to ensure that they have coverage in the event of an accident.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Operators",
            id: 14,
            graphic: operators,
            paragraphs: [
              [
                {
                  text: "Individuals who operate personal watercraft, such as jet ski or wave runner operators, may also benefit from personal watercraft insurance, as they can be held liable for any accidents or injuries that occur while they are operating the watercraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Charter Companies",
            id: 15,
            graphic: charterCompaniesW,
            paragraphs: [
              [
                {
                  text: "Companies that provide personal watercraft rental or charter services should also carry personal watercraft insurance to protect themselves and their clients from potential losses and liabilities.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const aircraft: InsuranceInfo = {
  name: "Aircraft",
  whatIsIt: {
    question: "What is aircraft insurance?",
    paragraphs: [
      [
        {
          text: "Personal aircraft insurance is a type of insurance policy that provides coverage for individuals who own or operate private aircraft. This type of insurance is designed to protect aircraft owners and pilots from financial losses that may result from accidents, damage, or other liabilities related to the use of their aircraft.",
          isBold: false,
        },
      ],
    ],
    graphic: aircraft_insurance,
  },
  coverage: {
    question: "What does aircraft insurance cover?",
    paragraphs: [
      [
        {
          text: "Personal aircraft insurance policies typically include coverage for a range of potential risks, such as damage to the aircraft, injuries or property damage caused by the aircraft to third parties, and liability for injury or damage caused to passengers on board the aircraft. Depending on the specific policy, coverage may also be available for risks such as theft, vandalism, and weather-related damage.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Physical Damage to the Aircraft",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance typically provides coverage for damage to the aircraft resulting from accidents, weather-related events, vandalism, and theft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Liability for Third-Party Injuries or Property Damage",
            id: 1,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance can provide coverage for legal liabilities that may arise if the aircraft causes injury or property damage to a third party.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Liability for Injuries to Passengers",
            id: 2,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance can provide coverage for injuries sustained by passengers on board the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Medical Expenses",
            id: 3,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance may provide coverage for medical expenses incurred as a result of an accident or injury related to the use of the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Emergency Expenses",
            id: 4,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance may provide coverage for emergency expenses such as search and rescue costs.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Personal Effects Coverage",
            id: 5,
            paragraphs: [
              [
                {
                  text: "Some personal aircraft insurance policies may provide coverage for loss or damage to personal belongings that are on board the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Ground Risk Coverage",
            id: 6,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance can provide coverage for damage to the aircraft while it is on the ground, such as damage caused by a collision with a vehicle or object.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't aircraft insurance cover?",
    paragraphs: [
      [
        {
          text: "While personal aircraft insurance is designed to provide coverage for a wide range of risks related to the use of private aircraft, there are some types of losses or liabilities that may not be covered by a standard policy. Some common exclusions or limitations in personal aircraft insurance policies may include:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "War or Terrorism Related Losses",
            id: 7,
            paragraphs: [
              [
                {
                  text: "Most personal aircraft insurance policies will exclude coverage for losses that result from acts of war or terrorism.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Intentional or Criminal Acts",
            id: 8,
            paragraphs: [
              [
                {
                  text: "If an aircraft owner or pilot intentionally causes damage or injury, or engages in criminal activity while operating the aircraft, their insurance policy may not provide coverage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Uninsured or Underinsured Losses",
            id: 9,
            paragraphs: [
              [
                {
                  text: "If another pilot or aircraft causes damage or injury to the insured aircraft or passengers, and that pilot or aircraft is uninsured or underinsured, the personal aircraft insurance policy may not provide full coverage for the losses.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Wear and Tear or Mechanical Failures",
            id: 10,
            paragraphs: [
              [
                {
                  text: "Personal aircraft insurance policies may exclude coverage for damage or losses that result from normal wear and tear or mechanical failures of the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Exceeding Aircraft Limitations",
            id: 11,
            paragraphs: [
              [
                {
                  text: "If an aircraft owner or pilot exceeds the stated limitations or requirements in their insurance policy (such as maximum flight time or altitude), coverage may be limited or excluded.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Illegal Activities",
            id: 12,
            paragraphs: [
              [
                {
                  text: "If the aircraft is used for illegal activities such as drug trafficking, smuggling or any other criminal activity, then the insurance coverage will be excluded.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need aircraft insurance?",
    paragraphs: [
      [
        {
          text: "Individuals who own or operate private aircraft should consider purchasing personal aircraft insurance to protect themselves and their assets from potential losses and liabilities. This may include:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Private Aircraft Owners",
            id: 13,
            graphic: privateAircraftOwners,
            paragraphs: [
              [
                {
                  text: "Owners of private aircraft may face significant financial losses in the event of an accident, theft, or damage to the aircraft. Personal aircraft insurance can help to mitigate these risks and provide coverage for potential liabilities.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Pilots",
            id: 14,
            graphic: pilots,
            paragraphs: [
              [
                {
                  text: "Pilots who operate private aircraft may also benefit from personal aircraft insurance, as they can be held liable for any accidents or injuries that occur while they are operating the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Charter Companies",
            id: 15,
            graphic: charterCompanies,
            paragraphs: [
              [
                {
                  text: "Companies that provide private aircraft charter services should also carry personal aircraft insurance to protect themselves and their clients from potential losses and liabilities.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Flight Schools",
            id: 16,
            graphic: flightSchools,
            paragraphs: [
              [
                {
                  text: "Flight schools that offer training for private pilots should also have personal aircraft insurance to cover any accidents or injuries that may occur during training.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Aircraft Lenders",
            id: 17,
            graphic: aircraftLenders,
            paragraphs: [
              [
                {
                  text: "Lenders who provide financing for private aircraft may require borrowers to carry personal aircraft insurance to protect the lender's investment in the aircraft.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const umbrella: InsuranceInfo = {
  name: "Umbrella",
  whatIsIt: {
    question: "What is umbrella insurance?",
    paragraphs: [
      [
        {
          text: "Umbrella insurance provides additional liability coverage that can help protect assets such as your home, car and boat. It can also help cover defense costs, attorney fees and other charges associated with lawsuits.Umbrella insurance coverage helps protect you from the costs of covered claims when those costs exceed the limits of your home insurance or auto insurance policies.",
          isBold: false,
        },
      ],
      [
        {
          text: "An umbrella policy can help cover defense costs when you are being sued for damages to someone else’s property or injuries caused to others in an accident.",
          isBold: false,
        },
      ],
    ],
    graphic: umbrella_liability,
  },
  coverage: {
    question: "What does umbrella insurance cover?",
    paragraphs: [
      [
        {
          text: "Whether it’s a serious car accident involving extensive medical bills or an incident on your property, you may quickly find yourself responsible for damages that exceed the limits of your auto, home or boat policies. That’s when having an umbrella insurance policy can provide coverage that goes beyond the limits of your primary coverage.Umbrella insurance can help provide coverage for:",
          isBold: false,
        },
      ],
      [
        {
          text: "",
          isBold: false,
          bulletList: [
            "$1 million to $10 million of liability, which can help protect assets such as your home, car and boat.",
            "Claims like libel, slander, defamation of character and invasion of privacy.",
            "Legal defense costs like attorney fees and other charges associated with lawsuits.",
            "Liabilities that happen outside the United States. An umbrella insurance policy is a valuable addition to any auto, home or other policy for extended personal liability protection.",
          ],
        },
      ],
      [
        {
          text: "In today’s world, anyone can face a lawsuit, even if you’ve done nothing wrong. That’s why it’s more important than ever to consider adding an umbrella policy as an extra layer of protection for your assets – and your peace of mind.",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [],
      },
    ],
  },
  noCoverage: {
    question: "What doesn't umbrella insurance cover?",
    paragraphs: [
      [
        {
          text: "Generally, damage to your own personal property is not covered under a personal umbrella policy.",
          isBold: false,
        },
      ],
      [
        {
          text: "Other examples of coverage not included in a typical umbrella policy are:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "Business Losses",
            id: 0,
            paragraphs: [
              [
                {
                  text: "Damage to your business property or losses related to the running of your business generally would not be covered by a personal umbrella policy. This exclusion applies even if your business is operated out of your home. Consider purchasing business insurance if you need this type of coverage.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Intentional Acts",
            id: 1,
            paragraphs: [
              [
                {
                  text: "A personal umbrella policy isn’t designed to protect you from liability connected with your own intentionally harmful behavior; for example, if you intentionally harm a visitor to your home.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
  whoIsItFor: {
    question: "Do I need umbrella insurance?",
    paragraphs: [
      [
        {
          text: "Personal umbrella insurance can provide important liability protection for a wide range of individuals who may face potential risks and liabilities in their personal and professional lives. Some groups of people who may benefit from a personal umbrella policy include:",
          isBold: false,
        },
      ],
    ],
    list: [
      {
        dropDown: [
          {
            title: "High Net Worth Individuals",
            id: 2,
            graphic: highNetWorthIndividuals,
            paragraphs: [
              [
                {
                  text: "Individuals with significant assets, such as homes, investments, and other property, may be more likely to face lawsuits or other liability claims, and may benefit from the extra protection provided by a personal umbrella policy.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Business Owners",
            id: 3,
            graphic: businessOwners,
            paragraphs: [
              [
                {
                  text: "Business owners may be at risk for liability claims related to their business activities, and a personal umbrella policy can provide additional protection beyond the limits of their business insurance policies.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Home",
            id: 4,
            graphic: homeownersIcon,
            paragraphs: [
              [
                {
                  text: "Home may be at risk for liability claims related to accidents or injuries that occur on their property, such as slip and fall accidents or dog bites, and may benefit from the extra protection provided by a personal umbrella policy.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Drivers",
            id: 5,
            graphic: drivers,
            paragraphs: [
              [
                {
                  text: "Drivers may be at risk for liability claims related to accidents that occur while they are driving, and a personal umbrella policy can provide additional protection beyond the limits of their auto insurance policy.",
                  isBold: false,
                },
              ],
            ],
          },
          {
            title: "Landlords",
            id: 6,
            graphic: landlords,
            paragraphs: [
              [
                {
                  text: "Landlords may be at risk for liability claims related to their rental properties, such as injuries sustained by tenants or damage to property, and may benefit from the extra protection provided by a personal umbrella policy. In general, anyone who has assets that could be at risk in the event of a lawsuit or other liability claim, or who engages in activities that could put them at risk for liability, should consider a personal umbrella policy as a way to provide additional protection and peace of mind.",
                  isBold: false,
                },
              ],
            ],
          },
        ],
      },
    ],
  },
};

const insuranceInfo = {
  generalLiability,
  commercialAuto,
  cyberLiability,
  eventCancellation,
  liquorLiability,
  workersCompensation,
  inlandMarine,
  umbrellaLiability,
  tradeCredit,
  professionalLiability,
  eventLiability,
  propertyCoverage,
  auto,
  personalInlandMarine,
  home,
  watercraft,
  aircraft,
  umbrella,
};

export default insuranceInfo;
