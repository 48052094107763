import { Box } from "@mui/material";

const classes = {
  container: {
    display: "flex",
    width: "100%",
    backgroundColor: "yellow",
  },
};

const CompanyDashboard = (): JSX.Element => {
  return <Box sx={classes.container}></Box>;
};

export default CompanyDashboard;
