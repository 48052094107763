import { useEffect, useState } from "react";
import { Box, Slide } from "@mui/material";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
const classes = {
  supportCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: { xs: "100%", sm: "20%", md: "15%" },
    mt: "20px",
    marginBottom: { xs: "10px", md: "20px" },
    padding: { xs: "0px", sm: "10px", md: "20px" },
  },
  icon: {
    width: { xs: "80px", md: "160px" },
    height: { xs: "80px", md: "160px" },
  },
  title: {
    fontSize: { xs: "14px", sm: "16px", md: "22px" },
    textTransform: "uppercase",
    fontWeight: "bold",
    margin: { xs: "10px", sm: "20px", md: "40px" },
    textAlign: "center",
  },
  text: {
    fontSize: { xs: "12px", md: "18px" },
    textAlign: "left",
  },
};

interface Props {
  icon: string;
  title: string;
  subtitlePrimary: string;
  subtitleSecondary: string;
  transitionDelay: string;
  altTag: string;
}

const SupportCard = ({
  icon,
  title,
  subtitlePrimary,
  subtitleSecondary,
  transitionDelay,
  altTag,
}: Props) => {
  const [text, setText] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const textMove = () => {
      if (isMobile) {
        setText(window.scrollY >= window.innerHeight - 200);
      } else {
        setText(window.scrollY >= window.innerHeight / 1.75);
      }
    };
    window.addEventListener("scroll", textMove);
    return () => window.removeEventListener("scroll", textMove);
  }, []);

  return (
    <Slide
      direction="up"
      in={text}
      style={{ transitionDelay: transitionDelay }}
      {...(text ? { timeout: 1000 } : {})}
    >
      <Box sx={classes.supportCard}>
        <Box component="img" alt={altTag} src={icon} sx={classes.icon} />
        <Typography sx={classes.title}>{title}</Typography>
        <Box sx={{ marginLeft: "20px" }}>
          <Typography sx={classes.text}>{subtitlePrimary}</Typography>
          <Typography sx={classes.text}>{subtitleSecondary}</Typography>
        </Box>
      </Box>
    </Slide>
  );
};
export default SupportCard;
