import { Typography, Box, List, ListItem, Grid, Link } from "@mui/material";
import classes from "../styles/styles";

const InsuranceMenu = () => {
  return (
    <Box sx={classes.extendedMenu}>
      <Grid container spacing={1}>
        <Grid item xs={8} sx={classes.rightBorder}>
          <Link sx={classes.linkStyle} href={"/insurance?section=commercial"}>
            <Typography sx={classes.listTitle}>COMMERCIAL</Typography>
          </Link>
          <Grid container sx={{ display: "flex" }}>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/general-liability"}
                >
                  <Typography sx={classes.listText}>
                    General Liability
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/property-coverage"}
                >
                  <Typography sx={classes.listText}>
                    Property Coverage
                  </Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/workers-compensation"}
                >
                  <Typography sx={classes.listText}>
                    Workers Compensation
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/commerical-inland-marine"}
                >
                  <Typography sx={classes.listText}>Inland Marine</Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/cyber-liability"}
                >
                  <Typography sx={classes.listText}>Cyber Liability</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/umbrella-liability"}
                >
                  <Typography sx={classes.listText}>
                    Umbrella Liability
                  </Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/business-auto"}>
                  <Typography sx={classes.listText}>Business Auto</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/professional-liability"}
                >
                  <Typography sx={classes.listText}>
                    Professional Liability
                  </Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/event-liability"}
                >
                  <Typography sx={classes.listText}>Event Liability</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/event-cancellation"}
                >
                  <Typography sx={classes.listText}>
                    Event Cancellation
                  </Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/liquor-liability"}
                >
                  <Typography sx={classes.listText}>
                    Liquor Liability
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/trade-credit"}>
                  <Typography sx={classes.listText}>Trade Credit</Typography>
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Link sx={classes.linkStyle} href={"/insurance?section=personal"}>
            <Typography sx={classes.listTitle}>PERSONAL</Typography>
          </Link>
          <Grid container sx={{ display: "flex" }}>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/auto"}>
                  <Typography sx={classes.listText}>Auto</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/home"}>
                  <Typography sx={classes.listText}>Home</Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link
                  sx={classes.linkStyle}
                  href={"/insurance/personal-inland-marine"}
                >
                  <Typography sx={classes.listText}>Inland Marine</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/watercraft"}>
                  <Typography sx={classes.listText}>Watercraft</Typography>
                </Link>
              </ListItem>
            </List>
            <List sx={{ flex: 1 }}>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/aircraft"}>
                  <Typography sx={classes.listText}>Aircraft</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={classes.linkStyle} href={"/insurance/umbrella"}>
                  <Typography sx={classes.listText}>Umbrella</Typography>
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceMenu;
