import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import store from "./context/store";
import Navbar from "./components/Navbar/Navbar";
import NavbarBlack from "./components/Navbar/NavbarBlack";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import Industries from "./pages/Industries/Industries";
import insuranceInfo from "./data/static/insuranceInfo";
import InsuranceInfoPage from "./pages/Product/InsuranceInfo";
import "./App.css";
import theme from "./assets/styles/theme";
import Support from "./pages/Support";
import NavbarSearch from "./components/Navbar/NavbarSearch";
import Careers from "./pages/Careers";
import CommercialVsPersonal from "./pages/CommercialVSPersonal";
import StepperPage from "./components/Layouts/StepperPage";
import pageContents from "./data/static/pages";
import Dashboard from "./pages/Dashboard/Dashboard";

interface Page {
  component: JSX.Element;
}

const App = () => {
  const NavPage = (props: Page): JSX.Element => {
    return (
      <>
        <Navbar />
        {props.component}
        <Footer />
      </>
    );
  };
  const NavPageBlack = (props: Page): JSX.Element => {
    return (
      <>
        <NavbarBlack />
        {props.component}
        <Footer />
      </>
    );
  };
  const WhiteNavWithStepper = (props: Page): JSX.Element => {
    return (
      <>
        <NavbarBlack />
        {props.component}
      </>
    );
  };
  const NavSearchPage = (props: Page): JSX.Element => {
    return (
      <>
        <NavbarSearch />
        {props.component}
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ReduxProvider store={store}>
          <Routes>
            <Route path="/" element={<NavPage component={<Home />} />} />
            <Route
              path="/advisory"
              element={
                <WhiteNavWithStepper
                  component={
                    <StepperPage pageContent={pageContents.advisoryPage} />
                  }
                />
              }
            />
            <Route
              path="/industries"
              element={
                <NavPageBlack
                  component={
                    <Industries pageContent={pageContents.industriesPage} />
                  }
                />
              }
            />
            <Route
              path="/industries-detailed"
              element={
                <WhiteNavWithStepper
                  component={
                    <StepperPage pageContent={pageContents.industriesPage} />
                  }
                />
              }
            />
            <Route
              path="/faq"
              element={
                <WhiteNavWithStepper
                  component={
                    <StepperPage pageContent={pageContents.faqPage} isFaq />
                  }
                />
              }
            />
            <Route
              path="/insurance"
              element={<NavPageBlack component={<CommercialVsPersonal />} />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/:code" element={<NavPage component={<Home />} />} />
            <Route
              path="/insurance/general-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.generalLiability} />
                  }
                />
              }
            />

            <Route
              path="/insurance/umbrella-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.umbrellaLiability} />
                  }
                />
              }
            />

            <Route
              path="/insurance/workers-compensation"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.workersCompensation} />
                  }
                />
              }
            />

            <Route
              path="/insurance/event-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.eventLiability} />
                  }
                />
              }
            />

            <Route
              path="/insurance/event-cancellation"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.eventCancellation} />
                  }
                />
              }
            />

            <Route
              path="/insurance/liquor-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.liquorLiability} />
                  }
                />
              }
            />

            <Route
              path="/insurance/cyber-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.cyberLiability} />
                  }
                />
              }
            />

            <Route
              path="/insurance/professional-liability"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage
                      {...insuranceInfo.professionalLiability}
                    />
                  }
                />
              }
            />

            <Route
              path="/insurance/business-auto"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.commercialAuto} />
                  }
                />
              }
            />

            <Route
              path="/insurance/commerical-inland-marine"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.inlandMarine} />
                  }
                />
              }
            />

            <Route
              path="/insurance/trade-credit"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.tradeCredit} />
                  }
                />
              }
            />

            <Route
              path="/insurance/property-coverage"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.propertyCoverage} />
                  }
                />
              }
            />

            <Route
              path="/insurance/watercraft"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage {...insuranceInfo.watercraft} />
                  }
                />
              }
            />

            <Route
              path="/insurance/aircraft"
              element={
                <NavPageBlack
                  component={<InsuranceInfoPage {...insuranceInfo.aircraft} />}
                />
              }
            />

            <Route
              path="/insurance/auto"
              element={
                <NavPageBlack
                  component={<InsuranceInfoPage {...insuranceInfo.auto} />}
                />
              }
            />

            <Route
              path="/insurance/home"
              element={
                <NavPageBlack
                  component={<InsuranceInfoPage {...insuranceInfo.home} />}
                />
              }
            />

            <Route
              path="/insurance/umbrella"
              element={
                <NavPageBlack
                  component={<InsuranceInfoPage {...insuranceInfo.umbrella} />}
                />
              }
            />

            <Route
              path="/insurance/personal-inland-marine"
              element={
                <NavPageBlack
                  component={
                    <InsuranceInfoPage
                      {...insuranceInfo.personalInlandMarine}
                    />
                  }
                />
              }
            />
            <Route
              path="/support"
              element={<NavPageBlack component={<Support />} />}
            />
            <Route
              path="/careers"
              element={<NavPageBlack component={<Careers />} />}
            />
            {/* <Route
              path="/form-commercial"
              element={
                <NavSearchPage
                  component={<FormCommercialWrapper loggedIn={true} />} // TODO: Check if user is logged in; show different navbar and pass different props
                />
              }
            />
            <Route
              path="/choose-insurance"
              element={
                <NavSearchPage
                  component={<ChooseInsurance loggedIn={true} />} // TODO: Check if user is logged in; show different navbar and pass different props
                />
              }
            />
            <Route
              path="/choose-personal-insurance"
              element={
                <NavSearchPage
                  component={<ChoosePersonalInsurance />} // TODO: Check if user is logged in; show different navbar and pass different props
                />
              }
            />
            <Route
              path="/general-info-commercial"
              element={
                <NavSearchPage
                  component={<GeneralInfoCommercial loggedIn={true} />} // TODO: Check if user is logged in; show different navbar and pass different props
                />
              }
            /> */}
          </Routes>
        </ReduxProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
