import { useEffect, useState, useRef } from "react";
import { Box, IconButton, Slide, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./styles/carousel.css";
import SlideIndicator from "./SlideIndicator";

const classes = {
  container: {
    display: "flex",
    height: { xs: "100%", sm: "100vh" },
    width: "100%",
    flexDirection: "column",
    paddingTop: { xs: "10px", sm: "70px" },
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    fontSize: {
      xs: "18px",
      sm: "24px",
      md: "29px",
      lg: "34px",
    },
    paddingBottom: "30px",
    fontWeight: "bold",
    textAlign: "center",
  },
};

interface Props {
  children: JSX.Element[];
  showDesktop: number;
  showMobile: number;
  infiniteLoop?: boolean;
  isMobile: boolean;
  type: string;
  changeFocusedCard: (index: number) => void;
  changeMiddleCard: (index: number) => void;
  focusedCard: number;
  isHovering: boolean;
}

const Carousel = (props: Props) => {
  const {
    children,
    showDesktop,
    showMobile,
    infiniteLoop,
    isMobile,
    type,
    isHovering,
  } = props;
  let show = isMobile ? showMobile : showDesktop;

  const [currentIndex, setCurrentIndex] = useState(1);
  const [length, setLength] = useState(children.length);
  const [isRepeating, setIsRepeating] = useState(
    infiniteLoop && children.length > show
  );
  const [transitionEnabled, setTransitionEnabled] = useState(true);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const autoPlayRef = useRef<any>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startAutoplay = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }

    if (!isHovering) {
      intervalRef.current = setInterval(() => {
        autoPlayRef.current();
        props.changeMiddleCard((currentIndex + Math.floor(show / 2)) % length);
      }, 6000);
    }

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  };

  useEffect(() => {
    autoPlayRef.current = next;
  });

  useEffect(() => {
    const clearAutoplay = startAutoplay();
    return () => clearAutoplay();
  }, [isHovering]);

  useEffect(() => {
    setLength(children.length);
    setIsRepeating(infiniteLoop && children.length > show);
  }, [children, infiniteLoop, show]);

  useEffect(() => {
    if (isRepeating) {
      if (currentIndex === show || currentIndex === length) {
        setTransitionEnabled(true);
      }
    }
  }, [currentIndex, isRepeating, show, length]);

  useEffect(() => {
    const middleCardIndex = (currentIndex + Math.floor(show / 2)) % length;
    props.changeMiddleCard(middleCardIndex);
  }, [currentIndex, show, length, props]);

  const next = () => {
    if (currentIndex === length - 1) {
      setCurrentIndex(0);
      props.changeFocusedCard(0);
      props.changeMiddleCard(Math.floor(show / 2));
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      props.changeFocusedCard(currentIndex + 1);
      props.changeMiddleCard(
        (currentIndex + 1 + Math.floor(show / 2)) % length
      );
    }
    startAutoplay();
  };
  const prev = () => {
    setCurrentIndex((prevState) => (prevState - 1 + length) % length);
    props.changeFocusedCard((currentIndex - 1 + length) % length);
    props.changeMiddleCard((currentIndex - 1 + Math.floor(show / 2)) % length);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      next();
    }

    if (diff < -5) {
      prev();
    }

    setTouchPosition(null);
  };

  const handleTransitionEnd = () => {
    if (isRepeating) {
      setTransitionEnabled(true);
    }
  };

  const renderChildren = () => {
    let output = [];
    for (let i = -Math.floor(show / 2); i <= Math.floor(show / 2); i++) {
      output.push(children[(currentIndex + i + length) % length]);
    }
    return output;
  };

  const [text, setText] = useState(false);

  useEffect(() => {
    const textMove = () => {
      if (isMobile) {
        setText(window.scrollY >= window.innerHeight + 300);
      } else {
        setText(window.scrollY >= (window.innerHeight / 1.25) * 2);
      }
    };
    window.addEventListener("scroll", textMove);
    return () => window.removeEventListener("scroll", textMove);
  }, []);

  return (
    <Box sx={classes.container}>
      <Typography sx={classes.title}>INSURANCE POLICIES</Typography>
      <Slide direction="up" in={text} {...(text ? { timeout: 1000 } : {})}>
        <div className="carousel-wrapper">
          {(isRepeating || currentIndex >= 0) && (
            <>
              {isMobile ? (
                <></>
              ) : (
                <div className={`div-blur-${type}`}>
                  <IconButton
                    onClick={() => prev()}
                    className={`left-arrow-${type}`}
                    aria-label="Previous"
                  >
                    <ArrowBackIosNewIcon color="secondary" />
                  </IconButton>
                </div>
              )}
            </>
          )}
          <div
            className="carousel-content-wrapper"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div
              className={`carousel-content show-${show}-${type}`}
              style={{
                transition: !transitionEnabled ? "none" : undefined,
              }}
              onTransitionEnd={() => handleTransitionEnd()}
            >
              {renderChildren()}
            </div>
          </div>
          {(isRepeating || currentIndex < length) && (
            <>
              {isMobile ? (
                <></>
              ) : (
                <div className={`div-blur-${type}`}>
                  <IconButton
                    onClick={() => next()}
                    className={`right-arrow-${type}`}
                    aria-label="Next"
                  >
                    <ArrowForwardIosIcon color="secondary" />
                  </IconButton>
                </div>
              )}
            </>
          )}
        </div>
      </Slide>
      {isMobile && <SlideIndicator length={length} current={currentIndex} />}
    </Box>
  );
};

export default Carousel;
